import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LicensingModel, LoginMethod, TenantType } from "domain/tenants";
import { FeatureLicenseType } from "domain/users";
import { ProductToRateList } from "services/licenses/LicenseService";
import { getObject, RepositoryKey, setObject } from "utils/repository";

export interface TenantDetails {
    uuid: string;
    featureLicenses: FeatureLicenseType[];
    region?: string;
    type: TenantType;
    tenantName: string;
    loginMethod?: LoginMethod;
    licensingModel: LicensingModel;
    tenantTier: string;
    tokenRates?: ProductToRateList;
    parentDrawBackPrevention?: boolean;
}

export interface TenantDetailsState {
    stack: TenantDetails[];
}

const INITIAL_STATE: TenantDetailsState = {
    stack: [],
};

const TENANT_DETAILS_SLICE = createSlice({
    name: "tenantDetailsStore",
    initialState: (getObject(RepositoryKey.TENANT_DETAILS) as TenantDetailsState) ?? INITIAL_STATE,
    reducers: {
        pushTenantDetails(state, action: PayloadAction<TenantDetails>) {
            state.stack.push(action.payload);
            setObject(RepositoryKey.TENANT_DETAILS, state);
        },
        updateTenantDetails(state, action: PayloadAction<TenantDetails>) {
            const index = state.stack.findIndex((tenant) => tenant.uuid == action.payload.uuid);
            state.stack[index] = {
                uuid: action.payload.uuid,
                featureLicenses: action.payload.featureLicenses,
                type: action.payload.type,
                tenantName: action.payload.tenantName,
                region: action.payload.region,
                loginMethod: action.payload.loginMethod,
                tenantTier: action.payload.tenantTier,
                licensingModel: action.payload.licensingModel,
                tokenRates: action.payload.tokenRates,
                parentDrawBackPrevention: action.payload.parentDrawBackPrevention,
            };
            setObject(RepositoryKey.TENANT_DETAILS, state);
        },
        popTenantDetails(state) {
            state.stack.pop();
            setObject(RepositoryKey.TENANT_DETAILS, state);
        },
        clearAllTenantDetails(state) {
            state.stack = [];
            setObject(RepositoryKey.TENANT_DETAILS, state);
        },
    },
});

export const { pushTenantDetails, updateTenantDetails, popTenantDetails, clearAllTenantDetails } =
    TENANT_DETAILS_SLICE.actions;

export default TENANT_DETAILS_SLICE.reducer;
