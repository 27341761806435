import * as React from "react";
import { connect } from "react-redux";

import ChooseWorkflowTemplate from "components/home/choose-workflow-template/ChooseWorkflowTemplate";
import InstallBmde from "components/home/install-bmde/InstallBmde";
import CreateBootableUsb from "components/home/onboarding-create-bootable-usb/CreateBootableUsb";
import RunErasure from "components/home/onboarding-run-erasure/RunErasure";
import ViewReport from "components/home/onboarding-view-report/ViewReport";
import { LicenseData } from "domain/licenses";
import {
    BmdeOnboardingSteps,
    checkIfMenuExpanded,
    checkIfStepIsClickable,
    getStepIndexForBmde,
    isStepCompleted,
    OnboardingProductType,
} from "domain/onboardings";
import { SoftwarePackage, Versions } from "domain/softwarePackages";
import { TemplateTableData } from "domain/workflowTemplates";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    lastCompletedStepIndex: number;
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
    fetchedSoftwarePackages?: SoftwarePackage[];
    canViewReport: boolean;
    canViewWorkflows: boolean;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

const BmdeSteps = (props: Props): JSX.Element => {
    const getStepIndex = (step: BmdeOnboardingSteps | undefined) => {
        return getStepIndexForBmde(step, hasIbr);
    };

    const hasIbr = props.canViewWorkflows;

    return (
        <>
            {props.canViewWorkflows && (
                <ChooseWorkflowTemplate
                    stepIndex={getStepIndex(BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE)}
                    menuExpanded={checkIfMenuExpanded(
                        props.lastCompletedStepIndex,
                        getStepIndex(BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE),
                        hasIbr
                    )}
                    stepCompleted={isStepCompleted(
                        getStepIndex(BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE),
                        props.lastCompletedStepIndex
                    )}
                    stepActive={true}
                    product={OnboardingProductType.BMDE}
                    defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                    requestFailureMessage={props.requestFailureMessage}
                    saveOnboardingStep={props.saveOnboardingStep}
                    stepInProgressIndex={props.stepInProgressIndex}
                    saveStepInProgressIndex={props.saveStepInProgressIndex}
                />
            )}
            <CreateBootableUsb
                stepIndex={getStepIndex(BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE)}
                menuExpanded={checkIfMenuExpanded(
                    props.lastCompletedStepIndex,
                    getStepIndex(BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE),
                    hasIbr
                )}
                stepCompleted={isStepCompleted(
                    getStepIndex(BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE),
                    props.lastCompletedStepIndex
                )}
                stepActive={!hasIbr}
                licenses={props.licenses}
                fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                stepClickable={checkIfStepIsClickable(
                    getStepIndex(BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE),
                    props.lastCompletedStepIndex,
                    hasIbr
                )}
                saveOnboardingStep={props.saveOnboardingStep}
                product={OnboardingProductType.BMDE}
                stepInProgressIndex={props.stepInProgressIndex}
                saveStepInProgressIndex={props.saveStepInProgressIndex}
            />
            <InstallBmde
                stepIndex={getStepIndex(BmdeOnboardingSteps.STEP_INSTALLING_BMDE)}
                menuExpanded={checkIfMenuExpanded(
                    props.lastCompletedStepIndex,
                    getStepIndex(BmdeOnboardingSteps.STEP_INSTALLING_BMDE),
                    hasIbr
                )}
                stepCompleted={isStepCompleted(
                    getStepIndex(BmdeOnboardingSteps.STEP_INSTALLING_BMDE),
                    props.lastCompletedStepIndex
                )}
                stepActive={false}
                stepClickable={checkIfStepIsClickable(
                    getStepIndex(BmdeOnboardingSteps.STEP_INSTALLING_BMDE),
                    props.lastCompletedStepIndex,
                    hasIbr
                )}
                saveOnboardingStep={props.saveOnboardingStep}
                hasIbr={hasIbr}
                stepInProgressIndex={props.stepInProgressIndex}
                saveStepInProgressIndex={props.saveStepInProgressIndex}
            />
            <RunErasure
                stepIndex={getStepIndex(BmdeOnboardingSteps.STEP_RUNNING_ERASURE)}
                menuExpanded={checkIfMenuExpanded(
                    props.lastCompletedStepIndex,
                    getStepIndex(BmdeOnboardingSteps.STEP_RUNNING_ERASURE),
                    hasIbr
                )}
                stepActive={false}
                product={OnboardingProductType.BMDE}
                stepClickable={checkIfStepIsClickable(
                    getStepIndex(BmdeOnboardingSteps.STEP_RUNNING_ERASURE),
                    props.lastCompletedStepIndex,
                    hasIbr
                )}
                stepCompleted={isStepCompleted(
                    getStepIndex(BmdeOnboardingSteps.STEP_RUNNING_ERASURE),
                    props.lastCompletedStepIndex
                )}
                saveOnboardingStep={props.saveOnboardingStep}
                hasIbr={hasIbr}
                stepInProgressIndex={props.stepInProgressIndex}
                saveStepInProgressIndex={props.saveStepInProgressIndex}
            />
            {props.canViewReport && (
                <ViewReport
                    stepIndex={getStepIndex(BmdeOnboardingSteps.STEP_VIEWING_REPORT)}
                    menuExpanded={checkIfMenuExpanded(
                        props.lastCompletedStepIndex,
                        getStepIndex(BmdeOnboardingSteps.STEP_VIEWING_REPORT),
                        hasIbr
                    )}
                    stepCompleted={isStepCompleted(
                        getStepIndex(BmdeOnboardingSteps.STEP_VIEWING_REPORT),
                        props.lastCompletedStepIndex
                    )}
                    stepActive={false}
                    stepClickable={checkIfStepIsClickable(
                        getStepIndex(BmdeOnboardingSteps.STEP_VIEWING_REPORT),
                        props.lastCompletedStepIndex,
                        hasIbr
                    )}
                    product={OnboardingProductType.BMDE}
                    hasIbr={hasIbr}
                    saveOnboardingStep={props.saveOnboardingStep}
                />
            )}
        </>
    );
};

export default connector(BmdeSteps);
