import { ChannelBundle, CorporateBundle, ProcessorBundle, SmbBundle } from "components/licenses/bundles";
import { TableData } from "domain/table";
import { Role } from "domain/users";

export interface Tenant extends CommonTenant {
    countryCode: string;
    contactName: string;
    contactEmail: string;
    expirationDate: string;
    emsId: string;
    createdTimestamp: string;
    createdBy: string;
    erasureClientEndpoint: string;
    publicApiEndpoint: string;
    usageStatistics: boolean;
    roles: Role[];
    tier: CombinedTier;
    salesforceAccountId: string;
    type: TenantType;
    totalReportsCount: number;
    totalUsers: number;
    totalWorkflows: number;
    productsLicensed: [];
    availableLicenses: number;
    parentExpirationDate: string;
    loginMethod?: LoginMethod;
    deletionStatus: boolean;
    tenantHierarchyLevel: number;
    tenants?: Tenant[];
    parentUuid?: string;
    foundUser?: string;
    foundUserName?: string;
    subTenantsCount: number;
    licensingModel: LicensingModel;
    ipRestrictions: [];
    parentLicensingModel: LicensingModel;
    parentType: TenantType;
    parentTier: CombinedTier;
    tenantName?: string;
    salesforceContractId?: string;
    salesforceContractState?: TenantContractStatus | string;
    salesforceContactEmail?: string;
    salesforceContractEndDate?: string;
    parentDrawbackPrevention: boolean;
    commonLicenseCount?: number;
    drawbackPrevention?: boolean;
}

export interface CommonTenant extends TableData {
    name: string;
    notes: string;
    region: string;
    uuid: string;
    status: boolean;
}

export interface TenantCursor {
    name: string;
    parentUuid: string;
}

export interface Tenants extends TableData {
    tenantList: Tenant[];
    cursor: TenantCursor | null;
}

export interface TenantsData {
    tenantList: Tenants;
    message?: boolean;
}

export interface FetchRegionsListResponse {
    regions: string[];
}

export const ssoLoginMethodList = ["COMPANY_ID"];
export const loginMethodList = [...ssoLoginMethodList, "EMAIL", "COMPANY_ID_AND_EMAIL"];
export type LoginMethod = typeof loginMethodList[number];
export type TenantType = "INTERNAL" | "CUSTOMER" | "CHANNEL" | "CORPORATE" | "PROCESSOR" | "SMB";
export const TENANT_TYPES: TenantType[] = ["CHANNEL", "CORPORATE", "CUSTOMER", "PROCESSOR", "SMB"];
export enum TenantContractStatus {
    SELECT = "SELECT",
    DRAFT = "DRAFT",
    ACTIVE = "ACTIVE",
    EXPIRED = "EXPIRED",
    TERMINATED = "TERMINATED",
}
export enum LicensingModel {
    OLD_MODEL = "OLD_MODEL",
    BUNDLE = "BUNDLE",
    BUNDLE_WITH_TOKEN = "BUNDLE_WITH_TOKEN",
}
export const INTERNAL_TENANT_UUID = "BLANCCO";
export enum OldLicensingModelTier {
    BASIC = "BASIC",
    STANDARD = "STANDARD",
    ADVANCED = "ADVANCED",
}
export const COMBINED_TIERS = Object.keys(OldLicensingModelTier)
    .concat(Object.keys(CorporateBundle))
    .concat(Object.keys(ProcessorBundle))
    .concat(Object.keys(SmbBundle))
    .concat(Object.keys(ChannelBundle));
export type CombinedTier = typeof COMBINED_TIERS[number];
