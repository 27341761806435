// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1-T7zliRYMjWETXEtKhXvA{width:100px;height:100px;margin:auto}._2nVpux_DhxhCgor4U566av{width:30px;height:30px;margin:auto}._3Ej-7IXhQaJhBsJ7OGMjV9{width:16px;height:16px;margin:auto}", ""]);
// Exports
exports.locals = {
	"skCubeGrid": "_1-T7zliRYMjWETXEtKhXvA",
	"smallGrid": "_2nVpux_DhxhCgor4U566av",
	"smallerGrid": "_3Ej-7IXhQaJhBsJ7OGMjV9"
};
module.exports = exports;
