import * as React from "react";

export const Sustainability = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            d="M4 20C4 17.4737 5.55789 15.4863 8.27789 14.9474C10.3158 14.5432 12.421 13.2632 13.2632 12.421M11.5791 19.1579C10.1004 19.1624 8.67408 18.6109 7.58294 17.613C6.49179 16.615 5.81557 15.2434 5.68839 13.7702C5.5612 12.297 5.99235 10.8298 6.89631 9.65957C7.80028 8.48936 9.11103 7.70164 10.5686 7.45263C15.3686 6.52631 16.6318 6.08842 18.316 4C19.1581 5.68421 20.0002 7.52 20.0002 10.7368C20.0002 15.3684 15.9749 19.1579 11.5791 19.1579Z"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
