import * as React from "react";

export const OnPremBmp = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            d="M19.4876 2.57617H4.51165C3.47777 2.57617 2.63965 3.41429 2.63965 4.44817V13.8082C2.63965 14.842 3.47777 15.6802 4.51165 15.6802H19.4876C20.5215 15.6802 21.3596 14.842 21.3596 13.8082V4.44817C21.3596 3.41429 20.5215 2.57617 19.4876 2.57617Z"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.25586 19.4238H15.7439"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 15.6797V19.4237"
            stroke={props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M15.8536 9.98032H14.8339C14.6682 9.98032 14.5339 10.1146 14.5339 10.2803V11.915C14.5339 12.0807 14.3996 12.215 14.2339 12.215H13.5437C13.378 12.215 13.2437 12.0807 13.2437 11.915V6.3C13.2437 6.13431 13.378 6 13.5437 6H15.9506C16.5746 6 17.0722 6.16022 17.4432 6.48067C17.8143 6.80112 17.9998 7.29726 17.9998 7.96908C17.9998 8.70274 17.8143 9.22136 17.4432 9.52495C17.0722 9.82853 16.5423 9.98032 15.8536 9.98032ZM16.4608 8.68587C16.6295 8.53689 16.7138 8.30077 16.7138 7.97751C16.7138 7.65425 16.6281 7.42375 16.4566 7.28602C16.2879 7.14828 16.0504 7.07941 15.744 7.07941H14.8339C14.6682 7.07941 14.5339 7.21372 14.5339 7.37941V8.60935C14.5339 8.77503 14.6682 8.90935 14.8339 8.90935H15.744C16.0504 8.90935 16.2893 8.83486 16.4608 8.68587Z"
            fill={props.color}
        />
        <path
            d="M7.21012 11.715C7.21012 11.9912 6.98626 12.215 6.71012 12.215H6.5C6.22386 12.215 6 11.9912 6 11.715V6.5C6 6.22386 6.22386 6 6.5 6H7.49139C7.72414 6 7.9261 6.16059 7.97854 6.38736L8.92118 10.4639C8.94509 10.5673 9.09234 10.5672 9.11608 10.4638L10.0515 6.38815C10.1036 6.16101 10.3057 6 10.5388 6H11.5084C11.7846 6 12.0084 6.22386 12.0084 6.5V11.715C12.0084 11.9912 11.7846 12.215 11.5084 12.215H11.2983C11.0222 12.215 10.7983 11.9912 10.7983 11.715V8.01124C10.7983 7.89037 10.7997 7.72171 10.8025 7.50527C10.8034 7.43804 10.7102 7.4273 10.6954 7.49289L9.71832 11.825C9.66689 12.0531 9.46433 12.215 9.23057 12.215H8.77006C8.53602 12.215 8.33329 12.0527 8.28214 11.8243L7.31226 7.49306C7.29765 7.42786 7.20505 7.43846 7.2059 7.50527C7.20871 7.72171 7.21012 7.89037 7.21012 8.01124V11.715Z"
            fill={props.color}
        />
    </svg>
);
