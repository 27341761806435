import * as React from "react";

export const Workflows = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.25 5C3.25 4.0335 4.0335 3.25 5 3.25H8C8.9665 3.25 9.75 4.0335 9.75 5V5.25H17C19.0711 5.25 20.75 6.92893 20.75 9C20.75 11.0711 19.0711 12.75 17 12.75H15.75V13C15.75 13.9665 14.9665 14.75 14 14.75H11C10.0335 14.75 9.25 13.9665 9.25 13V12.75H7C5.75736 12.75 4.75 13.7574 4.75 15C4.75 16.2426 5.75736 17.25 7 17.25H18.1893L17.4697 16.5303C17.1768 16.2374 17.1768 15.7626 17.4697 15.4697C17.7626 15.1768 18.2374 15.1768 18.5303 15.4697L20.5303 17.4697C20.8232 17.7626 20.8232 18.2374 20.5303 18.5303L18.5303 20.5303C18.2374 20.8232 17.7626 20.8232 17.4697 20.5303C17.1768 20.2374 17.1768 19.7626 17.4697 19.4697L18.1893 18.75H7C4.92893 18.75 3.25 17.0711 3.25 15C3.25 12.9289 4.92893 11.25 7 11.25H9.25V11C9.25 10.0335 10.0335 9.25 11 9.25H14C14.9665 9.25 15.75 10.0335 15.75 11V11.25H17C18.2426 11.25 19.25 10.2426 19.25 9C19.25 7.75736 18.2426 6.75 17 6.75H9.75V7C9.75 7.9665 8.9665 8.75 8 8.75H5C4.0335 8.75 3.25 7.9665 3.25 7V5ZM8.25 5C8.25 4.86193 8.13807 4.75 8 4.75H5C4.86193 4.75 4.75 4.86193 4.75 5V7C4.75 7.13807 4.86193 7.25 5 7.25H8C8.13807 7.25 8.25 7.13807 8.25 7V5ZM14.25 11C14.25 10.8619 14.1381 10.75 14 10.75H11C10.8619 10.75 10.75 10.8619 10.75 11V13C10.75 13.1381 10.8619 13.25 11 13.25H14C14.1381 13.25 14.25 13.1381 14.25 13V11Z"
            fill={props.color}
        />
    </svg>
);
