import classNames from "classnames";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { object, string } from "yup";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import ConfirmationModal from "components/workflows/ConfirmationModal";
import style from "components/workflows/manage-workflow-dialog/manage-workflow-dialog.scss";
import WasmVisualEditorView, { ActionType } from "components/workflows/wasm-visual-editor/WasmVisualEditorView";
import { compareEditorVersionDtos, deduceEditorVersion } from "components/workflows/WorkflowUtil";
import {
    BDE_WORKFLOW_EDITOR_VERSION_7_12_0,
    BDE_WORKFLOW_EDITOR_VERSION_7_13_0,
    BMDE_WORKFLOW_EDITOR_VERSION_5_4_0,
    BMDE_WORKFLOW_EDITOR_VERSION_5_5_0,
} from "domain/globalConstants";
import { FeatureLicenseType } from "domain/users";
import { WorkflowEntityType } from "domain/workflows";
import { templateService } from "services/workflows/TemplateService";
import {
    ManifestWorkflowEditor,
    Profile,
    profileToFeatureLicenseMap,
    profileToNameMap,
    WorkflowService,
} from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { setUser } from "store/user";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyValidWorkflowCharacters } from "utils/commonFunctions";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

enum DialogState {
    FETCHING_WORKFLOW_EDITORS,
    FETCHING_WORKFLOW_EDITORS_FAILED,
    FETCHING_WORKFLOW,
    FETCHING_WORKFLOW_FAILED,
    SELECTING_WORKFLOW_EDITOR,
    SELECTING_NEW_WORKFLOW_EDITOR,
    EDITING,
}

interface Props {
    confirmCancelVisible?: boolean;
    entityType: WorkflowEntityType;
    onCancel: (doCancel: boolean) => void;
    onUpdateParentView: () => void;
    setDynamicSize: (initial: boolean) => void;
    setFullscreen: (fullscreen: boolean) => void;
    workflowService: WorkflowService;
    isCreate?: boolean;
    isEdit?: boolean;
    isUpdate?: boolean;
    name?: string;
    parentWorkflowsCount?: number;
    product?: string;
    setShowConfirmCancelDialog?: (show: boolean) => void;
    version?: string;
    uuid?: string;
    setHasNewEditorLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

interface WorkflowFormValues {
    name: string;
}

interface CreateWorkflowFormValues extends WorkflowFormValues {
    product: Profile;
    version: string;
}

const WORKFLOW_NAME_MAX_LENGTH = 255;

const mapState = (state: StoreState) => ({
    user: state.userReducer.user,
});
const connector = connect(mapState, { setUser });
const ManageWorkflowTemplateDialog: React.FunctionComponent<Props & ConnectedProps<typeof connector>> = (props) => {
    const { t } = useTranslation();
    const [dialogState, setDialogState] = React.useState(DialogState.FETCHING_WORKFLOW);
    const [optionList, setOptionList] = React.useState<JSX.Element[] | JSX.Element | undefined>(undefined);
    const [selectedProfile, setSelectedProfile] = React.useState<Profile | "">((props.product as Profile) || "");
    const [selectedVersion, setSelectedVersion] = React.useState<string | "">(props.version || "");
    const [submissionError, setSubmissionError] = React.useState<string | undefined>(undefined);
    const [workflowEditors, setWorkflowEditors] = useState<ManifestWorkflowEditor[]>([]);
    const [workflowNameInputValue, setWorkflowNameInputValue] = React.useState("");
    const [updateWorkflowEditors, setUpdateWorkflowEditors] = useState<ManifestWorkflowEditor[]>([]);
    const [updateOptionList, setUpdateOptionList] = React.useState<JSX.Element[] | undefined>(undefined);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const [loading, setLoading] = React.useState(false);
    const fetchWorkflowEditors = (profile: Profile): Promise<ManifestWorkflowEditor[]> => {
        setDialogState(DialogState.FETCHING_WORKFLOW);

        const abortController = new AbortController();
        abortControllers.push(abortController);

        return props.workflowService.fetchWorkflowEditors(profile, abortController);
    };

    const handleSelectedProfileChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedProfile(event.currentTarget.value as Profile);
    };

    const handleSelectedVersionChanged = (event: React.FormEvent<HTMLSelectElement>) => {
        setSelectedVersion(event.currentTarget.value);
        setSelectedProfile(selectedProfile as Profile);
    };

    const confirmWorkflowEditorSelection = () => {
        props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
        setDialogState(DialogState.FETCHING_WORKFLOW);
        props.setDynamicSize(false);
        props.setFullscreen(true);
        setDialogState(DialogState.EDITING);
    };

    const handleWorkflowNameInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        setWorkflowNameInputValue(event.currentTarget.value);
    };

    useEffect(() => {
        fetchWorkflowEditors(Profile.ALL)
            .then((workflowEditors) => {
                setWorkflowEditors(workflowEditors.filter((each) => each.editorGeneration === "V2"));
                // Set default SelectedProfile based on tenant's workflow licences
                const filteredProfiles = Object.values(Profile).filter((each) => {
                    const featureLicense = profileToFeatureLicenseMap.get(each);
                    return (
                        Profile.ALL !== each &&
                        featureLicense != null &&
                        tenantDetails.featureLicenses.includes(featureLicense)
                    );
                });

                if (filteredProfiles.length === 0) {
                    throw new Error("Tenant has no feature licences");
                }
                props.isCreate && setDialogState(DialogState.SELECTING_WORKFLOW_EDITOR);

                props.isUpdate && setDialogState(DialogState.SELECTING_NEW_WORKFLOW_EDITOR);

                if (props.isEdit) {
                    props.setShowConfirmCancelDialog && props.setShowConfirmCancelDialog(true);
                    props.setDynamicSize(false);
                    props.setFullscreen(true);
                    setTimeout(() => {
                        setDialogState(DialogState.EDITING);
                    }, 3000);
                }
            })
            .catch(() => setDialogState(DialogState.FETCHING_WORKFLOW_EDITORS_FAILED));

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    useEffect(() => {
        let sortedEditors = workflowEditors
            .filter((editor) =>
                selectedProfile == Profile.BDE || selectedProfile == Profile.LUN
                    ? editor.profile === selectedProfile
                    : editor.profile != Profile.BDE && editor.profile != Profile.LUN
            )
            .sort(compareEditorVersionDtos);

        if (sortedEditors.length == 0) {
            return;
        }

        //  Remove the BDE new generation editor old versions from list which
        //  do not support workflow templates.
        if (selectedProfile === Profile.BDE) {
            sortedEditors = sortedEditors.filter(
                (each) =>
                    !each.version.includes(BDE_WORKFLOW_EDITOR_VERSION_7_12_0) &&
                    !each.version.includes(BDE_WORKFLOW_EDITOR_VERSION_7_13_0)
            );
        }

        // Remove the BMDE new generation editor old versions from list which
        // do not support workflow templates.
        if (selectedProfile === Profile.BMDE || selectedProfile === Profile.BMDE_DIAGNOSTIC) {
            sortedEditors = sortedEditors.filter(
                (each) =>
                    !each.version.includes(BMDE_WORKFLOW_EDITOR_VERSION_5_4_0) &&
                    !each.version.includes(BMDE_WORKFLOW_EDITOR_VERSION_5_5_0)
            );
        }

        const deduplicatedVersions = sortedEditors.filter(
            (editor, index, self) => index === self.findIndex((each) => each.version === editor.version)
        );

        if (props.isUpdate) {
            const updateVersions = deduplicatedVersions
                .filter(
                    (each) =>
                        each.editorGeneration === "V2" &&
                        each.profile ===
                            (props.product === Profile.BMDE || props.product === Profile.BMDE_DIAGNOSTIC
                                ? Profile.BMDE
                                : props.product)
                )
                .sort(compareEditorVersionDtos);

            const updateEditorVersions: ManifestWorkflowEditor[] = [];
            for (const updateVersion of updateVersions) {
                if (updateVersion.version === props.version) {
                    break;
                }
                updateEditorVersions.push(updateVersion);
            }

            setUpdateWorkflowEditors(updateEditorVersions);
            if (updateEditorVersions.length >= 1) {
                setSelectedVersion(updateEditorVersions[0].version);
            }

            setUpdateOptionList(
                updateEditorVersions.map((editor) => (
                    <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
                ))
            );
        }

        setOptionList(
            deduplicatedVersions.map((editor) => (
                <option key={editor.version + ":" + editor.profile}>{editor.version}</option>
            ))
        );
    }, [selectedProfile, workflowEditors]);

    const [editorOpenTabs, setEditorOpenTabs] = React.useState("");
    const [confirmVisibleRefreshCount, setConfirmVisibleRefreshCount] = React.useState(0);
    React.useEffect(() => {
        const version = deduceEditorVersion(selectedVersion);

        if (
            props.confirmCancelVisible === true &&
            !(selectedProfile === Profile.BDE && BDE_WORKFLOW_EDITOR_VERSION_7_12_0 === version) &&
            !(selectedProfile === Profile.BMDE && BMDE_WORKFLOW_EDITOR_VERSION_5_4_0 === version) &&
            !(selectedProfile === Profile.BMDE_DIAGNOSTIC && BMDE_WORKFLOW_EDITOR_VERSION_5_4_0 === version)
        ) {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setEditorOpenTabs(wasmInstance.UTF8ToString(wasmInstance._tabDetails()));
                setConfirmVisibleRefreshCount((prev) => ++prev);
            } catch {
                return;
            }
        }
    }, [props.confirmCancelVisible]);

    const confirmationModal = (
        <ConfirmationModal
            onCancel={props.onCancel}
            onUpdateParentView={props.onUpdateParentView}
            setShowConfirmCancelDialog={props.setShowConfirmCancelDialog}
            confirmCancelVisible={props.confirmCancelVisible}
            editorOpenTabs={editorOpenTabs}
            confirmVisibleRefreshCount={confirmVisibleRefreshCount}
            entityType={props.entityType}
            setHasNewEditorLoaded={props.setHasNewEditorLoaded}
        />
    );

    const deduceActionType = (): ActionType => {
        if (props.isCreate) {
            return "create";
        } else if (props.isEdit) {
            return "edit";
        } else {
            return "update";
        }
    };

    const submitHandler: FormikConfig<WorkflowFormValues>["onSubmit"] = async (
        values,
        { setErrors, setSubmitting }
    ) => {
        const abortController = new AbortController();
        setSubmissionError(undefined);

        try {
            setLoading(true);
            const isTemplateNameAvailable: boolean = await templateService.checkWorkflowTemplateNameAvailability(
                values.name,
                abortController
            );

            if (!isTemplateNameAvailable) {
                setLoading(false);
                const errorText: string =
                    "TEMPLATE" === props.entityType
                        ? "ManageWorkflowDialog.selectWorkflowEditor.workflowTemplateNameNotAvailable"
                        : "ManageWorkflowDialog.selectWorkflowEditor.subWorkflowNameNotAvailable";
                setErrors({
                    name: t(errorText),
                });
                setSubmitting(false);
            } else {
                if (props.isCreate) {
                    confirmWorkflowEditorSelection();
                } else {
                    confirmNewWorkflowEditorSelection();
                }
                setSubmitting(true);
            }
        } catch (error) {
            setSubmissionError(t("ManageWorkflowDialog.selectWorkflowEditor.workflowCreationError"));
        } finally {
            setLoading(false);
        }
    };

    const confirmNewWorkflowEditorSelection = () => {
        if (props.setShowConfirmCancelDialog) {
            props.setShowConfirmCancelDialog(true);
        }
        if (updateWorkflowEditors.length === 1) {
            setSelectedVersion(updateWorkflowEditors[0].version);
        }
        setDialogState(DialogState.FETCHING_WORKFLOW);
        props.setDynamicSize(false);
        props.setFullscreen(true);
        setDialogState(DialogState.EDITING);
    };

    const theme = useTheme();
    let content = <></>;

    const loader = loading ? (
        <div className={style.loaderContainer}>
            <LoadingIndicator small={true} />
        </div>
    ) : (
        ""
    );

    switch (dialogState) {
        case DialogState.FETCHING_WORKFLOW_EDITORS:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.loadingMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <LoadingIndicator />
                    <div className={style.loadingMessage}>{t("ManageWorkflowDialog.fetchWorkflow.loadingMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_EDITORS_FAILED:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <div className={style.failureMessage}>
                        {t("ManageWorkflowDialog.fetchWorkflowEditors.failureMessage")}
                    </div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.FETCHING_WORKFLOW_FAILED:
            content = (
                <div className={props.isCreate ? style.fetchStatus : style.fetchEditorStatus}>
                    <div className={style.failureMessage}>{t("ManageWorkflowDialog.fetchWorkflow.failureMessage")}</div>
                    {confirmationModal}
                </div>
            );
            break;
        case DialogState.SELECTING_WORKFLOW_EDITOR:
            content = (
                <Formik
                    initialValues={{ product: "", version: "", name: "" }}
                    onSubmit={submitHandler}
                    validationSchema={object().shape({
                        name: string()
                            .required(t("WorkflowsTable.actions.create.validation.nameRequired"))
                            .max(WORKFLOW_NAME_MAX_LENGTH, t("WorkflowsTable.actions.create.validation.nameMaxLength"))
                            .test(
                                "Has only valid characters",
                                t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"),
                                (value) => hasOnlyValidWorkflowCharacters(value)
                            ),
                        product: string().required(t("WorkflowsTable.actions.create.validation.productRequired")),
                        version: string().required(t("WorkflowsTable.actions.create.validation.versionRequired")),
                    })}
                    validateOnChange={false}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        validateField,
                        validateForm,
                        setFieldValue,
                    }: FormikProps<CreateWorkflowFormValues>) => {
                        return (
                            <Form>
                                <div>
                                    <div className={classNames(form.formFields, style.formField, style.formSpace)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(form.label, style.fixedWidthLabel)}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.productLabel")}
                                            </label>
                                        </div>

                                        <div>
                                            <select
                                                autoFocus
                                                name="product"
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleChange(e);
                                                    setFieldValue("version", "");
                                                    setSelectedVersion("");
                                                    handleSelectedProfileChanged(e);
                                                }}
                                                onBlur={() => errors.product && validateField("product")}
                                                className={classNames(form.select, style.fixedWidthProduct)}
                                                value={values.product}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .productVersionSelection.productSelect
                                                }
                                            >
                                                <option key={"empty"} value={""}>
                                                    {t("ManageWorkflowDialog.selectWorkflowEditor.selectProduct")}
                                                </option>
                                                {Object.values(Profile).map((each, index) => {
                                                    if (
                                                        Profile.ALL !== each &&
                                                        Profile.BMP !== each &&
                                                        tenantDetails.featureLicenses.includes(
                                                            profileToFeatureLicenseMap.get(each) as FeatureLicenseType
                                                        )
                                                    ) {
                                                        return (
                                                            <option key={index} value={each}>
                                                                {profileToNameMap.get(each)}
                                                            </option>
                                                        );
                                                    }
                                                })}
                                            </select>
                                            <div className={classNames(form.error, style.selectProduct)}>
                                                {errors.product}
                                            </div>
                                        </div>
                                        <div>
                                            <select
                                                name="version"
                                                value={values.version}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleChange(e);
                                                    handleSelectedVersionChanged(e);
                                                }}
                                                onBlur={() => errors.version && validateField("version")}
                                                className={classNames(form.select, style.fixedWidthVersion)}
                                                disabled={selectedProfile === ""}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .productVersionSelection.versionSelect
                                                }
                                            >
                                                <option key={"value"} value={""}>
                                                    {t("ManageWorkflowDialog.selectWorkflowEditor.selectVersion")}
                                                </option>
                                                {selectedProfile !== "" && optionList}
                                            </select>
                                            <div className={classNames(form.error)}>{errors.version}</div>
                                        </div>
                                    </div>
                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(form.label, style.fixedWidthLabel)}
                                            >
                                                {t("Common.name")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                id="versionSelectWorkflowName"
                                                name="name"
                                                data-testid={testIds.workArea.workflows.manageWorkflowDialog.nameInput}
                                                type="text"
                                                className={classNames(form.input, style.fixedWidthInput)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    handleWorkflowNameInputChange(e);
                                                }}
                                                onKeyUp={() => validateField("name")}
                                                maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                            />
                                            {loader}

                                            <div className={classNames(form.error)}>{errors.name}</div>
                                        </div>
                                    </div>

                                    {(errors.name || errors.version || errors.product || submissionError) && (
                                        <div className={classNames(form.errorMultiLine, style.commonError)}>
                                            <span className={style.localBannerInfoIcon}>
                                                <FailedRedNotificationIcon
                                                    backgroundColor={theme.bannerIconBackgroundColor}
                                                    iconColor={theme.bannerIconColor}
                                                />
                                            </span>
                                            {submissionError ||
                                                t("ManageWorkflowDialog.selectWorkflowEditor.commonValidationError")}
                                        </div>
                                    )}
                                    <div className={classNames(form.buttonContainer)}>
                                        <button
                                            type="submit"
                                            className={buttons.primaryButtonWithoutIcon}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.productVersionSelection
                                                    .designWorkflowButton
                                            }
                                            onClick={() => validateForm().then(() => confirmWorkflowEditorSelection)}
                                        >
                                            {t("ManageWorkflowDialog.selectWorkflowEditor.designWorkflowButton")}
                                        </button>
                                    </div>
                                </div>
                                {confirmationModal}
                            </Form>
                        );
                    }}
                </Formik>
            );
            break;
        case DialogState.SELECTING_NEW_WORKFLOW_EDITOR:
            content = (
                <Formik
                    initialValues={{ product: props.product, version: "", name: "" }}
                    onSubmit={submitHandler}
                    validationSchema={object().shape({
                        name: string()
                            .required(t("WorkflowsTable.actions.create.validation.nameRequired"))
                            .max(WORKFLOW_NAME_MAX_LENGTH, t("WorkflowsTable.actions.create.validation.nameMaxLength"))
                            .test(
                                "Has only valid characters",
                                t("WorkflowsTable.actions.create.validation.nameSpecialCharacters"),
                                (value) => hasOnlyValidWorkflowCharacters(value)
                            ),
                    })}
                    validateOnChange={true}
                    validateOnBlur={true}
                >
                    {({ errors, handleChange, validateField }: FormikProps<CreateWorkflowFormValues>) => {
                        return (
                            <Form>
                                <div className={style.smallNewEditorDialog}>
                                    <div className={style.updateBanner}>
                                        <span className={style.localBannerInfoIcon}>
                                            <Info
                                                borderColor={theme.chartNeutralColor}
                                                color={theme.localBannerTextColor}
                                            />
                                        </span>
                                        <div>
                                            {props.entityType === "TEMPLATE"
                                                ? t(
                                                      "workflowTemplatesManager.updateTemplateDialog.updateTemplateIntroductionText"
                                                  )
                                                : t(
                                                      "workflowTemplatesManager.updateSubWorkflowDialog.updateSubWorkflowIntroductionText"
                                                  )}
                                        </div>
                                    </div>

                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="product"
                                                className={classNames(
                                                    form.label,
                                                    style.fixedWidthLabel,
                                                    style.updateWorkflowFormLabel
                                                )}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.productLabel")}
                                            </label>
                                        </div>
                                        <div className={style.profileContainer}>
                                            {profileToNameMap.get(props.product as Profile)}
                                        </div>
                                    </div>
                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="version"
                                                className={classNames(
                                                    form.label,
                                                    style.fixedWidthLabel,
                                                    style.updateWorkflowFormLabel
                                                )}
                                            >
                                                {t("ManageWorkflowDialog.selectWorkflowEditor.editorVersionLabel")}
                                            </label>
                                        </div>
                                        {updateWorkflowEditors.length === 1 ? (
                                            <div>{updateWorkflowEditors[0].version}</div>
                                        ) : (
                                            <div>
                                                <select
                                                    name="version"
                                                    className={classNames(form.select, style.fixedWidthVersion)}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        handleChange(e);
                                                        handleSelectedVersionChanged(e);
                                                    }}
                                                    data-testid={
                                                        testIds.workArea.workflows.manageWorkflowDialog
                                                            .UpdateWorkflowSelection.versionSelect
                                                    }
                                                >
                                                    {updateOptionList}
                                                </select>
                                            </div>
                                        )}
                                    </div>

                                    <div className={classNames(form.formFields, style.formField)}>
                                        <div>
                                            <label
                                                htmlFor="name"
                                                className={classNames(
                                                    form.label,
                                                    style.fixedWidthLabel,
                                                    style.updateWorkflowFormLabel
                                                )}
                                            >
                                                {t("Common.name")}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                id="versionSelectWorkflowName"
                                                name="name"
                                                type="text"
                                                className={classNames(form.input, style.fixedWidthName)}
                                                data-testid={
                                                    testIds.workArea.workflows.manageWorkflowDialog
                                                        .UpdateWorkflowSelection.nameInput
                                                }
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    handleChange(e);
                                                    handleWorkflowNameInputChange(e);
                                                }}
                                                onKeyUp={() => validateField("name")}
                                                maxLength={WORKFLOW_NAME_MAX_LENGTH}
                                            />
                                            {loader}

                                            <div className={classNames(form.error)}>{errors.name}</div>
                                        </div>
                                    </div>
                                    {(errors.name || submissionError) && (
                                        <div className={classNames(form.errorMultiLine, style.commonErrorNewEditor)}>
                                            <span className={style.localBannerInfoIcon}>
                                                <FailedRedNotificationIcon
                                                    backgroundColor={theme.bannerIconBackgroundColor}
                                                    iconColor={theme.bannerIconColor}
                                                />
                                            </span>
                                            {submissionError ||
                                                t("ManageWorkflowDialog.selectWorkflowEditor.commonValidationError")}
                                        </div>
                                    )}
                                    <ButtonContainer>
                                        <button
                                            type="submit"
                                            className={buttons.primaryButtonWithoutIcon}
                                            data-testid={
                                                testIds.workArea.workflows.manageWorkflowDialog.UpdateWorkflowSelection
                                                    .openNewWorkflowButton
                                            }
                                        >
                                            {t("Common.update")}
                                        </button>
                                        <button
                                            type="reset"
                                            className={buttons.secondaryButtonWithoutIcon}
                                            data-testid={testIds.common.dialog.closeButton}
                                            onClick={() => {
                                                props.onCancel(true);
                                            }}
                                        >
                                            {t("Common.noDontUpdateButton")}
                                        </button>
                                    </ButtonContainer>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            );
            break;
        case DialogState.EDITING:
            content = (
                <>
                    <WasmVisualEditorView
                        key={props.isEdit ? props.name : workflowNameInputValue}
                        profile={(props.product as Profile) || selectedProfile}
                        version={selectedVersion}
                        workflowEditors={workflowEditors}
                        uuid={props.isEdit || props.isUpdate ? props.uuid : undefined}
                        data-testid={testIds.workArea.workflows.manageWorkflowDialog.visualEditor.itself}
                        workflowName={workflowNameInputValue}
                        actionType={deduceActionType()}
                        entityType={props.entityType}
                        bmdeDiagnostics={selectedProfile}
                        preventNameChange={false}
                        setHasNewEditorLoaded={props.setHasNewEditorLoaded}
                    />
                    {confirmationModal}
                </>
            );
            break;
    }
    return content;
};

export default connector(ManageWorkflowTemplateDialog);
