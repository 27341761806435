import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./view-user-details.scss";
import GroupIcon from "components/icons/GroupIcon";
import LeftArrow from "components/icons/LeftArrow";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import AddUserForm from "components/users/add-user/AddUserForm";
import AuthorityView from "components/users/user-roles/manage-role/AuthorityView";
import { STANDARD_USER_TYPE } from "domain/user";
import { UserByUuid } from "domain/users";
import { userService } from "services/user/users/UserService";
import { getInitials } from "utils/commonFunctions";
import { formatDateWithoutTime } from "utils/format";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Props {
    selectedUser: string;
    showDetailsView: (value: boolean) => void;
}

const ViewUserDetails = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(true);
    const [userDetails, setUserDetails] = React.useState<UserByUuid>();
    const theme = useTheme();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");

    const fetchData = () => {
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        userService
            .fetchUser(props.selectedUser, abortController)
            .then((data: UserByUuid) => {
                setUserDetails(data);
                setLoading(false);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("ViewUser.userFetchFailureMessage"));
                }
            });
    };

    const authorities = () => {
        if (userDetails == null) {
            return [];
        }

        const userGroupsWithRoles = userDetails.groups?.filter((group) => group["role"]);

        return [
            ...new Set([
                ...userDetails.role.authorities,
                ...(userGroupsWithRoles
                    ? userGroupsWithRoles.flatMap((group) => (group.role == null ? [] : group.role.authorities))
                    : []),
            ]),
        ];
    };

    const createDataItems = (dataItems: Record<string, string>) => {
        return Object.assign.apply(null, [
            {},
            ...Object.entries(dataItems ?? {}).map((each) => ({ ["data-" + each[0]]: each[1] })),
        ]);
    };

    React.useEffect(() => {
        fetchData();

        return () => {
            abortControllers.forEach((controller) => controller.abort());
        };
    }, []);

    if (requestFailureMessage !== "") {
        return <span>{requestFailureMessage}</span>;
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <div className={style.container}>
            <div className={style.content}>
                <div className={style.backArrow}>
                    <button
                        onClick={() => props.showDetailsView(false)}
                        data-testid={testIds.common.secondaryView.goBackButton}
                    >
                        <LeftArrow color={theme.activeForegroundColor} />
                    </button>
                </div>
                <div className={style.section}>
                    <Heading tag={"h1"}>{t("ViewUser.title", { userName: userDetails?.name })}</Heading>
                    <Heading tag="div" variant="SUBTITLE_1">
                        {t("ViewUser.generalInformation")}
                    </Heading>
                    <AddUserForm
                        fullName={userDetails?.name}
                        email={userDetails?.email}
                        expirationDate={userDetails?.expirationDate ? userDetails?.expirationDate : ""}
                        disableEmail={true}
                        userRoleName={userDetails?.role.name}
                        enabled={userDetails?.enabled ? userDetails?.enabled : false}
                        disableStatus={true}
                        loginMethod={"DEFAULT"}
                        ssoEnabled={true}
                        onView={true}
                        licensePools={[]}
                        userLicensePoolUuid={""}
                        userType={userDetails?.userType || STANDARD_USER_TYPE}
                    />
                </div>
                <div className={style.section}>
                    <Heading tag="div" variant="SUBTITLE_1">
                        {t("UsersTable.groups")}
                    </Heading>
                    {!userDetails?.groups || userDetails?.groups.length === 0 ? (
                        <span>{t("ViewUser.noGroupsMessage")}</span>
                    ) : (
                        <div className={style.groupsTable}>
                            <StaticTable
                                testId={testIds.workArea.user.viewUserDialog.groupsTable.itself}
                                headers={[
                                    { value: t("UserGroups.table.groupName") },
                                    { value: t("UserGroups.table.totalUserCount") },
                                    { value: t("UserGroups.table.creationDate") },
                                    { value: t("UserGroups.table.role") },
                                ]}
                                cells={userDetails?.groups.map((data, key) => {
                                    return [
                                        <div
                                            key={"name" + key}
                                            className={style.groupName}
                                            {...createDataItems({
                                                testId: testIds.workArea.user.viewUserDialog.groupsTable.groupNameLabel,
                                                raw: JSON.stringify({ name: data.name }),
                                            })}
                                        >
                                            <GroupIcon
                                                backgroundColor={data.backgroundColor}
                                                textColor={data.textColor}
                                                groupInitials={getInitials(data.name)}
                                            />
                                            {data.name}
                                        </div>,
                                        <div
                                            key={"userCount" + key}
                                            {...createDataItems({
                                                testId: testIds.workArea.user.viewUserDialog.groupsTable.userCountLabel,
                                                raw: JSON.stringify({ user_count: data.userCount }),
                                            })}
                                        >
                                            {data.userCount}
                                        </div>,
                                        <div
                                            key={"createdDate" + key}
                                            {...createDataItems({
                                                testId: testIds.workArea.user.viewUserDialog.groupsTable
                                                    .creationDateLabel,
                                                raw: JSON.stringify({ created_date: data.createdDate }),
                                            })}
                                        >
                                            {formatDateWithoutTime(data.createdDate)}
                                        </div>,
                                        <div
                                            key={"role" + key}
                                            {...(data.role &&
                                                createDataItems({
                                                    testId: testIds.workArea.user.viewUserDialog.groupsTable
                                                        .roleNameLabel,
                                                    raw: JSON.stringify({
                                                        role: { name: data.role.name },
                                                    }),
                                                }))}
                                        >
                                            {data.role?.name}
                                        </div>,
                                    ];
                                })}
                            />
                        </div>
                    )}
                </div>
                <div className={style.section}>
                    <Heading tag="div" variant="SUBTITLE_1">
                        {t("ViewUser.authorities")}
                    </Heading>
                    <AuthorityView
                        authorities={authorities()}
                        onView={true}
                        isManager={false}
                        useNewLayout={true}
                        userGroups={userDetails?.groups}
                        userRole={userDetails?.role}
                    />
                </div>
            </div>
        </div>
    );
};

export default ViewUserDetails;
