import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import StepHeader from "components/home/StepHeader";
import ViewReportSubStep from "components/home/ViewReportSubStep";
import { BdeOnboardingSteps, BmdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepIndex: number;
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    stepClickable: boolean;
    product: OnboardingProductType;
    hasIbr: boolean;
    saveOnboardingStep: (onboarding: Onboarding) => void;
}

const ViewReport = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [showSubSection, setShowSubSection] = React.useState(false);
    const stepName =
        props.product == OnboardingProductType.BDE
            ? BdeOnboardingSteps.STEP_VIEWING_REPORT
            : BmdeOnboardingSteps.STEP_VIEWING_REPORT;

    const expandViewReport = () => {
        props.stepClickable && setShowSubSection(!showSubSection);
    };

    React.useEffect(() => {
        if (props.menuExpanded && props.stepClickable) {
            setShowSubSection(true);
        }
    }, [props.menuExpanded]);

    return (
        <StepHeader
            success={props.stepCompleted}
            showStep={showSubSection}
            inactive={!props.stepClickable}
            title={t("Onboarding.bmde.gettingStarted.steps.viewReport.title")}
            stepIndex={props.stepIndex}
            menuExpanded={props.menuExpanded}
            clickEvent={expandViewReport}
            showContent={showSubSection}
            content={
                <ViewReportSubStep
                    product={props.product}
                    stepCompleted={props.stepCompleted}
                    hasIbr={props.hasIbr}
                    saveOnboardingStep={props.saveOnboardingStep}
                    stepName={stepName}
                />
            }
            type={props.product}
            stepName={stepName}
        />
    );
};

export default connector(ViewReport);
