import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import style from "./mcs.scss";
import McsTable from "./McsTable";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { Licenses } from "components/icons/Licenses";
import { NoAccess } from "components/icons/NoAccess";
import { SuccessIcon } from "components/icons/SuccessIcon";
import ApplicationLayout from "components/layout/ApplicationLayout";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { MCS_ROUTE } from "components/router/Routes";
import Tooltip from "components/tooltip/Tooltip";
import { AUTH_MC_CREATE } from "domain/authority";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { licenseService } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { isInTenantAccess } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import buttonsStyle from "styles/buttons.scss";
import { logger } from "utils/logging";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

export default function AllMcsView(): JSX.Element {
    const { t } = useTranslation();
    const theme = useTheme();
    const [registrationToken, setRegistrationToken] = useState<string>("");
    const [state, setState] = useState({ count: 0 });
    const ON_PREMISE_BMP_LICENSE_TYPE_ID = "51";

    // TODO BCC-4316 and BCC-4487 - Need to add required logic for unregister and edit as required.
    // I have just set random return for onMcsUnregister and onMcsEdit just to create the flow

    const fetchAvailableLicenses = async (licenseType: string) => {
        try {
            const data = await licenseService.fetchAvailableLicenses(licenseType);
            return data.availableLicenses;
        } catch (err) {
            logger.error("Exception occurred while fetching available licenses: ", err);
            return 0;
        }
    };

    const { data: availableLicenses, isLoading } = useQuery(["availableLicenses", ON_PREMISE_BMP_LICENSE_TYPE_ID], () =>
        fetchAvailableLicenses(ON_PREMISE_BMP_LICENSE_TYPE_ID)
    );

    function updateTableView() {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    }

    const handleCopyToken = () => {
        hybridMcService
            .createMcRegistrationToken()
            .then((registrationToken) => {
                setRegistrationToken(registrationToken.token);
                return navigator.clipboard.writeText(registrationToken.token).then(() => {
                    toast(
                        <SuccessIcon
                            successClass={layoutStyle.customToastSuccessIcon}
                            color={theme.contentBackgroundColor}
                            text={t("Mcs.copyRegistrationCodeSuccess")}
                        />,
                        {
                            closeButton: (closeToastProps) => (
                                <CustomToastCloseButton
                                    closeToast={{ ...closeToastProps }}
                                    color={theme.iconFillColor}
                                />
                            ),
                            className: layoutStyle.customToastSuccessMessage,
                        }
                    );
                });
            })
            .catch((error) => logger.error("Couldn't copy to clipboard", error));
    };

    const userHasAuthority = userSessionService.userHasAllAuthorities([AUTH_MC_CREATE]);
    const licensesAvailable = (availableLicenses ?? 0) > 0;
    const copyRegistrationCodeButton = (
        <div className={style.viewActionsContainer}>
            {userHasAuthority ? (
                <>
                    <Tooltip content={t("Mcs.availableLicensesInfo")}>
                        <div className={style.availableLicensesContainer}>
                            <Licenses size={20} color={theme.textColor} />
                            <div data-testid={testIds.workArea.mcs.availableLicensesLabel}>{availableLicenses}</div>
                        </div>
                    </Tooltip>
                    <button
                        className={buttonsStyle.primaryButtonWithoutIcon}
                        onClick={() => {
                            handleCopyToken();
                            usageStatisticsService.sendEvent({
                                category: Category.MCS,
                                action: Action.REGISTER_MC,
                            });
                        }}
                        data-testid={testIds.workArea.mcs.getRegistrationCodeButton}
                        data-token={JSON.stringify({
                            token: registrationToken,
                        })}
                        disabled={!licensesAvailable}
                    >
                        {t("Mcs.copyRegistrationCode")}
                    </button>
                </>
            ) : (
                <div className={style.missingAuthorityWrapper}>
                    <NoAccess color={theme.textColor} />
                    <span>{t("Mcs.authorityMissing")}</span>
                </div>
            )}
        </div>
    );

    const viewActions = (
        <>
            {isLoading ? (
                <LoadingIndicator small={true} />
            ) : (
                !isInTenantAccess() && <div className={style.viewActionsContainer}>{copyRegistrationCodeButton}</div>
            )}
        </>
    );

    return (
        <ApplicationLayout
            viewTitle={t("Mcs.table.title")}
            view={
                <McsTable
                    count={state.count}
                    initialMcsData={{
                        tenantUuid: "",
                        name: "",
                        description: "",
                    }}
                    onMcChange={updateTableView}
                />
            }
            viewActions={viewActions}
            mainRoute={MCS_ROUTE}
        />
    );
}
