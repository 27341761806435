import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import style from "./bmp-setup.scss";
import { Reports } from "components/icons/Reports";
import { Users } from "components/icons/Users";
import { Workflows } from "components/icons/Workflows";
import Modal from "components/modal/Modal";
import { ALL_REPORTS_ROUTE, ALL_WORKFLOWS_ROUTE, USERS_ALL_USERS_ROUTE } from "components/router/Routes";
import {
    AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
    AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
    AUTH_USER_CREATE,
    AUTH_USER_VIEW,
    AUTH_WORKFLOW_CREATE,
    AUTH_WORKFLOW_VIEW,
} from "domain/authority";
import { OnboardingProductType } from "domain/onboardings";
import { FeatureLicenseType } from "domain/users";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { isUserParentInternal } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface LinkDetails {
    featureLicenses: FeatureLicenseType[];
    authorities: string[];
    all: boolean;
    linkTitle: string;
    icon: JSX.Element;
    routePath: string;
    testId: string;
    usageStatisticsAction: Action;
}

export const BmpSetup = (props: { product: OnboardingProductType }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    type Link = "CUSTOM_VIEWS" | "USERS" | "WORKFLOWS";
    const history = useHistory();
    const navigateToReports = (toCreateViews: boolean) => {
        history.push(ALL_REPORTS_ROUTE.path, { toCreateViews });
    };
    const linkToVisibility = new Map<Link, LinkDetails>([
        [
            "CUSTOM_VIEWS",
            {
                featureLicenses: ["FEATURE_CUSTOM_REPORT_VIEWS"],
                authorities: [AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN, AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED],
                all: false,
                linkTitle: t("Onboarding.nextSteps.customViews"),
                icon: <Reports color={theme.iconFillColor} />,
                routePath: ALL_REPORTS_ROUTE.path,
                testId: testIds.workArea.onboarding.bmpSetupContainer.reportsLink,
                usageStatisticsAction: Action.CREATE_CUSTOM_VIEW,
            },
        ],
        [
            "WORKFLOWS",
            {
                featureLicenses: ["FEATURE_WORKFLOW_BDE", "FEATURE_WORKFLOW_BMDE", "FEATURE_WORKFLOW_LUN"],
                authorities: [AUTH_WORKFLOW_VIEW, AUTH_WORKFLOW_CREATE],
                all: true,
                linkTitle: t("Onboarding.nextSteps.workflows"),
                icon: <Workflows color={theme.iconFillColor} />,
                routePath: ALL_WORKFLOWS_ROUTE.path,
                testId: testIds.workArea.onboarding.bmpSetupContainer.workflowsLink,
                usageStatisticsAction: Action.CREATE_WORKFLOW,
            },
        ],
        [
            "USERS",
            {
                featureLicenses: [],
                authorities: [AUTH_USER_CREATE, AUTH_USER_VIEW],
                all: true,
                linkTitle: t("Onboarding.nextSteps.users"),
                icon: <Users color={theme.iconFillColor} />,
                routePath: USERS_ALL_USERS_ROUTE.path,
                testId: testIds.workArea.onboarding.bmpSetupContainer.usersLink,
                usageStatisticsAction: Action.INVITE_USER,
            },
        ],
    ]);
    const [visibleFeedback, setVisibleFeedback] = React.useState(true);

    function createCard(cardProperties: LinkDetails) {
        let featureCheck = cardProperties.featureLicenses.length < 1;
        for (const each of cardProperties.featureLicenses) {
            if (userSessionService.hasFeatureLicense(each)) {
                featureCheck = true;
                break;
            }
        }
        if (
            !featureCheck ||
            !(cardProperties.all
                ? userSessionService.userHasAllAuthorities(cardProperties.authorities)
                : userSessionService.userHasAnyAuthority(cardProperties.authorities))
        ) {
            return null;
        }
        return (
            <div
                className={classNames(style.linkCard, style.linkText)}
                key={cardProperties.linkTitle}
                data-testid={cardProperties.testId}
                onClick={() => {
                    usageStatisticsService.sendEvent({
                        category: Category.ONBOARDING,
                        action: cardProperties.usageStatisticsAction,
                        label: props.product,
                    });
                    if (cardProperties.routePath === ALL_REPORTS_ROUTE.path) {
                        navigateToReports(true);
                    } else {
                        window.open(cardProperties.routePath, "_self");
                    }
                }}
            >
                {cardProperties.icon}
                <div className={style.linkText}>{cardProperties.linkTitle}</div>
            </div>
        );
    }

    const cards = [...linkToVisibility.keys()].map((each) => {
        const link = linkToVisibility.get(each);
        if (link) {
            return link;
        }
    });

    function createFeedbackForm() {
        if (isUserParentInternal()) {
            return null;
        }
        const link =
            props.product === OnboardingProductType.BDE
                ? "https://forms.office.com/Pages/ResponsePage.aspx?id=cVEcYP9hSka_vWPlS0wOOcYy-k0cRSFDuZeYFeN72xRUMkZMS1FVTUJMMzdCOEtVSVg2S1VVMDVNMy4u&embed=true"
                : "https://forms.office.com/Pages/ResponsePage.aspx?id=cVEcYP9hSka_vWPlS0wOOcYy-k0cRSFDuZeYFeN72xRUMlVXR1c1RkVYN0JRT0FITjNUOE80UTVJQy4u";
        return (
            <Modal
                isOpen={visibleFeedback}
                hideModal={() => {
                    setVisibleFeedback(false);
                }}
                modalTitle={t("Onboarding.nextSteps.feedbackTitle")}
            >
                <iframe
                    width="640px"
                    height="480px"
                    src={link}
                    frameBorder="0"
                    marginWidth={0}
                    marginHeight={0}
                ></iframe>
            </Modal>
        );
    }

    return (
        <div className={style.margin}>
            {createFeedbackForm()}
            <div className={style.title}>{t("Onboarding.nextSteps.whatNext")}</div>
            <div
                className={classNames(
                    style.linksContainer,
                    cards.length == 2 ? style.twoColumns : cards.length === 3 ? style.threeColumns : null
                )}
            >
                {cards.map((each) => each && createCard(each))}
            </div>
        </div>
    );
};
