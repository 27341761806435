import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./dashboard.scss";
import Info from "components/icons/Info";
import Tooltip from "components/tooltip/Tooltip";
import { WidgetDetails } from "domain/dashboard";
import { useTheme } from "utils/useTheme";

export const WidgetContainer = (props: WidgetDetails): JSX.Element => {
    const { t } = useTranslation();

    const dragHandleClassName = "drag-handle";
    const calendar = document.getElementsByClassName("src-components-dashboard-dashboard__dropdown")[0] as HTMLElement;
    if (calendar) {
        calendar.setAttribute("aria-label", t("AltText.calendar"));
    }
    const theme = useTheme();

    return (
        <div className={classNames(props.type === "CREATE_CHART" ? style.cardCreateChart : style.card)}>
            {props.type !== "CREATE_CHART" ? (
                <div className={style.cardHeader}>
                    <Tooltip content={props.title}>
                        <div className={style.cardTitle}>{t(props.title)}</div>
                    </Tooltip>
                    {props.hasInfoIcon && props.description?.trim() !== "" ? (
                        <Tooltip content={props.description}>
                            <div className={style.info} tabIndex={0}>
                                <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                            </div>
                        </Tooltip>
                    ) : (
                        ""
                    )}
                    {props.actions && <div className={style.widgetActions}>{props.actions}</div>}

                    {props.hideDragIcon ? (
                        ""
                    ) : (
                        <div className={style.dropdown}>
                            <div
                                aria-label={t("AltText.dragCardButton")}
                                className={classNames(style.cardMenuButton, dragHandleClassName)}
                            />
                        </div>
                    )}
                </div>
            ) : (
                ""
            )}
            {props.children}
        </div>
    );
};
