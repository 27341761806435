import * as React from "react";

interface Props {
    color?: string;
}

export const ContactIcon = React.forwardRef<SVGSVGElement, Props>(
    (props): JSX.Element => (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6668 17.0827C14.5788 17.0827 17.7502 13.9114 17.7502 9.99935C17.7502 6.08733 14.5788 2.91602 10.6668 2.91602C6.75481 2.91602 3.5835 6.08733 3.5835 9.99935C3.5835 13.9114 6.75481 17.0827 10.6668 17.0827ZM10.6668 18.3327C15.2692 18.3327 19.0002 14.6017 19.0002 9.99935C19.0002 5.39698 15.2692 1.66602 10.6668 1.66602C6.06446 1.66602 2.3335 5.39698 2.3335 9.99935C2.3335 14.6017 6.06446 18.3327 10.6668 18.3327Z"
                fill={props.color}
            />
            <path
                d="M11.5002 14.9993C11.5002 15.4596 11.1271 15.8327 10.6668 15.8327C10.2066 15.8327 9.8335 15.4596 9.8335 14.9993C9.8335 14.5391 10.2066 14.166 10.6668 14.166C11.1271 14.166 11.5002 14.5391 11.5002 14.9993Z"
                fill={props.color}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.60139 5.83348C9.16722 5.45626 9.91189 5.20768 10.6668 5.20768C11.425 5.20768 12.2036 5.4597 12.8008 5.99055C13.4095 6.53163 13.7918 7.32975 13.7918 8.33268C13.7918 9.09085 13.5398 9.86945 13.009 10.4667C12.5856 10.9429 12.0049 11.2806 11.2918 11.405V11.9438C11.2918 12.289 11.012 12.5688 10.6668 12.5688C10.3217 12.5688 10.0418 12.289 10.0418 11.9438V10.8327C10.0418 10.4875 10.3217 10.2077 10.6668 10.2077C11.3306 10.2077 11.7824 9.965 12.0747 9.6362C12.3772 9.29591 12.5418 8.82451 12.5418 8.33268C12.5418 7.66895 12.2991 7.21707 11.9704 6.92481C11.6301 6.62233 11.1587 6.45768 10.6668 6.45768C10.1718 6.45768 9.66644 6.62577 9.29477 6.87355C8.89582 7.13951 8.79183 7.39161 8.79183 7.49935C8.79183 7.84453 8.51201 8.12435 8.16683 8.12435C7.82165 8.12435 7.54183 7.84453 7.54183 7.49935C7.54183 6.77375 8.06284 6.19252 8.60139 5.83348Z"
                fill={props.color}
            />
        </svg>
    )
);
