import { TableData } from "./table";

export interface Mcs {
    tenantUuid: string;
    name?: string;
    registrationDate?: string;
    lastUpdatedDate?: string;
    status?: McStatus;
    uuid?: string;
    description?: string;
}

export interface McTable {
    desired: number;
    cursor: string;
    mcdata: McsTableData[];
}

export enum McStatus {
    ACTIVE = "ACTIVE",
    UNREGISTERED = "UNREGISTERED",
    DISABLED = "DISABLED",
}

export const getMcStatusDisplay = (status: McStatus): string => {
    const statusMap: Record<McStatus, string> = {
        [McStatus.ACTIVE]: "Mcs.table.mcStatus.active",
        [McStatus.UNREGISTERED]: "Mcs.table.mcStatus.unregistered",
        [McStatus.DISABLED]: "Mcs.table.mcStatus.disabledByBlanccoText",
    };

    return statusMap[status] || status;
};

export interface StatusBadgeConfig {
    text: string;
    className: string;
}

export interface License {
    expiration: string;
    remaining: number;
}

export interface McsTableData extends TableData {
    name: string;
    description: string;
    status: McStatus;
    uuid: string;
    registrationDate: string;
    lastUpdatedDate: string;
    deadlineDate: string;
    reportCount: number;
    userCount: number;
    workflowCount: number;
    licenses: Record<string, License>;
    halted: boolean;
    mcVersion?: string;
    containerId: string;
}

export interface McsDataDto {
    name: string;
    registration_date: string;
    last_updated_date: string;
    status: McStatus;
    uuid: string;
    description: string;
    deadline_date: string;
    halted: boolean;
    report_count: number;
    user_count: number;
    workflow_count: number;
    licenses: Record<string, License>;
    mc_version?: string;
    container_id: string;
}

export interface McsResponseDto {
    mcs: McsDataDto[];
    cursor: string;
    desired: number;
}

export function toMcsData(dto: McsDataDto): McsTableData {
    return {
        name: dto.name,
        description: dto.description,
        status: dto.status,
        uuid: dto.uuid,
        registrationDate: dto.registration_date,
        reportCount: dto.report_count,
        userCount: dto.user_count,
        workflowCount: dto.workflow_count,
        licenses: dto.licenses,
        lastUpdatedDate: dto.last_updated_date,
        deadlineDate: dto.deadline_date,
        halted: dto.halted,
        mcVersion: dto.mc_version,
        containerId: dto.container_id,
    };
}
