export enum RepositoryKey {
    ALL_API_KEYS_TABLE = "allApiKeysTable",
    ALL_REPORTS_TABLE = "allReportsTable",
    ARTHUR_URL = "arthurUrl",
    CONDITIONAL_SUMMARY_TABLE = "conditionalSummaryTable",
    CONFIGURATION_TABLE = "configuration",
    CONTAINERS_TABLE = "containers",
    CUSTOMER_ESG_DASHBOARD_CARD_LAYOUT = "customerEsgDashboardCardLayout",
    DASHBOARD_CARD_LAYOUT = "dashboardCardLayout",
    DATA_COLLECTION = "dataCollection",
    DELIVERY_HISTORY_TABLE = "deliveryHistory",
    DIAGNOSTIC_SUMMARY_TABLE = "diagnosticSummaryTable",
    ENTITLEMENT_TABLE = "entitlementTable",
    ESG_DASHBOARD_CARD_LAYOUT = "esgDashboardCardLayoutV1",
    FEATURE_FLAGS = "featureFlags",
    LAUREL_URL = "laurelUrl",
    LICENSE_TABLE = "licensesTable",
    LOCALE = "locale",
    MODE = "mode",
    MY_API_KEYS_TABLE = "myApiKeysTable",
    OLIVER_URL = "oliverUrl",
    ORIGINAL_ARTHUR_URL = "originalArthurUrl",
    ORIGINAL_LAUREL_URL = "originalLaurelUrl",
    ORIGINAL_OLIVER_URL = "originalOliverUrl",
    ORIGINAL_PUBLIC_API_DOCUMENTATION_URL = "originalPublicApiDocumentationUrl",
    ORIGINAL_PUBLIC_API_URL = "originalPublicApiUrl",
    ORIGINAL_STAN_URL = "originalStanUrl",
    PRODUCT_MANUAL_URL = "supportContentUrl",
    PUBLIC_API_DOCUMENTATION_URL = "publicApiDocumentationUrl",
    PUBLIC_API_URL = "publicApiUrl",
    SEEN_NOTIFICATIONS = "seenNotifications",
    STAN_URL = "stanUrl",
    SUPPORT_AND_HELP_CARD_LAYOUT = "supportAndHelpCardLayout",
    SUPPORT_SOFTWARE_URL = "supportSoftwareUrl",
    TEMPLATES_TABLE = "templatesTable",
    TENANT_DETAILS = "tenant.details",
    TENANT_TABLE = "tenantsTable",
    USER_DETAILS = "user.details",
    USER_TABLE = "usersTable",
    USER_ROLES_TABLE = "userRolesTable",
    USER_GROUPS_TABLE = "userGroupsTable",
    GROUP_USERS_TABLE = "groupUsersTable",
    VIDEOS_URL = "supportVideosUrl",
    WORKFLOWS_TABLE = "workflowsTable",
    SIDE_NAVIGATION = "sideNavigation",
    LICENSE_ALERTS_TABLE = "licenseAlertsTable",
    LICENSE_EVENTS_TABLE = "licenseEventsTable",
    LICENSE_POOLS_TABLE = "licensePoolsTable",
    REPORT_VIEW_SETTINGS = "reportViewSettings",
    REPORT_IMPORT_TABLE = "reportImportTable",
    REPORT_IMPORT_FAILED_UPLOAD_TABLE = "reportImportFailedUploadTable",
    REPORT_IMPORT_RESULTS_TABLE = "reportImportResultsTable",
    REPORT_DELETION_RESULTS_TABLE = "reportDeletionResultsTable",
    LOCAL_BMP_VERSION = "bmpVersion",
    BMS_LICENSES = "bmsLicenses",
    ONLY_BMS = "onlyBms",
    REPORT_PATHS_TABLE = "reportPathsTable",
    EMS_ID = "emsId",
    ACTIVE_TOP_MENU = "activeTopMenu",
    REPORTS_TO_BE_DELETED = "reportsToBeDeleted",
    ALL_GROUP_USERS_TABLE = "userList",
    SELECTED_GROUP_USERS_TABLE = "selectedUserList",
    WORKFLOW_TEMPLATES_TABLE = "workflowTemplatesTable",
    SUB_WORKFLOWS_TABLE = "subWorkflowsTable",
    MCS_TABLE = "mcsTable",
}

export function setObject<T>(key: RepositoryKey, object: T): void {
    window.localStorage.setItem(key, JSON.stringify(object));
}

export function getObject<T>(key: RepositoryKey): T | null {
    const object = window.localStorage.getItem(key);
    if (object === null) {
        return null;
    }
    return JSON.parse(object);
}

export function remove(key: RepositoryKey): void {
    window.localStorage.removeItem(key);
}
