import { TableData } from "./table";

export enum Authority {
    AUTH_API_KEY_CREATE_OWN = "AUTH_API_KEY_CREATE_OWN",
    AUTH_API_KEY_DELETE_OWN = "AUTH_API_KEY_DELETE_OWN",
    AUTH_API_KEY_EDIT_OWN = "AUTH_API_KEY_EDIT_OWN",
    AUTH_API_KEY_VIEW_ALL = "AUTH_API_KEY_VIEW_ALL",
    AUTH_API_KEY_VIEW_OWN = "AUTH_API_KEY_VIEW_OWN",
    AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN = "AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN",
    AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED = "AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED",
    AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN = "AUTH_CUSTOM_REPORT_VIEW_DELETE_OWN",
    AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED = "AUTH_CUSTOM_REPORT_VIEW_DELETE_SHARED",
    AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN = "AUTH_CUSTOM_REPORT_VIEW_EDIT_OWN",
    AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED = "AUTH_CUSTOM_REPORT_VIEW_EDIT_SHARED",
    AUTH_CUSTOM_REPORT_VIEW_VIEW = "AUTH_CUSTOM_REPORT_VIEW_VIEW",
    AUTH_EMS_CREATE_ENTITLEMENT = "AUTH_EMS_CREATE_ENTITLEMENT",
    AUTH_EMS_SYNCHRONIZE = "AUTH_EMS_SYNCHRONIZE",
    AUTH_EMS_VIEW = "AUTH_EMS_VIEW",
    AUTH_KEY_VALUE_CREATE = "AUTH_KEY_VALUE_CREATE",
    AUTH_KEY_VALUE_DELETE = "AUTH_KEY_VALUE_DELETE",
    AUTH_KEY_VALUE_EDIT = "AUTH_KEY_VALUE_EDIT",
    AUTH_KEY_VALUE_VIEW = "AUTH_KEY_VALUE_VIEW",
    AUTH_LICENSE_ALERT = "AUTH_LICENSE_ALERT",
    AUTH_LICENSE_ASSIGN = "AUTH_LICENSE_ASSIGN",
    AUTH_LICENSE_CONSUME = "AUTH_LICENSE_CONSUME",
    AUTH_LICENSE_HISTORY_VIEW_ALL = "AUTH_LICENSE_HISTORY_VIEW_ALL",
    AUTH_LICENSE_HISTORY_VIEW_OWN = "AUTH_LICENSE_HISTORY_VIEW_OWN",
    AUTH_LICENSE_POOL_CREATE = "AUTH_LICENSE_POOL_CREATE",
    AUTH_LICENSE_POOL_DELETE = "AUTH_LICENSE_POOL_DELETE",
    AUTH_LICENSE_POOL_EDIT = "AUTH_LICENSE_POOL_EDIT",
    AUTH_LICENSE_POOL_VIEW = "AUTH_LICENSE_POOL_VIEW",
    AUTH_LICENSE_VIEW = "AUTH_LICENSE_VIEW",
    AUTH_LICENSE_VIEW_OWN = "AUTH_LICENSE_VIEW_OWN",
    AUTH_REPORT_DELETE = "AUTH_REPORT_DELETE",
    AUTH_REPORT_EDIT = "AUTH_REPORT_EDIT",
    AUTH_REPORT_EXPORT_ALL = "AUTH_REPORT_EXPORT_ALL",
    AUTH_REPORT_IMPORT = "AUTH_REPORT_IMPORT",
    AUTH_REPORT_TEMPLATE_CREATE = "AUTH_REPORT_TEMPLATE_CREATE",
    AUTH_REPORT_TEMPLATE_DELETE = "AUTH_REPORT_TEMPLATE_DELETE",
    AUTH_REPORT_TEMPLATE_EDIT = "AUTH_REPORT_TEMPLATE_EDIT",
    AUTH_REPORT_TEMPLATE_VIEW = "AUTH_REPORT_TEMPLATE_VIEW",
    AUTH_REPORT_VIEW = "AUTH_REPORT_VIEW",
    AUTH_ROLE_CREATE = "AUTH_ROLE_CREATE",
    AUTH_ROLE_DEFAULT = "AUTH_ROLE_DEFAULT",
    AUTH_ROLE_DELETE = "AUTH_ROLE_DELETE",
    AUTH_ROLE_EDIT = "AUTH_ROLE_EDIT",
    AUTH_ROLE_VIEW = "AUTH_ROLE_VIEW",
    AUTH_SSO_CONFIGURE = "AUTH_SSO_CONFIGURE",
    AUTH_TENANT_ACCESS = "AUTH_TENANT_ACCESS",
    AUTH_TENANT_CREATE = "AUTH_TENANT_CREATE",
    AUTH_TENANT_DELETE = "AUTH_TENANT_DELETE",
    AUTH_TENANT_EDIT = "AUTH_TENANT_EDIT",
    AUTH_TENANT_SETTINGS = "AUTH_TENANT_SETTINGS",
    AUTH_TENANT_VIEW = "AUTH_TENANT_VIEW",
    AUTH_USER_CREATE = "AUTH_USER_CREATE",
    AUTH_USER_DELETE = "AUTH_USER_DELETE",
    AUTH_USER_EDIT = "AUTH_USER_EDIT",
    AUTH_USER_GROUP_CREATE = "AUTH_USER_GROUP_CREATE",
    AUTH_USER_GROUP_DELETE = "AUTH_USER_GROUP_DELETE",
    AUTH_USER_GROUP_EDIT = "AUTH_USER_GROUP_EDIT",
    AUTH_USER_GROUP_VIEW = "AUTH_USER_GROUP_VIEW",
    AUTH_USER_VIEW = "AUTH_USER_VIEW",
    AUTH_WORKFLOW_CREATE = "AUTH_WORKFLOW_CREATE",
    AUTH_WORKFLOW_DEFAULT = "AUTH_WORKFLOW_DEFAULT",
    AUTH_WORKFLOW_DELETE = "AUTH_WORKFLOW_DELETE",
    AUTH_WORKFLOW_EDIT = "AUTH_WORKFLOW_EDIT",
    AUTH_WORKFLOW_ACTIVATE = "AUTH_WORKFLOW_ACTIVATE",
    AUTH_WORKFLOW_TEMPLATE_CREATE = "AUTH_WORKFLOW_TEMPLATE_CREATE",
    AUTH_WORKFLOW_TEMPLATE_DELETE = "AUTH_WORKFLOW_TEMPLATE_DELETE",
    AUTH_WORKFLOW_TEMPLATE_EDIT = "AUTH_WORKFLOW_TEMPLATE_EDIT",
    AUTH_WORKFLOW_TEMPLATE_PUBLISH = "AUTH_WORKFLOW_TEMPLATE_PUBLISH",
    AUTH_WORKFLOW_TEMPLATE_SAVE = "AUTH_WORKFLOW_TEMPLATE_SAVE",
    AUTH_WORKFLOW_TEMPLATE_VIEW = "AUTH_WORKFLOW_TEMPLATE_VIEW",
    AUTH_WORKFLOW_VIEW = "AUTH_WORKFLOW_VIEW",
    AUTH_WORKFLOW_TEMPLATE_MANAGEMENT_VIEW = "AUTH_WORKFLOW_TEMPLATE_MANAGEMENT_VIEW",
    AUTH_MC_CREATE = "AUTH_MC_CREATE",
    AUTH_MC_VIEW = "AUTH_MC_VIEW",
    AUTH_MC_EDIT = "AUTH_MC_EDIT",
    AUTH_MC_UNREGISTER = "AUTH_MC_UNREGISTER",
}

export const EMS_AUTHORITIES = new Set([
    Authority.AUTH_EMS_VIEW,
    Authority.AUTH_EMS_SYNCHRONIZE,
    Authority.AUTH_EMS_CREATE_ENTITLEMENT,
]);

export const TARGET_AUTHORITIES = [
    Authority.AUTH_REPORT_DELETE,
    Authority.AUTH_REPORT_VIEW,
    Authority.AUTH_REPORT_EDIT,
];

export const ROOT_ONLY_AUTHORITIES = new Set([
    Authority.AUTH_WORKFLOW_TEMPLATE_PUBLISH,
    Authority.AUTH_WORKFLOW_TEMPLATE_DELETE,
    Authority.AUTH_WORKFLOW_TEMPLATE_EDIT,
    Authority.AUTH_WORKFLOW_TEMPLATE_CREATE,
]);

export interface Target {
    kind: string;
    value: string;
}

export interface Targets {
    authorities: {
        [authority: string]: {
            targets: Target[];
        };
    };
}

export interface Role {
    roleUuid: string;
    name: string;
    description?: string;
    defaultMigrationRole: boolean;
    authority: Authority[];
    targets?: Targets;
}

export interface RoleData {
    name?: string;
    description?: string;
    authority?: Authority[];
    targets?: Targets;
}

export interface RoleTable extends RoleData {
    cursor: string;
    roles: UserRolesTableData[];
}

export interface UserRolesTableData extends TableData {
    name: string;
    roleUuid: string;
    description: string;
    createdDate: string;
    editedDate: string;
    creator: string;
    defaultRole: boolean;
    defaultMigrationRole: boolean;
    authorities: [Authority.AUTH_API_KEY_CREATE_OWN];
    targets: Targets;
}

export interface UserRolesDataDto {
    name: string;
    role_uuid: string;
    description: string;
    created_date: string;
    edited_date: string;
    creator: string;
    default_role: boolean;
    default_migration_role: boolean;
    authorities: [Authority.AUTH_API_KEY_CREATE_OWN];
    targets: Targets;
}

export interface UserRolesResponseDto {
    roles: UserRolesDataDto[];
    cursor: string;
}

export function toUserRoleData(dto: UserRolesDataDto): UserRolesTableData {
    return {
        name: dto.name,
        roleUuid: dto.role_uuid,
        description: dto.description,
        createdDate: dto.created_date,
        editedDate: dto.edited_date,
        creator: dto.creator,
        defaultRole: dto.default_role,
        defaultMigrationRole: dto.default_migration_role,
        authorities: dto.authorities,
        targets: dto.targets,
    };
}

export interface RoleInUserResponseDto {
    authorities: Authority[];
    authority_targets: Targets;
    name: string;
    uuid: string;
}

export interface RoleInUserResponse {
    authorities: Authority[];
    authorityTargets: Targets;
    name: string;
    uuid: string;
}
