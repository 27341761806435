import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";

import style from "components/home/bde-home-page/getting-started.scss";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    number: number;
    subStep: boolean;
}

const CircleWithNumber = (props: Props): JSX.Element => {
    const circleStyle: string = classNames(props.subStep ? style.circleNumber : style.circleNumberActive);
    return (
        <>
            <div className={circleStyle}>{props.number}</div>
        </>
    );
};

export default connector(CircleWithNumber);
