import classNames from "classnames";
import { useFeature } from "flagged";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./user-menu.scss";
import { ViewTenantDetails } from "components/customer-details/ViewTenantDetails";
import { DropdownMenu, DropdownMenuDivider } from "components/dropdown-menu/DropdownMenu";
import ToggleablePanel from "components/header/ToggleablePanel";
import Chevron from "components/icons/Chevron";
import LanguageMenu from "components/language-menu/LanguageMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import CreateNewPasswordView from "components/login/create-new-password/CreateNewPasswordView";
import { ErrorModal } from "components/login/ErrorModal";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import SettingsView from "components/settings/SettingsView";
import UserProfileView from "components/users/profile/UserProfileView";
import { Tenant } from "domain/tenants";
import { FLAG_CHANGE_PASSWORD, FLAG_NEW_NAVIGATION } from "services/feature/FeatureFlagService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getTenantUuid, hasTenantCookie } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

enum ProfileState {
    LOADING = 0,
    LOADING_SUCCESS,
    LOADING_FAILED,
}

interface Props {
    className?: string;
}

export const UserMenu = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [userProfileVisibility, setUserProfileVisibility] = useState(false);
    const [settingsVisibility, setSettingsVisibility] = useState(false);
    const [changePasswordFormVisibility, setChangePasswordFormVisibility] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = React.useState<boolean>(false);
    const showUserProfile = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_USER_PROFILE,
        });
        setUserProfileVisibility(true);
    };

    const hideUserProfile = () => {
        setUserProfileVisibility(false);
    };

    const showChangePasswordForm = () => {
        setChangePasswordFormVisibility(true);
    };
    const hideChangePassword = () => {
        setChangePasswordFormVisibility(false);
    };

    const showSettings = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_USER_SETTINGS,
        });
        setSettingsVisibility(true);
    };

    const hideSettings = () => {
        setSettingsVisibility(false);
    };

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [tenantDetails, setTenantDetails] = React.useState<Tenant>();
    const [profileState, setProfileState] = React.useState(ProfileState.LOADING);
    const [tenantProfileVisibility, setTenantProfileVisibility] = useState(false);
    const isNewNavigationEnabled = useFeature(FLAG_NEW_NAVIGATION) === true;

    const fetchData = () => {
        setTenantProfileVisibility(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .fetchTenant(getTenantUuid(), abortController)
            .then((tenantDetails) => {
                setTenantDetails(tenantDetails);
                setProfileState(ProfileState.LOADING_SUCCESS);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setProfileState(ProfileState.LOADING_FAILED);
                }
            });
    };

    let child = <></>;
    switch (profileState) {
        case ProfileState.LOADING:
            child = <LoadingIndicator />;
            break;
        case ProfileState.LOADING_SUCCESS:
            child = tenantDetails ? <ViewTenantDetails activeTenant={tenantDetails} /> : <></>;
            break;
        case ProfileState.LOADING_FAILED:
            child = (
                <div>
                    <div className={style.errorContainer}>{t("CustomerProfile.refreshRequestFailed")}</div>
                    <div className={form.buttonContainer}>
                        <button
                            className={buttonStyle.primaryOkButton}
                            onClick={() => {
                                setTenantProfileVisibility(false);
                            }}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            );
            break;
    }

    const tenantProfileModal = (
        <Modal
            key={1}
            isOpen={tenantProfileVisibility}
            hideModal={() => setTenantProfileVisibility(false)}
            modalTitle={t("Routes.profile")}
        >
            {child}
        </Modal>
    );

    const userProfileModal = (
        <Modal isOpen={userProfileVisibility} hideModal={hideUserProfile} modalTitle={t("Routes.profile")}>
            <UserProfileView />
        </Modal>
    );

    const menuItems: JSX.Element[] = [
        <div key="0" className={style.versionInformation}>
            <strong>Blancco Management Portal</strong>
            <br />
            {t("Common.version")} {process.env.BMP_VERSION}
        </div>,
        <MenuItemButton
            key="1"
            tabIndex={0}
            onClick={hasTenantCookie() ? fetchData : showUserProfile}
            data-testid={testIds.navigation.userMenu.profileLink}
        >
            {t("Routes.profile")}
        </MenuItemButton>,
        <MenuItemButton
            key="2"
            tabIndex={0}
            onClick={showSettings}
            data-testid={testIds.navigation.userMenu.settingLink}
        >
            {t("Common.settings")}
        </MenuItemButton>,
        <>
            {useFeature(FLAG_CHANGE_PASSWORD) && !hasTenantCookie() ? (
                <MenuItemButton
                    key="3"
                    tabIndex={0}
                    onClick={showChangePasswordForm}
                    data-testid={testIds.navigation.userMenu.changePasswordLink}
                >
                    {t("Routes.changePassword")}
                </MenuItemButton>
            ) : (
                <></>
            )}
        </>,
        <>
            {isNewNavigationEnabled && (
                <ToggleablePanel
                    key={4}
                    title={t("Common.language")}
                    testId={testIds.navigation.userMenu.languageMenu.button}
                    menuType={"HEADER"}
                    chevronSize={{ width: 14, height: 14 }}
                    buttonClass={style.navButton}
                    customToggleIcon={
                        <Chevron color={theme.textColor} className={style.chevronRight} width={16} height={16} />
                    }
                    hideChevron
                >
                    <>
                        <LanguageMenu className={style.flyoutLanguageMenu} />
                    </>
                </ToggleablePanel>
            )}
        </>,
        <DropdownMenuDivider key="5" />,

        <MenuItemButton
            key="6"
            tag="a"
            target="_blank"
            tabIndex={0}
            href="https://status.blancco.com"
            data-testid={testIds.navigation.userMenu.systemStatusLink}
            onClick={() => {
                usageStatisticsService.sendEvent({
                    category: Category.HEADER,
                    action: Action.VISIT_SYSTEM_STATUS,
                });
            }}
        >
            {t("Routes.systemStatus")}
        </MenuItemButton>,
        <MenuItemButton
            key="7"
            tag="a"
            target="_blank"
            tabIndex={0}
            href="https://trust.blancco.com"
            data-testid={testIds.navigation.userMenu.trustCenterLink}
            onClick={() => {
                usageStatisticsService.sendEvent({
                    category: Category.HEADER,
                    action: Action.VISIT_TRUST_CENTER,
                });
            }}
        >
            {t("Routes.trustCenter")}
        </MenuItemButton>,
        <DropdownMenuDivider key="8" />,

        <MenuItemButton
            key="9"
            tag="a"
            tabIndex={0}
            href="/logout"
            data-testid={testIds.navigation.userMenu.logoutLink}
            onClick={() => {
                usageStatisticsService.sendEvent({
                    category: Category.HEADER,
                    action: Action.LOGOUT,
                });
            }}
        >
            {t("Routes.logout")}
        </MenuItemButton>,
    ];

    return (
        <>
            <DropdownMenu
                className={classNames(style.menu, props.className)}
                menuItems={menuItems}
                testId={testIds.navigation.userMenu.itself}
            />
            {hasTenantCookie() ? tenantProfileModal : userProfileModal}
            <Modal isOpen={settingsVisibility} hideModal={hideSettings} modalTitle={t("Common.settings")}>
                <SettingsView onCancel={hideSettings} />
            </Modal>
            <Modal
                isOpen={changePasswordFormVisibility}
                hideModal={hideChangePassword}
                modalTitle={t("Routes.changePassword")}
            >
                <CreateNewPasswordView
                    changePassword={true}
                    setChangePasswordFormVisibility={hideChangePassword}
                    errorModalVisible={setErrorModalVisible}
                />
            </Modal>
            {errorModalVisible && (
                <ErrorModal
                    hide={() => setErrorModalVisible(false)}
                    visible={errorModalVisible}
                    title={t("CreateNewPasswordView.attemptsExceededErrorTitle")}
                    description={t("CreateNewPasswordView.attemptsExceededErrorMessage")}
                />
            )}
        </>
    );
};
