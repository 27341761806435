import { SoftwarePackageDto, SoftwarePackagesData, SoftwarePackagesDataDto } from "domain/softwarePackages";
import { apiGatewayService } from "services/api/ApiGatewayService";
import { getObject, RepositoryKey } from "utils/repository";

/**
 * Handles interactions with get software packages list API.
 */

function toSoftwarePackagesData(dto: SoftwarePackagesDataDto): SoftwarePackagesData {
    const softwarePackagesData: SoftwarePackagesData = {
        productDownloads: [],
    };
    dto.productDownloads.map((each: SoftwarePackageDto) => {
        softwarePackagesData.productDownloads.push({
            productName: each.productName,
            licenseIds: each.licenseIds.map(String),
            versions: each.versions,
            bmpHomeGroup: each.bmpHomeGroup,
            bmpHomeCategory: each.bmpHomeCategory,
        });
    });
    return softwarePackagesData;
}

class SoftwarePackagesService {
    public async fetchSoftwarePackages(): Promise<SoftwarePackagesData> {
        const supportSoftwareUrl = "https://api.blancco.com/support/software";
        return apiGatewayService
            .fetch(getObject(RepositoryKey.SUPPORT_SOFTWARE_URL) || supportSoftwareUrl)
            .then((res) => res.json())
            .then((res) => toSoftwarePackagesData(res));
    }
}

export const softwarePackagesService = new SoftwarePackagesService();
