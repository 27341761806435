import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";

import ImportReportsDialog from "./ImportReportsDialog";
import { eventBus } from "components/event-bus/eventBus";
import Import from "components/icons/Import";
import { DialogState } from "components/import-file-dialog/ImportFileDialog";
import Modal from "components/modal/Modal";
import NotificationModal from "components/modal/NotificationModal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { licenseService } from "services/licenses/LicenseService";
import buttons from "styles/buttons.scss";
import { Logger } from "utils/logging";
import { useTheme } from "utils/useTheme";

const LOGGER = new Logger("ImportReportsView");

interface State {
    modalVisible: boolean;
    // This isn't ideal. Normally it would be better if the component handled
    // its own internal state but in order to provide a confirmation for
    // "close" and "x" button clicks, React forces us to lift this state out
    // of the component.
    dialogState: DialogState;
    wasValidated: boolean;
    uploadingStarted: boolean;
    confirming: boolean;
    files: File[];
}

function createDefaultState(): State {
    return {
        modalVisible: false,
        dialogState: DialogState.SELECTING_FILE,
        wasValidated: false,
        uploadingStarted: false,
        confirming: false,
        files: [],
    };
}

function createNotificationModal(t: TFunction, onHide: () => void, onRegret: () => void): JSX.Element {
    return (
        <NotificationModal
            hide={onHide}
            open={true}
            title={t("ImportReportsDialog.title")}
            message={t("ImportReportsDialog.closeConfirmation")}
            goBack={onRegret}
        />
    );
}

const ImportReportsView = (): JSX.Element => {
    const { t } = useTranslation();
    const [state, setState] = React.useState(createDefaultState());
    const theme = useTheme();

    React.useEffect(() => {
        const subscriber = {
            handler: ({ files }: { files: File[] }) => {
                if (LOGGER.isDebugEnabled()) {
                    const description = JSON.stringify(files.map((each) => ({ name: each.name, size: each.size })));
                    LOGGER.debug("Received report-import-file event via event bus", description);
                }
                setState((previous) => ({ ...previous, files, modalVisible: true }));
                return Promise.resolve();
            },
            id: "ImportReportsView",
        };
        const unsubscribe = eventBus.getReportImportFileTopic().subscribe(subscriber);
        return () => unsubscribe();
    }, []);

    function reset() {
        setState(createDefaultState());
    }

    function hideModalConditionally() {
        if (
            state.uploadingStarted &&
            state.dialogState !== DialogState.IMPORTING_REPORTS &&
            state.dialogState !== DialogState.IMPORTING_SUCCEEDED
        ) {
            setState((previous) => ({ ...previous, confirming: true }));
        } else {
            reset();
        }
    }

    function updateState(state: DialogState) {
        if (state === DialogState.UPLOADING_REPORTS) {
            setState((previous) => ({ ...previous, uploadingStarted: true }));
        } else if (state === DialogState.VALIDATED_REPORTS) {
            setState((previous) => ({ ...previous, wasValidated: true }));
        }
        setState((previous) => ({
            ...previous,
            dialogState: state,
        }));
    }

    function closeConfirmationDialog(regret: boolean) {
        if (regret) {
            setState((previous) =>
                Object.assign(
                    {},
                    { ...previous, confirming: false },
                    state.wasValidated ? { dialogState: DialogState.VALIDATED_REPORTS } : {}
                )
            );
        } else {
            reset();
        }
    }

    const confirmation = state.confirming
        ? createNotificationModal(
              t,
              () => closeConfirmationDialog(false),
              () => closeConfirmationDialog(true)
          )
        : null;
    return (
        <>
            <TextWithTooltip text={t("ImportReportsDialog.title")}>
                <button
                    className={buttons.secondaryButton}
                    onClick={() => setState((previous) => ({ ...previous, modalVisible: true }))}
                >
                    <Import color={theme.linkTextColor} />
                    {t("ImportReportsDialog.title")}
                </button>
            </TextWithTooltip>
            <Modal
                isOpen={state.modalVisible}
                hideModal={hideModalConditionally}
                modalTitle={t("ImportReportsDialog.title")}
            >
                <ImportReportsDialog
                    onClose={hideModalConditionally}
                    dialogState={state.dialogState}
                    fetchAllLicenses={licenseService.fetchAllLicenses}
                    setDialogState={updateState}
                    fileList={state.files}
                />
            </Modal>
            {confirmation}
        </>
    );
};

export default ImportReportsView;
