import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import CircleNumber from "components/home/CircleWithNumber";
import { Step } from "components/home/onboarding-run-erasure/RunErasure";
import StepCompletedText from "components/home/StepCompletedText";
import StepHeader from "components/home/StepHeader";
import Info from "components/icons/Info";
import Tooltip from "components/tooltip/Tooltip";
import { BmdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepIndex: number;
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    stepClickable: boolean;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    hasIbr: boolean;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

const InstallBmde = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [showInstallBmde, setShowInstallBmde] = React.useState(props.menuExpanded && props.stepClickable);
    const dataType = OnboardingProductType.BMDE + "_" + BmdeOnboardingSteps.STEP_INSTALLING_BMDE;
    const expandInstallBmdeView = () => {
        props.stepClickable && setShowInstallBmde(!showInstallBmde);
    };

    React.useEffect(() => {
        if (props.stepInProgressIndex !== props.stepIndex) {
            setShowInstallBmde(false);
        }
    }, [props.stepInProgressIndex]);

    const installBmdeSteps: Step[] = [
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.plugInBootableUSB"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.turnOnOrReboot"),
            tooltip: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.turnOnOrRebootTooltip"),
            secondDescription: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.chooseUsbStick"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickPermanent"),
            tooltip: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickPermanentTooltip"),
            secondDescription: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.acceptEula"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickAutomaticPartitioning"),
            tooltip: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickAutomaticPartitioningTooltip"),
            secondDescription: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.removeUsb"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setAdminPassword"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.connect"),
            info: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.address"),
            secondDescription: props.hasIbr
                ? t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.synchronize")
                : undefined,
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setupWifiConfiguration"),
        },
    ];

    React.useEffect(() => {
        if (props.menuExpanded && props.stepClickable) {
            setShowInstallBmde(true);
        }
    }, [props.menuExpanded]);

    function createContent() {
        return showInstallBmde ? (
            <>
                <div className={style.stepsContainer}>
                    <div className={style.alertBox}>
                        <div>
                            <Info
                                borderColor={theme.contentBackgroundColor}
                                color={theme.sortIconActiveColor}
                                size={"16"}
                            />
                        </div>
                        {t("Onboarding.bmde.gettingStarted.steps.installBMDE.steps.prepareYourPC")}
                    </div>
                    {installBmdeSteps.map((step, index) => (
                        <div key={index} className={style.subStepsToFollowLayout}>
                            <CircleNumber number={index + 1} subStep={true} />
                            <div className={style.stepPadding}>
                                <div>
                                    {step.description}
                                    {step.tooltip && (
                                        <Tooltip content={step.tooltip} placement={"top"}>
                                            <span className={style.tooltipSpacing}>
                                                <Info
                                                    borderColor={theme.contentBackgroundColor}
                                                    color={theme.sortIconActiveColor}
                                                    size={"14"}
                                                />
                                            </span>
                                        </Tooltip>
                                    )}
                                    {step.info && (
                                        <div className={classNames(style.stepActionButton, style.subStepPadding)}>
                                            {step.info}
                                        </div>
                                    )}
                                    {step.secondDescription}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={style.markComplete}>
                    {props.stepCompleted ? (
                        <StepCompletedText
                            successText={t("Onboarding.bmde.gettingStarted.steps.installBMDE.completed")}
                            testId={testIds.workArea.onboarding.step.statusLabel}
                            dataType={dataType}
                        />
                    ) : (
                        <Button
                            variant={"PRIMARY"}
                            loadingAnimation={props.stepInProgressIndex === props.stepIndex}
                            disabled={props.stepInProgressIndex === props.stepIndex}
                            className={classNames(buttons.primaryButtonWithoutIcon)}
                            onClick={() => {
                                props.saveStepInProgressIndex(props.stepIndex);
                                props.saveOnboardingStep({
                                    product: OnboardingProductType.BMDE,
                                    step: BmdeOnboardingSteps.STEP_INSTALLING_BMDE,
                                });
                            }}
                            testId={testIds.workArea.onboarding.step.primaryButton}
                            data-type={dataType}
                        >
                            {props.stepInProgressIndex === props.stepIndex
                                ? t("Onboarding.saving")
                                : t("Onboarding.bmde.gettingStarted.steps.installBMDE.markComplete")}
                        </Button>
                    )}
                </div>
            </>
        ) : null;
    }
    return (
        <StepHeader
            title={t("Onboarding.bmde.gettingStarted.steps.installBMDE.title")}
            stepIndex={props.stepIndex}
            success={props.stepCompleted}
            showStep={showInstallBmde}
            inactive={!props.stepClickable}
            clickEvent={expandInstallBmdeView}
            showContent={showInstallBmde}
            menuExpanded={props.menuExpanded}
            content={createContent()}
            type={OnboardingProductType.BMDE}
            stepName={BmdeOnboardingSteps.STEP_INSTALLING_BMDE}
        />
    );
};

export default connector(InstallBmde);
