import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./support.scss";
import bmpSetupStyle from "components/home/bmp-setup.scss";
import { ContactIcon } from "components/home/home-icons/ContactIcon";
import { KnowledgeBaseIcon } from "components/home/home-icons/KnowledgeBaseIcon";
import { SmbBundle } from "components/licenses/bundles";
import { CombinedTier } from "domain/tenants";
import { useTheme } from "utils/useTheme";

const Support = (props: { tenantTier: CombinedTier }): JSX.Element => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <div className={style.support}>
            <div>
                <div className={style.supportLabel}>{t("Onboarding.home.support")}</div>
                <div className={style.needHelp}>{t("Onboarding.home.needHelp")}</div>
            </div>
            <div className={classNames(style.item, bmpSetupStyle.linkText)}>
                <KnowledgeBaseIcon color={theme.homeIconsColor} />
                <button onClick={() => window.open("https://support.blancco.com/space/KB", "_blank")}>
                    <strong>{t("Onboarding.home.knowledgeBase")}</strong>
                </button>
            </div>
            {Object.keys(SmbBundle).includes(props.tenantTier) ? null : (
                <>
                    <div className={style.spacer}></div>
                    <div className={classNames(style.item, bmpSetupStyle.linkText)}>
                        <ContactIcon color={theme.homeIconsColor} />
                        <button
                            onClick={() =>
                                window.open(
                                    "https://portal.servicedesk.blancco.com/servicedesk/customer/user/login?destination=portals",
                                    "_blank"
                                )
                            }
                        >
                            <strong>{t("Onboarding.home.contactSupport")}</strong>
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Support;
