import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./notification.scss";
import ErrorMessage from "components/error-message/ErrorMessage";
import Bell from "components/icons/Bell";
import NotificationBlock from "components/notifications/NotificationBlock";
import Heading from "components/typography/heading/Heading";
import { AUTH_REPORT_DELETE, AUTH_WORKFLOW_VIEW } from "domain/authority";
import { Notification } from "domain/notification";
import { FLAG_DELETE_REPORT } from "services/feature/FeatureFlagService";
import { notificationService } from "services/notification/NotificationService";
import { ReportDeletionNotificationData } from "services/report/ReportDeletionService";
import { ImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { tenantHasFeatureLicense } from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ErrorMessageBlock = () => (
    <div className={style.menuItem}>
        <ErrorMessage />
    </div>
);

// Return notifications that should actually be visible to the user. In most cases, this should be a precaution.
// Notifications should be generally created for relevant tenants/users only.
const toUserVisibleNotifications = (notifications: Notification[]) => {
    return notifications.filter((notification) => {
        if (
            notification.type === "REPORT_DELETION" &&
            (!userSessionService.userHasAllAuthorities([AUTH_REPORT_DELETE]) || !useFeature(FLAG_DELETE_REPORT))
        ) {
            return false;
        }
        if (notification.type === "FEATURE_UPDATE" && !userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_VIEW])) {
            return false;
        }
        return !(
            notification.type === "WORKFLOW_EDITOR_UPDATE" &&
            (!userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_VIEW]) ||
                !(
                    tenantHasFeatureLicense("FEATURE_WORKFLOW_BDE") ||
                    tenantHasFeatureLicense("FEATURE_WORKFLOW_BMDE") ||
                    tenantHasFeatureLicense("FEATURE_WORKFLOW_LUN")
                ))
        );
    });
};

const NotificationMenu = (
    props: {
        notifications: Notification[];
        onDelete: (notifications: Notification[]) => void;
        showImportReportDialog: (jobData: ImportNotificationData) => void;
        showExportReport: (jobId: string) => void;
        showDeleteReportDialog: (jobData: ReportDeletionNotificationData) => void;
        showLicenseAlertDialog?: (uuid: string) => void;
        className?: string;
    } & ConnectedProps<typeof connector>
): JSX.Element => {
    const { t } = useTranslation();
    const notifications = toUserVisibleNotifications(props.notifications);

    const deleteNotification = (notificationUuid: string) => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.REMOVE_NOTIFICATION,
        });
        notificationService.deleteNotification(notificationUuid).then(() => {
            const notification = notifications?.find((notification) => notification.uuid === notificationUuid);
            if (notification == null) {
                return;
            }
            const index = notifications?.indexOf(notification);
            if (index != undefined && index > -1) {
                props.onDelete(notifications.filter((_each, key) => key !== index));
            }
        });
    };

    function deriveNotification(notification: Notification, index: number) {
        return (
            <ErrorBoundary FallbackComponent={ErrorMessageBlock}>
                <NotificationBlock
                    notification={notification}
                    key={index}
                    onDelete={deleteNotification}
                    showImportReportDialog={props.showImportReportDialog}
                    showExportReport={props.showExportReport}
                    showDeleteReport={props.showDeleteReportDialog}
                    showLicenseAlertDialog={props.showLicenseAlertDialog}
                />
            </ErrorBoundary>
        );
    }

    return (
        <div
            className={classNames(style.menu, props.className)}
            data-testid={testIds.navigation.notificationMenu.itself}
        >
            {notifications.length < 1 ? (
                <div className={style.notificationEmptyState}>
                    <Bell fillColor={props.theme.textColor} size={36} />
                    <div className={style.notificationEmptyStateContent}>
                        <Heading tag="div" variant="SUBTITLE_2">
                            {t("Notification.title.noNotifications.title")}
                        </Heading>
                        <div className={style.message}>{t("Notification.title.noNotifications.message")}</div>
                    </div>
                </div>
            ) : (
                <div>{notifications?.map((each, index) => deriveNotification(each, index))}</div>
            )}
        </div>
    );
};

export default connector(NotificationMenu);
