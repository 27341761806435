import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import style from "./mcs.scss";
import { createLicenseTypes } from "components/licenses/common";
import StaticTable from "components/support/api-guide/StaticTable";
import Heading from "components/typography/heading/Heading";
import { McsTableData } from "domain/mc";
import buttons from "styles/buttons.scss";
import { formatDateWithoutTime } from "utils/format";

interface Props {
    mcsTableData: McsTableData;
    onClick: () => void;
}

const McSyncInformationView: React.FC<Props> = ({ mcsTableData, onClick }) => {
    const { t } = useTranslation();
    const blanccoProducts = createLicenseTypes(true);
    return (
        <div>
            <div className={style.syncDataSection}>
                <div className={style.syncInfo}>
                    <div className={style.label}>{t("Mcs.onPremBmpInfoModal.name")}</div>
                    <div className={style.breakpointXxl}>{mcsTableData.name ?? t("Mcs.onPremBmpInfoModal.noData")}</div>
                </div>
            </div>
            <Heading tag={"div"} variant="SUBTITLE_2" underline={false}>
                {t("Mcs.onPremBmpInfoModal.usageTitle")}
            </Heading>
            <div className={style.syncDataSection}>
                <div className={style.syncInfo}>
                    <div className={style.label}>{t("Mcs.onPremBmpInfoModal.reportCount")}</div>
                    <div>{mcsTableData.reportCount ?? t("Common.na")}</div>
                </div>

                <div className={style.syncInfo}>
                    <div className={style.label}>{t("Mcs.onPremBmpInfoModal.workflowCount")}</div>
                    <div>{mcsTableData.workflowCount ?? t("Common.na")}</div>
                </div>

                <div className={style.syncInfo}>
                    <div className={style.label}>{t("Mcs.onPremBmpInfoModal.userCount")}</div>
                    <div>{mcsTableData.userCount ?? t("Common.na")}</div>
                </div>

                <div className={style.syncInfo}>
                    <div className={style.label}>{t("AddEntitlementForm.containerId")}</div>
                    <div>{mcsTableData.containerId ?? t("Common.na")}</div>
                </div>
            </div>

            <Heading tag="div" variant="SUBTITLE_2" underline={false}>
                {t("Mcs.onPremBmpInfoModal.licenseTitle")}
            </Heading>
            <div className={style.syncDataSection}>
                {mcsTableData.licenses && Object.entries(mcsTableData.licenses).length > 0 ? (
                    <div className={style.tableContainer}>
                        <StaticTable
                            tableClass={style.table}
                            headers={[
                                {
                                    value: t("DeliveryHistory.deliveryHistoryDialog.table.licenseType"),
                                },
                                {
                                    value: t("LicensePools.view.remainingLicense"),
                                },
                                {
                                    value: t("LicensePools.view.licenses.table.expirationDate"),
                                },
                            ]}
                            cells={Object.entries(mcsTableData.licenses).map(([licenseId, license]) => {
                                const productName =
                                    blanccoProducts.find((type) => type.productId === licenseId)?.productName ||
                                    licenseId;

                                return [
                                    <div key={`name-${licenseId}`} className={style.label}>
                                        {productName}
                                    </div>,
                                    <div key={`remaining-${licenseId}`} className={style.label}>
                                        {license.remaining}
                                    </div>,
                                    <div key={`expiration-${licenseId}`} className={style.label}>
                                        {license.expiration && formatDateWithoutTime(license.expiration)}
                                    </div>,
                                ];
                            })}
                        />
                    </div>
                ) : (
                    <div>{t("Common.na")}</div>
                )}
            </div>
            <div className={classNames(style.syncDataOkButton, style.okButtonContainer)}>
                <button className={buttons.primaryButtonWithoutIcon} onClick={onClick}>
                    {t("Common.ok")}
                </button>
            </div>
        </div>
    );
};

export default McSyncInformationView;
