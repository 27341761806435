import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import StepCompletedText from "components/home/StepCompletedText";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import TemplateViewImage from "components/icons/TemplateViewImage";
import layoutStyle from "components/layout/layout.scss";
import Modal from "components/modal/Modal";
import {
    ActiveWorkflowDialog,
    ConflictsResponse,
    TemplateDetailsDialog,
    toConflictsResponse,
} from "components/workflows/workflow-templates/PublishedWorkflowTemplate";
import WorkflowTemplateDetailsDialog from "components/workflows/workflow-templates/WorkflowTemplateDetailsDialog";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { BdeOnboardingSteps, BmdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { TemplateTableData } from "domain/workflowTemplates";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { templateService, WorkflowTemplateSaveResponse } from "services/workflows/TemplateService";
import { ManifestWorkflowEditor, Profile, workflowService } from "services/workflows/WorkflowService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepCompleted?: boolean;
    showWorflowTemplateView: (value: boolean) => void;
    setHideSecondStep?: (value: boolean) => void;
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    product: OnboardingProductType;
    stepName: string;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

const WorkflowTemplateSubStep = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const abortController = new AbortController();
    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const completeStep = () => {
        saveOnboardingStep();
    };
    const [templateDetailsDialog, setTemplateDetailsDialog] = React.useState<TemplateDetailsDialog>({
        show: false,
    });
    const theme = useTheme();
    const [addedBadge, setAddedBadge] = React.useState<boolean>(false);
    const [updatePriority, setUpdatePriority] = React.useState<boolean>(true);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [conflictsException, setConflictsException] = React.useState<ConflictsResponse | undefined>(undefined);
    const [showActiveWorkflowDialog, setShowActiveWorkflowDialog] = React.useState<ActiveWorkflowDialog>({
        show: false,
        workflowUuid: "",
    });
    const [activeWorkflowLoading, setActiveWorkflowLoading] = React.useState<boolean>(false);
    const [workflowEditors, setWorkflowEditors] = React.useState<ManifestWorkflowEditor[]>([]);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const saveOnboardingStep = () => {
        props.saveOnboardingStep({
            product: props.product,
            step:
                props.product === OnboardingProductType.BMDE
                    ? BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE
                    : BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE,
            workflowTemplateUuid: props.defaultWorkflowTemplate.uuid,
        });
    };

    React.useEffect(() => {
        if (props.defaultWorkflowTemplate.name === "") {
            setAndShowErrorModal();
        }

        const abortController = new AbortController();
        abortControllers.push(abortController);
        workflowService.fetchWorkflowEditors(Profile.ALL, abortController).then((workflowEditors) => {
            setWorkflowEditors(workflowEditors);
        });
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const addTemplateToTenantsWorkflows = (fromPreview: boolean) => {
        setLoading(true);
        templateService
            .save(props.defaultWorkflowTemplate.uuid)
            .then((response: WorkflowTemplateSaveResponse) => {
                setRequestFailureMessage("");
                setAddedBadge(true);
                setUpdatePriority(false);
                markWorkflowAsActive(response.workflowUuid);
            })
            .catch((exception) => {
                try {
                    if (!abortController.signal.aborted) {
                        if (fromPreview) {
                            setConflictsException(toConflictsResponse(JSON.parse(exception.message)));
                            setRequestFailureMessage(t("workflowTemplate.workflowTemplateDetailsDialog.error.message"));
                            setAddedBadge(false);
                            setUpdatePriority(true);
                            setShowActiveWorkflowDialog({
                                show: false,
                                workflowUuid: "",
                            });
                        } else {
                            fetchCorrespondingWorkflow();
                            setRequestFailureMessage("");
                        }
                    }
                } catch (error) {
                    setConflictsException(undefined);
                    setAddedBadge(false);
                    setUpdatePriority(true);
                    setShowError(true);
                }
            });
    };

    const hideErrorModal = () => {
        setShowError(false);
    };

    const showSuccessNotification = (active: boolean) => {
        let message = t("workflowTemplate.workflowTemplateDetailsDialog.successToast.success", {
            templateName: templateDetailsDialog.details?.name,
        });
        if (active) {
            message += " " + t("workflowTemplate.workflowTemplateDetailsDialog.successToast.active");
        }
        return toast(
            <SuccessIcon
                successClass={layoutStyle.customToastSuccessIcon}
                color={theme.contentBackgroundColor}
                text={message}
            />,
            {
                closeButton: (closeToastProps) => (
                    <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                ),
                className: layoutStyle.customToastSuccessMessage,
            }
        );
    };

    const markWorkflowAsActive = (workflowUuid: string) => {
        workflowService
            .setDefault(workflowUuid, abortController)
            .then(() => {
                setLoading(false);
                completeStep();
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setShowError(true);
                }
                setShowError(true);
            });
        setActiveWorkflowLoading(false);
    };

    const fetchCorrespondingWorkflow = () => {
        workflowService
            .fetchWorkflows(props.defaultWorkflowTemplate.name, "", "", "", "", "", abortController)
            .then((data) => {
                markWorkflowAsActive(data.workflowTableData[0].uuid);
            })
            .catch(() => {
                setShowError(true);
            });
    };

    const setAndShowErrorModal = () => {
        setRequestFailureMessage(t("workflowTemplatesManager.template.requestFailed"));
        setShowError(true);
    };

    return (
        <>
            <div className={style.inProgressInfoBox}>
                <div className={style.inProgressDescription}>
                    {t("Onboarding.gettingStartedFlow.workflowStep.description1")}
                </div>
                <div className={style.inProgressDescription}>
                    {t("Onboarding.gettingStartedFlow.workflowStep.description2")}
                </div>
            </div>
            <div className={style.previewTemplateInfoText}>
                {t("Onboarding.gettingStartedFlow.workflowStep.previewTemplateInfoText")}
                <div className={style.previewTemplateBox}>
                    <TemplateViewImage />
                    <div className={style.previewTemplateBlock}>
                        <div className={style.previewTemplateHeading}>
                            {props.defaultWorkflowTemplate.name === ""
                                ? props.product
                                : props.defaultWorkflowTemplate.name}
                        </div>
                        <div>
                            {props.product === OnboardingProductType.BDE
                                ? t("Onboarding.gettingStartedFlow.workflowStep.templateDescription")
                                : t("Onboarding.bmde.gettingStarted.steps.chooseWorkflow.templateDescription")}
                        </div>
                        <div>
                            <button
                                className={style.previewTemplateButton}
                                onClick={() => {
                                    setTemplateDetailsDialog({
                                        show: true,
                                        details: props.defaultWorkflowTemplate,
                                    });
                                    setRequestFailureMessage("");
                                }}
                            >
                                {t("Onboarding.gettingStartedFlow.workflowStep.previewTemplate.button")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.templateSelectButtonLayout}>
                {props.stepCompleted ? (
                    <StepCompletedText
                        successText={t(
                            "Onboarding.gettingStartedFlow.workflowStep.previewTemplate.defaultTemplateSelected"
                        )}
                        testId={testIds.workArea.onboarding.step.statusLabel}
                        dataType={props.product + "_" + props.stepName}
                    />
                ) : (
                    <Button
                        variant={"PRIMARY"}
                        className={classNames(style.templateSelectButton)}
                        onClick={() => {
                            props.saveStepInProgressIndex(0);
                            addTemplateToTenantsWorkflows(false);
                        }}
                        testId={testIds.workArea.onboarding.step.primaryButton}
                        data-type={props.product + "_" + props.stepName}
                        loadingAnimation={props.stepInProgressIndex === 0}
                        disabled={props.stepInProgressIndex === 0}
                    >
                        {props.stepInProgressIndex === 0
                            ? t("Onboarding.saving")
                            : t("Onboarding.gettingStartedFlow.workflowStep.defaultTemplateSelectButton")}
                    </Button>
                )}
            </div>
            <Modal
                isOpen={showError}
                hideModal={hideErrorModal}
                modalTitle={
                    requestFailureMessage ? requestFailureMessage : t("Onboarding.bmde.errorMessages.errorOccurred")
                }
            >
                <>
                    <div className={style.errorMessageContainer}>
                        {t("Onboarding.bmde.errorMessages.fetchingTemplatesFailed", {
                            email: SUPPORT_EMAIL,
                        })}
                    </div>
                    <div className={style.errorButtonContainer}>
                        <button
                            className={classNames(
                                buttons.primaryButton,
                                buttons.medium,
                                buttons.okButton,
                                buttons.buttonWithoutIcon
                            )}
                            onClick={() => {
                                setShowError(false);
                            }}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </>
            </Modal>
            <WorkflowTemplateDetailsDialog
                showTemplateDetailsDialog={templateDetailsDialog}
                setShowTemplateDetailsDialog={setTemplateDetailsDialog}
                setAddedBadge={setAddedBadge}
                requestFailureMessage={requestFailureMessage}
                loading={loading}
                conflictsException={conflictsException}
                addedBadge={addedBadge}
                saveTemplate={() => {
                    addTemplateToTenantsWorkflows(true);
                }}
                showActiveWorkflowDialog={showActiveWorkflowDialog}
                setShowActiveWorkflowDialog={setShowActiveWorkflowDialog}
                setSavedWorkflowActive={() => {
                    markWorkflowAsActive(showActiveWorkflowDialog.workflowUuid);
                }}
                showSuccessNotificationToast={showSuccessNotification}
                activeWorkflowLoading={activeWorkflowLoading}
                workflowEditors={workflowEditors}
                previewOnly={true}
                updatePriority={updatePriority}
            />
        </>
    );
};

export default connector(WorkflowTemplateSubStep);
