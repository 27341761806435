import classNames from "classnames";
import moment from "moment/moment";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { DatePicker } from "rsuite";

import { CalendarIcon } from "components/icons/CalendarIcon";
import style from "components/licenses/delivery-history/add-license-delivery.scss";
import { StoreState } from "store";
import form from "styles/form.scss";
import {
    DATE_FORMAT,
    formatDatePickerValue,
    formatFormDate,
    formatFormDateWithoutTime,
    generateTodayDate,
    parseDate,
} from "utils/format";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface Props {
    amount: number | null;
    expirationDate: string;
    onDateChange: (newDate: string) => void;
    dateString: string;
    onAmountChange: (newAmount: string) => void;
    showAmountType: boolean;
}

const GlobalExpirationDateAndAmount = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div className={classNames(form.formFields)}>
            {props.showAmountType && (
                <label className={classNames(style.globalLabel)}>
                    {t("DeliveryHistory.addLicenseDelivery.amounts")}
                    <input
                        className={classNames(form.input, style.inputMargin, style.fixedWidth)}
                        type={"number"}
                        value={props.amount ?? ""}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => props.onAmountChange(event.target.value)}
                        data-testid={
                            testIds.workArea.license.deliveryHistory.createDeliveryDialog.globalAssignedAmountInput
                                .itself
                        }
                    />
                </label>
            )}
            <label className={classNames(style.globalLabel)}>
                {t("DeliveryHistory.addLicenseDelivery.expirationDates")}
                <DatePicker
                    format={DATE_FORMAT}
                    cleanable={false}
                    caretAs={(colorProps) => <CalendarIcon {...colorProps} color={props.theme.activeBackgroundColor} />}
                    id={"expirationDate"}
                    className={classNames(form.dateRange, style.inputMargin, style.inputWidth, style.customDateInput)}
                    onSelect={(date: Date) => {
                        const value = formatFormDate(date.toString());
                        props.onDateChange(formatDatePickerValue(value));
                    }}
                    disabledDate={(date: Date) => {
                        const normalizedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                        const lastDate = formatDatePickerValue(props.dateString);
                        return normalizedDate > moment(lastDate).toDate();
                    }}
                    value={parseDate(
                        formatFormDateWithoutTime(props.expirationDate ? props.expirationDate : generateTodayDate())
                    )}
                    data-testid={
                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.globalExpirationDateInput.itself
                    }
                    renderValue={(value: Date) => {
                        return moment(value).format("DD/MM/YYYY");
                    }}
                />
            </label>
        </div>
    );
};
export default connector(GlobalExpirationDateAndAmount);
