// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3i9V6NOf4HUxl45JgPUSgY{width:500px;display:flex;flex-direction:column;align-items:center;gap:32px}.usEW0vcE_yJaV477IH-jV{display:flex;flex-direction:column;align-items:center;gap:8px}.usEW0vcE_yJaV477IH-jV h2{font-weight:600}._1v_c-QnCru-qvf7bkm5DXG{display:flex;gap:16px}", ""]);
// Exports
exports.locals = {
	"breakpointSm": "580px",
	"breakpointMd": "768px",
	"breakpointLg": "992px",
	"breakpointXl": "1200px",
	"breakpointXxl": "1600px",
	"modalContainer": "_3i9V6NOf4HUxl45JgPUSgY",
	"modalContentContainer": "usEW0vcE_yJaV477IH-jV",
	"modalButtons": "_1v_c-QnCru-qvf7bkm5DXG"
};
module.exports = exports;
