import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./multiselect-dropdown.scss";
import { useOutsideClick } from "components/header/MenuPanel";
import Checkmark from "components/icons/Checkmark";
import { FilterState } from "services/workflows/TemplateService";
import { StoreState } from "store";
import { applyMode } from "store/theme";
import formStyle from "styles/form.scss";
import form from "styles/form.scss";

interface SelectWithCheckboxProps {
    selectName: string;
    selectType: string;
    filterState: FilterState;
    setFilterState: React.Dispatch<React.SetStateAction<FilterState>>;
    dropDownList: string[];
    testId: string;
}

const ICON_SIZE = 12;
const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState, { applyMode });
const MultiselectDropdown = (props: ConnectedProps<typeof connector> & SelectWithCheckboxProps): JSX.Element => {
    const { t } = useTranslation();
    const list = props.dropDownList;
    const [filterVisible, SetFilterVisible] = React.useState(false);
    const [selectAll, SetSelectAll] = React.useState(true);

    const ref = useOutsideClick(() => {
        SetFilterVisible(false);
    });
    function showCheckmarkIcon() {
        return (
            <div className={style.headerCheckbox}>
                <Checkmark color={props.theme.iconFillColor} size={ICON_SIZE} strokeWidth={1} />
            </div>
        );
    }
    const handleChange = (list: string[]) => {
        SetSelectAll(list.length > 0);
        handleFilterChanges({ list: list });
    };

    const handleFilterChanges = (update: Partial<FilterState>) => {
        props.setFilterState((prevState) => ({
            ...prevState,
            ...update,
        }));
    };

    const handleClick = (list: string, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            handleChange(props.filterState.list.concat(list));
        } else {
            handleChange(props.filterState.list.filter((each) => each !== list));
        }
    };

    const multiselectHeader = () => {
        if (props.filterState.list.length === list.length) {
            return t("Common.multiSelect.all", { type: props.selectType });
        } else if (props.filterState.list.length <= 0 && !selectAll) {
            return t("Common.multiSelect.all", { type: props.selectType });
        } else if (props.filterState.list.length === 1) {
            return props.filterState.list[0];
        } else {
            if (props.filterState.list.length === 0) {
                return t("Common.multiSelect.all", { type: props.selectType });
            } else {
                return t("Common.multiSelect.amount", {
                    amount: props.filterState.list.length,
                    type: props.selectType,
                });
            }
        }
    };

    return (
        <div ref={ref}>
            <div
                className={classNames(formStyle.select, style.selectSearch, style.ellipsis, style.commonHeight)}
                onClick={() => {
                    SetFilterVisible(!filterVisible);
                }}
                data-testid={props.testId}
            >
                {multiselectHeader()}
            </div>
            <ul hidden={!filterVisible} className={style.filters}>
                <li
                    onClick={() => {
                        props.filterState.list.length === list.length ? handleChange([]) : handleChange(list);
                    }}
                >
                    {t("Common.multiSelect.selectAll")}
                    {props.filterState.list.length === list.length && showCheckmarkIcon()}
                </li>

                {list.map((item: string) => {
                    return (
                        <li key={item}>
                            <label className={style.filteringItem}>
                                <input
                                    className={form.input}
                                    name={props.selectName}
                                    id={item}
                                    value={item}
                                    type="checkbox"
                                    onChange={(e) => handleClick(item, e)}
                                    checked={props.filterState.list.includes(item)}
                                />
                                <span className={style.labelSpacing}>{item} </span>
                            </label>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default connector(MultiselectDropdown);
