import * as React from "react";

const EncryptedExportIcon = (props: { color?: string; width?: string; height?: string }): JSX.Element => {
    return (
        <svg
            width={props.width ? props.width : "35.818"}
            height={props.height ? props.height : "48.818"}
            viewBox="0 0 48.818 48.818"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_2942_10416)">
                <rect x="3" y="1" width="40" height="40" rx="20" fill={props.color} />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.0571 14.1855C22.4595 14.1855 22.7857 14.5117 22.7857 14.9141V23.1713L25.5057 21.1313C25.8276 20.8898 26.2843 20.9551 26.5257 21.277C26.7671 21.5989 26.7019 22.0555 26.38 22.297L22.4943 25.2113C22.2352 25.4055 21.879 25.4055 21.62 25.2113L17.7343 22.297C17.4124 22.0555 17.3471 21.5989 17.5886 21.277C17.83 20.9551 18.2867 20.8898 18.6086 21.1313L21.3286 23.1713V14.9141C21.3286 14.5117 21.6548 14.1855 22.0571 14.1855ZM15.2286 23.8998C15.631 23.8998 15.9571 24.226 15.9571 24.6284V27.7855H30.0429V24.6284C30.0429 24.226 30.3691 23.8998 30.7714 23.8998C31.1738 23.8998 31.5 24.226 31.5 24.6284V28.5141C31.5 28.9165 31.1738 29.2427 30.7714 29.2427H15.2286C14.8262 29.2427 14.5 28.9165 14.5 28.5141V24.6284C14.5 24.226 14.8262 23.8998 15.2286 23.8998Z"
                    fill="#fff"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M30.4279 13.6667C30.6265 13.6667 30.8251 13.7325 30.9574 13.8642C31.0898 13.9959 31.2222 14.1934 31.2222 14.3909V18.2757C31.2222 18.4733 31.156 18.6708 30.9574 18.8025C30.8251 18.9342 30.6265 19 30.4279 19H25.7943C25.5957 19 25.3972 18.9342 25.2648 18.8025C25.0662 18.6708 25 18.4733 25 18.2757V14.3909C25 14.1934 25.0662 13.9959 25.2648 13.8642C25.3972 13.7325 25.5957 13.6667 25.7943 13.6667H26.1915H26.3471C26.861 13.6667 26.7843 13.6667 26.7843 13.6667H27.9196H28.8478C28.8478 13.6667 28.9054 13.6667 29.8834 13.6667H30.1631H30.4279ZM27.5816 16.893C27.7467 17.0388 27.9125 17.0905 28.1111 17.0905C28.3097 17.0905 28.6502 16.9923 28.8054 16.7301C28.8932 16.5817 28.9107 16.4726 28.9054 16.3004C28.8977 16.0463 28.8323 15.8733 28.6407 15.7051C28.4753 15.56 28.3316 15.5089 28.1111 15.5103C27.8587 15.5119 27.6903 15.587 27.5216 15.7737C27.3733 15.9377 27.3168 16.1029 27.3168 16.3004C27.3168 16.5638 27.3912 16.7249 27.5816 16.893Z"
                    fill="#fff"
                />
                <path
                    d="M26.7773 13.6667V12.4815C26.7773 12.0886 26.9178 11.7117 27.1679 11.4339C27.4179 11.1561 27.7571 11 28.1107 11C28.4643 11 28.8034 11.1561 29.0535 11.4339C29.3035 11.7117 29.444 12.0886 29.444 12.4815V13.6667"
                    stroke="#fff"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_2942_10416"
                    x="0"
                    y="0"
                    width="47.318"
                    height="47.318"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2942_10416" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2942_10416" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default EncryptedExportIcon;
