import * as React from "react";

interface Props {
    color?: string;
}

export const KnowledgeBaseIcon = React.forwardRef<SVGSVGElement, Props>(
    (props): JSX.Element => (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 10.5614V16.0351M21 10.5614L12 6L3 10.5614L12 15.1228L21 10.5614ZM6.6 12.386V16.9474C9.3 19.6842 14.7 19.6842 17.4 16.9474V12.386"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
);
