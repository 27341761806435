import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import WorkflowTemplateSubStep from "components/home/bde-home-page/WorkflowTemplateSubStep";
import StepHeader from "components/home/StepHeader";
import { BdeOnboardingSteps, BmdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { TemplateTableData } from "domain/workflowTemplates";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepIndex: number;
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    product: OnboardingProductType;
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    setHideSecondStep?: (value: boolean) => void;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

const ChooseWorkflowTemplate = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [showWorkflow, setShowWorkflow] = React.useState(false);
    const stepName =
        props.product == OnboardingProductType.BDE
            ? BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE
            : BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE;
    const openWorkflowTemplateView = () => {
        setShowWorkflow(!showWorkflow);
    };

    React.useEffect(() => {
        if (props.stepInProgressIndex !== 0) {
            setShowWorkflow(false);
        } else if (!props.stepCompleted) {
            setShowWorkflow(true);
        }
    }, [props.stepInProgressIndex]);

    function createContent() {
        return showWorkflow ? (
            props.requestFailureMessage ? (
                <div>{props.requestFailureMessage}</div>
            ) : (
                <div>
                    <WorkflowTemplateSubStep
                        stepCompleted={props.stepCompleted}
                        showWorflowTemplateView={setShowWorkflow}
                        product={props.product}
                        defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                        requestFailureMessage={props.requestFailureMessage}
                        setHideSecondStep={props.setHideSecondStep}
                        saveOnboardingStep={props.saveOnboardingStep}
                        stepName={stepName}
                        stepInProgressIndex={props.stepInProgressIndex}
                        saveStepInProgressIndex={props.saveStepInProgressIndex}
                    />
                </div>
            )
        ) : null;
    }
    return (
        <StepHeader
            title={t("Onboarding.gettingStartedFlow.workflowStep.title")}
            success={props.stepCompleted}
            showStep={showWorkflow}
            inactive={!props.stepCompleted && !props.stepActive}
            clickEvent={openWorkflowTemplateView}
            stepIndex={props.stepIndex}
            showContent={showWorkflow}
            menuExpanded={props.menuExpanded}
            content={createContent()}
            type={props.product}
            stepName={stepName}
        />
    );
};

export default connector(ChooseWorkflowTemplate);
