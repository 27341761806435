import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import Warning from "components/icons/Warning";
import style from "components/licenses/subscription-expiry-warning-banner/subscription-expiry-warning-banner.scss";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { useTheme } from "utils/useTheme";

const SubscriptionExpiryWarningBanner = (props: { productNames: string[] }): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();

    const subject = encodeURIComponent("Blancco Management Portal - Subscription licenses will get expired ");
    const body = encodeURIComponent(
        "Hi there\n\n" + "Subscription to " + props.productNames + " is going to end after this license delivery"
    );
    const mailToLink = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;

    return (
        <>
            {props.productNames.length <= 0 ? (
                ""
            ) : (
                <div className={classNames(style.warningBanner, style.subscriptionContainer)}>
                    <div className={style.textAndBannerContainer}>
                        <Warning color={theme.secondaryWarningBackgroundColor} width={15} height={15} />
                        <div className={style.textDisplay}>
                            {t("DeliveryHistory.addLicenseDelivery.subscriptionCountWarningMessage", {
                                licenses: props.productNames,
                            })}
                            <a href={mailToLink} color={theme.errorBackgroundColor}>
                                {t("DeliveryHistory.addLicenseDelivery.contactSupportMessage")}
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SubscriptionExpiryWarningBanner;
