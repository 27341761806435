import * as React from "react";

export const Home = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.7167 5.41706C12.2864 5.11586 11.7136 5.11586 11.2833 5.41706L5.28326 9.61631C4.94905 9.85021 4.75 10.2325 4.75 10.6404V18.3772C4.76649 18.616 4.87562 18.8411 5.05726 19.0044C5.24328 19.1718 5.49007 19.2604 5.74383 19.249C5.75508 19.2485 5.76634 19.2483 5.7776 19.2483H8.82754V13.9998C8.82754 12.481 10.0588 11.2498 11.5775 11.2498H12.4239C13.9427 11.2498 15.1739 12.481 15.1739 13.9998V19.2483H18.2224C18.2336 19.2483 18.2449 19.2485 18.2561 19.249C18.51 19.2604 18.757 19.1716 18.943 19.0041C19.1247 18.8405 19.2338 18.6151 19.25 18.376V10.6404C19.25 10.2325 19.051 9.85021 18.7167 9.61631L12.7167 5.41706ZM14.3766 20.7483C14.3923 20.7492 14.408 20.7497 14.4239 20.7497C14.4398 20.7497 14.4556 20.7492 14.4713 20.7483H18.2071C18.8463 20.7723 19.471 20.5472 19.9467 20.1188C20.4272 19.6862 20.7166 19.0811 20.7491 18.4353C20.7497 18.4227 20.75 18.4102 20.75 18.3976V10.6404C20.75 9.74296 20.3121 8.90198 19.5768 8.38739L13.5768 4.18814C12.63 3.5255 11.37 3.5255 10.4232 4.18814L4.42317 8.38739C3.68791 8.90198 3.25 9.74296 3.25 10.6404V18.3992C3.25 18.412 3.25033 18.4248 3.25098 18.4376C3.28403 19.0829 3.57367 19.6875 4.05409 20.1196C4.52971 20.5475 5.15412 20.7723 5.79296 20.7483H9.53022C9.54587 20.7492 9.56165 20.7497 9.57754 20.7497C9.59343 20.7497 9.60921 20.7492 9.62486 20.7483H14.3766ZM13.6739 19.2483V13.9998C13.6739 13.3094 13.1143 12.7498 12.4239 12.7498H11.5775C10.8872 12.7498 10.3275 13.3094 10.3275 13.9998V19.2483H13.6739Z"
            fill={props.color}
        />
    </svg>
);
