import * as React from "react";

export const Dashboard = (props: { className?: string; color: string }): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={props.className}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.25 6.82353C3.25 4.84992 4.84992 3.25 6.82353 3.25H17.1765C19.1501 3.25 20.75 4.84992 20.75 6.82353V16.2353C20.75 18.2089 19.1501 19.8088 17.1765 19.8088H6.82353C4.84992 19.8088 3.25 18.2089 3.25 16.2353V6.82353ZM6.82353 4.75C5.67835 4.75 4.75 5.67835 4.75 6.82353V16.2353C4.75 17.3805 5.67835 18.3088 6.82353 18.3088H17.1765C18.3216 18.3088 19.25 17.3805 19.25 16.2353V6.82353C19.25 5.67835 18.3216 4.75 17.1765 4.75H6.82353ZM15.2941 7.01471C15.7083 7.01471 16.0441 7.35049 16.0441 7.76471V16.2346C16.0441 16.6488 15.7083 16.9846 15.2941 16.9846C14.8799 16.9846 14.5441 16.6488 14.5441 16.2346V7.76471C14.5441 7.35049 14.8799 7.01471 15.2941 7.01471ZM12 10.214C12.4142 10.214 12.75 10.5498 12.75 10.964V16.2346C12.75 16.6488 12.4142 16.9846 12 16.9846C11.5858 16.9846 11.25 16.6488 11.25 16.2346V10.964C11.25 10.5498 11.5858 10.214 12 10.214ZM8.70588 12.8493C9.1201 12.8493 9.45588 13.1851 9.45588 13.5993V16.2346C9.45588 16.6488 9.1201 16.9846 8.70588 16.9846C8.29167 16.9846 7.95588 16.6488 7.95588 16.2346V13.5993C7.95588 13.1851 8.29167 12.8493 8.70588 12.8493Z"
            fill={props.color}
        />
    </svg>
);
