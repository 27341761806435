import * as React from "react";

export const Licenses = (props: { className?: string; size?: number; color: string }): JSX.Element => {
    const iconSize = props.size ?? 24;

    return (
        <svg
            width={iconSize}
            height={iconSize}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1064 5.32548C12.8441 4.58871 13.8442 4.17515 14.8868 4.17578C15.8916 4.17639 16.8565 4.56167 17.5847 5.24995C17.6202 5.31237 17.6646 5.36943 17.7165 5.41933C18.0947 5.78323 18.3963 6.21923 18.6033 6.70158C18.8103 7.18393 18.9186 7.70287 18.9218 8.22776C18.925 8.75265 18.823 9.27285 18.6218 9.75768C18.4207 10.2425 18.1244 10.6821 17.7506 11.0506C17.3767 11.419 16.9329 11.7089 16.4452 11.903C15.9575 12.0971 15.4358 12.1915 14.9111 12.1807C14.3863 12.1699 13.869 12.0541 13.3897 11.8401C12.9546 11.6459 12.5588 11.3743 12.2214 11.039C12.1891 10.9831 12.1495 10.9313 12.1031 10.8848C11.3663 10.1472 10.9527 9.14703 10.9534 8.10445C10.954 7.06187 11.3688 6.06225 12.1064 5.32548ZM11.594 12.4871C11.9554 12.7769 12.3532 13.0201 12.7781 13.2098C13.4404 13.5055 14.1551 13.6655 14.8802 13.6804C15.6053 13.6953 16.326 13.5648 16.9999 13.2966C17.6737 13.0285 18.287 12.628 18.8035 12.1189C19.32 11.6098 19.7294 11.0024 20.0073 10.3325C20.2852 9.66266 20.4262 8.9439 20.4218 8.21867C20.4174 7.49344 20.2678 6.77644 19.9817 6.10998C19.7129 5.48361 19.3287 4.91382 18.8495 4.43013C18.8162 4.37112 18.775 4.31685 18.7271 4.26882C17.7092 3.24969 16.3281 2.67665 14.8877 2.67578C13.4473 2.67491 12.0655 3.24627 11.0464 4.26418C10.0273 5.28208 9.45425 6.66314 9.45338 8.10355C9.45266 9.2997 9.84655 10.4554 10.5631 11.3966L4.57259 17.394L4.5701 17.3965L3.71659 18.242C3.42233 18.5335 3.4201 19.0084 3.71161 19.3027C4.00313 19.5969 4.47799 19.5992 4.77226 19.3076L5.09794 18.985L7.1221 21.0092C7.26275 21.1498 7.45351 21.2288 7.65242 21.2288C7.85134 21.2288 8.0421 21.1498 8.18276 21.0092L11.1588 18.0332C11.4516 17.7403 11.4516 17.2654 11.1588 16.9725L9.13648 14.9502L11.594 12.4871ZM8.07643 16.0115L9.56777 17.5028L7.65242 19.4182L6.16109 17.9268L8.07643 16.0115Z"
                fill={props.color}
            />
        </svg>
    );
};
