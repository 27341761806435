import * as React from "react";

export const NoAccess = (props: { className?: string; color: string }): JSX.Element => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.6568 19.5711C18.0474 19.9616 18.6805 19.9616 19.071 19.5711C19.4616 19.1806 19.4616 18.5474 19.071 18.1569L17.6568 19.5711ZM6.34313 5.42895C5.9526 5.03843 5.31944 5.03843 4.92891 5.42895C4.53839 5.81948 4.53839 6.45264 4.92891 6.84317L6.34313 5.42895ZM20 12.5C20 16.9183 16.4183 20.5 12 20.5V22.5C17.5228 22.5 22 18.0228 22 12.5H20ZM12 20.5C7.58172 20.5 4 16.9183 4 12.5H2C2 18.0228 6.47715 22.5 12 22.5V20.5ZM4 12.5C4 8.08172 7.58172 4.5 12 4.5V2.5C6.47715 2.5 2 6.97715 2 12.5H4ZM12 4.5C16.4183 4.5 20 8.08172 20 12.5H22C22 6.97715 17.5228 2.5 12 2.5V4.5ZM19.071 18.1569L12.6568 11.7426L11.2426 13.1569L17.6568 19.5711L19.071 18.1569ZM12.6568 11.7426L6.34313 5.42895L4.92891 6.84317L11.2426 13.1569L12.6568 11.7426Z"
            fill={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.68012 7.59438C4.62707 8.94906 4 10.6513 4 12.5C4 16.9183 7.58172 20.5 12 20.5C13.8487 20.5 15.5509 19.8729 16.9056 18.8199L5.68012 7.59438ZM7.09433 6.18016L18.3198 17.4057C19.3729 16.051 20 14.3487 20 12.5C20 8.08172 16.4183 4.5 12 4.5C10.1513 4.5 8.44902 5.12709 7.09433 6.18016ZM19.0721 19.57C20.8811 17.7605 22 15.2609 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5C14.762 22.5 17.2624 21.3803 19.0721 19.57Z"
            fill={props.color}
        />
    </svg>
);
