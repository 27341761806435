import * as React from "react";

export const CalendarIcon = (colorProps: { color: string }): JSX.Element => {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.09784 0.875C6.44302 0.875 6.72284 1.15482 6.72284 1.5V1.70833H9.28236V1.5C9.28236 1.15482 9.56219 0.875 9.90736 0.875C10.2525 0.875 10.5324 1.15482 10.5324 1.5V1.70833H12.7645C14.1567 1.70833 15.2943 2.82892 15.2943 4.22222V13.7778C15.2943 15.1711 14.1567 16.2917 12.7645 16.2917H3.2407C1.84849 16.2917 0.710938 15.1711 0.710938 13.7778V4.22222C0.710938 2.82892 1.84849 1.70833 3.2407 1.70833H5.47284V1.5C5.47284 1.15482 5.75266 0.875 6.09784 0.875ZM5.47284 2.95833H3.2407C2.52897 2.95833 1.96094 3.52912 1.96094 4.22222V6.70833H14.0443V4.22222C14.0443 3.52912 13.4762 2.95833 12.7645 2.95833H10.5324V4.33333C10.5324 4.67851 10.2525 4.95833 9.90736 4.95833C9.56219 4.95833 9.28236 4.67851 9.28236 4.33333V2.95833H6.72284V4.33333C6.72284 4.67851 6.44302 4.95833 6.09784 4.95833C5.75266 4.95833 5.47284 4.67851 5.47284 4.33333V2.95833ZM14.0443 7.95833H1.96094V13.7778C1.96094 14.4709 2.52896 15.0417 3.2407 15.0417H12.7645C13.4762 15.0417 14.0443 14.4709 14.0443 13.7778V7.95833Z"
                fill={colorProps.color}
            />
        </svg>
    );
};
