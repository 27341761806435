import classNames from "classnames";
import React from "react";
import { Menu } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { toast } from "react-toastify";

import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import style from "components/licenses/license-alerts/license-alerts.scss";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import DeleteItemStatusDialog from "components/status-dialog/DeleteItemStatusDialog";
import TextWithTooltip from "components/table/TextWithTooltip";
import listItemStyle from "components/tenants/tenants.scss";
import { AUTH_LICENSE_ALERT } from "domain/authority";
import { licenseAlertsService } from "services/licenses/LicenseAlertService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { useTheme } from "utils/useTheme";

import FailedRedNotificationIcon from "assets/images/icons/alertIcon.svg";

import testIds from "testIds.json";

interface Result {
    title: string;
    message: string | JSX.Element;
    image?: string | JSX.Element;
}

export interface AlertDetails {
    alertUuid: string;
    alertName: string;
    tenantName: string;
    tenantUuid: string;
    poolName: string;
    poolUuid: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    userDetail: state.userReducer.user,
});

const connector = connect(mapState);

const LicenseAlertsKebabMenu = (props: {
    licenseAlertUuid: string;
    licenseAlertName: string;
    onLicenseAlertDelete: () => void;
    onLicenseAlertEdit: () => void;
    edit: (value: boolean) => void;
    alertDetails: (value: AlertDetails) => void;
    tenantName: string;
    showForm: (value: boolean) => void;
    poolName: string;
    poolUuid: string;
}): JSX.Element => {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const theme = useTheme();
    const [result, setResult] = React.useState<Result>({ title: "", message: "", image: "" });
    const [deleteLicenseAlertsModalVisible, setDeleteLicenseAlertsModalVisible] = React.useState(false);
    const [licenseAlertsNotDeleted, setLicenseAlertsNotDeleted] = React.useState<boolean>(false);
    const [okClicked, setOkClicked] = React.useState<boolean>(false);

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
    };
    const handleLicenseAlertsDelete = () => {
        deleteLicenseAlertsSubmitEventHandler();
        setLicenseAlertsNotDeleted(false);
    };
    const deleteLicenseAlertsSubmitEventHandler = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        licenseAlertsService
            .deleteLicenseAlerts(props.licenseAlertUuid, abortController)
            .then(() => {
                props.onLicenseAlertDelete();
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("LicenseAlerts.deleteLicenseAlerts.deletedMessage")}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
            })
            .catch(() => {
                if (!signal.aborted) {
                    showResult({
                        title: t("LicenseAlerts.deleteLicenseAlerts.licenseAlertsNotDeletedTitle"),
                        message: <span>{t("LicenseAlerts.deleteLicenseAlerts.failureMessage")}</span>,
                        image: <img className={style.image} src={FailedRedNotificationIcon} />,
                    });
                }
                setLicenseAlertsNotDeleted(true);
            })
            .finally(() => {
                setOkClicked(false);
                setDeleteLicenseAlertsModalVisible(false);
            });
    };

    return (
        <>
            <div className={classNames(style.deleteIcon)}>
                <Menu className={style.kebabMenu} data-testid={testIds.common.primaryView.table.kebabMenu.itself}>
                    <ul>
                        {
                            <li className={listItemStyle.listItem}>
                                {userSessionService.userHasAnyAuthority([AUTH_LICENSE_ALERT]) && (
                                    <TextWithTooltip text={t("Common.edit")}>
                                        <MenuItemButton
                                            onClick={(e) => {
                                                props.edit(true);
                                                props.showForm(true);
                                                props.alertDetails({
                                                    alertUuid: props.licenseAlertUuid,
                                                    alertName: props.licenseAlertName,
                                                    tenantName: props.tenantName,
                                                    tenantUuid: "",
                                                    poolName: props.poolName,
                                                    poolUuid: props.poolUuid,
                                                });
                                                usageStatisticsService.sendEvent({
                                                    category: Category.LICENSE_ALERTS,
                                                    action: Action.EDIT_LICENSE_ALERTS,
                                                });
                                                e.preventDefault();
                                            }}
                                            data-testid={
                                                testIds.workArea.license.licenseAlerts.table.kebabMenu.deleteButton
                                            }
                                            className={style.menuItem}
                                        >
                                            {t("Common.edit")}
                                        </MenuItemButton>
                                    </TextWithTooltip>
                                )}
                            </li>
                        }
                        {
                            <>
                                <li className={style.deleteListItem}>
                                    {userSessionService.userHasAnyAuthority([AUTH_LICENSE_ALERT]) && (
                                        <TextWithTooltip text={t("Common.delete")}>
                                            <MenuItemButton
                                                onClick={(e) => {
                                                    setDeleteLicenseAlertsModalVisible(true);
                                                    usageStatisticsService.sendEvent({
                                                        category: Category.LICENSE_ALERTS,
                                                        action: Action.DELETE_LICENSE_ALERTS,
                                                    });
                                                    e.preventDefault();
                                                }}
                                                data-testid={
                                                    testIds.workArea.license.licenseAlerts.table.kebabMenu.deleteButton
                                                }
                                                className={style.menuItem}
                                            >
                                                {t("Common.delete")}
                                            </MenuItemButton>
                                        </TextWithTooltip>
                                    )}
                                </li>
                            </>
                        }
                    </ul>
                </Menu>
                <DeleteItemStatusDialog
                    title={result.title}
                    modalDisplayed={licenseAlertsNotDeleted}
                    setModalDisplayed={() => setLicenseAlertsNotDeleted(false)}
                    handleCommand={deleteLicenseAlertsSubmitEventHandler}
                    ditchLink={true}
                />
                <DeleteConfirmationDialog
                    modalDisplayed={deleteLicenseAlertsModalVisible}
                    introductionMessage={t("LicenseAlerts.deleteLicenseAlerts.introductionMessage", {
                        licenseAlertName: props.licenseAlertName,
                    })}
                    confirmationMessage={t("Common.deleteConfirmationMessage")}
                    handleCommand={handleLicenseAlertsDelete}
                    setModalDisplayed={() => setDeleteLicenseAlertsModalVisible(false)}
                    title={t("LicenseAlerts.deleteLicenseAlerts.title")}
                    loading={okClicked}
                />
            </div>
        </>
    );
};

export default connector(LicenseAlertsKebabMenu);
