import classNames from "classnames";
import React, { ChangeEvent, CSSProperties } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Label } from "reactstrap";

import Info from "components/icons/Info";
import localStyle from "components/licenses/assign-bms-keys/form.scss";
import styles from "components/licenses/license-configuration/bms/bms-config.scss";
import Tooltip from "components/tooltip/Tooltip";
import { NOTES_MAX_LENGTH } from "domain/globalConstants";
import { StoreState } from "store";
import form from "styles/form.scss";

interface Props {
    labelKey?: string;
    inputId: string;
    disabled?: boolean;
    value: string | number | undefined | null;
    toolTipContent?: string;
    className?: string;
    onChange: (newValue: string) => void;
    inputStyle?: CSSProperties;
    inputError?: string;
    maxLength?: number;
}

const mapState = (state: StoreState) => ({
    settings: state.reportViewSettings,
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BmsTextAreaComponent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const sanitizedValue = props.value !== null && props.value !== undefined ? props.value : "";

    return (
        <span className={classNames(styles.inputContainer)}>
            {props.labelKey && (
                <Label
                    htmlFor={props.inputId}
                    className={props.className ? classNames(props.className) : classNames(styles.bmsLabel)}
                >
                    {props.labelKey}
                </Label>
            )}

            <textarea
                className={classNames(form.input, styles.brandInput, styles.textareaInput)}
                id={props.inputId}
                value={sanitizedValue}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => props.onChange(event.target.value)}
                maxLength={props.maxLength ? props.maxLength : NOTES_MAX_LENGTH}
                disabled={props.disabled ? props.disabled : false}
            />

            {props.toolTipContent && (
                <Tooltip content={props.toolTipContent}>
                    <div className={localStyle.info} tabIndex={0}>
                        <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                    </div>
                </Tooltip>
            )}

            {props.inputError && (
                <div className={localStyle.info} tabIndex={0}>
                    <span className={classNames(styles.inputError)}>* {props.inputError}</span>
                </div>
            )}
        </span>
    );
};

export default connector(BmsTextAreaComponent);
