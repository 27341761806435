import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import MenuPanel from "./MenuPanel";
import style from "components/header/header.scss";
import HamburgerMenuIcon from "components/icons/HamburgerMenuIcon";
import NavigationMenu from "components/navigation/navigation-menu/NavigationMenu";
import TopNavigation from "components/navigation/TopNavigation";
import { preventBodyScrolling } from "utils/commonFunctions";

import logo from "assets/images/logo/blanccoManagementPortalLogoWhiteSmall.svg";

interface Props {
    skipToContentRef?: React.RefObject<HTMLDivElement>;
}

export const Header = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const [mobileNavOpen, setMobileNavOpen] = React.useState(false);

    React.useEffect(() => {
        preventBodyScrolling(mobileNavOpen);
    }, [mobileNavOpen]);

    return (
        <div className={style.header}>
            <div className={style.mobileMenu} onClick={() => setMobileNavOpen((prevState) => !prevState)}>
                <HamburgerMenuIcon isOpen={mobileNavOpen} />
            </div>
            <div className={style.logo}>
                <Link to="/">
                    <img src={logo} alt="Management Portal" />
                    <div className={style.version} title={"Management Portal version: " + process.env.BMP_VERSION} />
                </Link>
            </div>
            <button className={style.skipToContent} onClick={() => props.skipToContentRef?.current?.focus()}>
                {t("Common.skipToContent")}
            </button>
            <TopNavigation />
            <div className={style.rightMenu}>
                <MenuPanel />
            </div>
            {mobileNavOpen && <NavigationMenu />}
        </div>
    );
};
