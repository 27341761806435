import React from "react";

interface Props {
    color: string;
    rotate: boolean;
}

export const SidebarToggle = (props: Props) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ transform: props.rotate ? "rotate(180deg)" : "rotate(0deg)" }}
        >
            <path d="M18 7L12 12L18 17M12 7L6 12L12 17" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    );
};
