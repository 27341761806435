import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import CircleNumber from "components/home/CircleWithNumber";
import StepCompletedText from "components/home/StepCompletedText";
import StepHeader from "components/home/StepHeader";
import Info from "components/icons/Info";
import Tooltip from "components/tooltip/Tooltip";
import { BdeOnboardingSteps, BmdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepIndex: number;
    menuExpanded: boolean;
    stepCompleted: boolean;
    product: OnboardingProductType;
    stepActive: boolean;
    stepClickable: boolean;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    hasIbr: boolean;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

export interface Step {
    description: string;
    secondDescription?: string;
    tooltip?: string;
    info?: string;
    subStep?: string[];
}

const RunErasure = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const stepName =
        props.product == OnboardingProductType.BDE
            ? BdeOnboardingSteps.STEP_RUNNING_ERASURE
            : BmdeOnboardingSteps.STEP_RUNNING_ERASURE;
    const clickEvent = () => {
        props.stepClickable && setShowContent(!showContent);
    };
    const saveOnboardingStep = () => {
        props.saveOnboardingStep({
            product: props.product,
            step:
                props.product === OnboardingProductType.BMDE
                    ? BmdeOnboardingSteps.STEP_RUNNING_ERASURE
                    : BdeOnboardingSteps.STEP_RUNNING_ERASURE,
        });
    };

    React.useEffect(() => {
        if (props.stepInProgressIndex !== props.stepIndex) {
            setShowContent(false);
        }
    }, [props.stepInProgressIndex]);

    React.useEffect(() => {
        if (props.menuExpanded && props.stepClickable) {
            setShowContent(true);
        }
    }, [props.menuExpanded]);

    const bdeRunErasureSteps: Step[] = [
        {
            description: t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.first"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.second"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.third.firstHalf"),
            tooltip: t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.infoTooltip"),
            secondDescription: t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.third.secondHalf"),
        },
        {
            description: props.hasIbr
                ? t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.fourth.withIbr")
                : t("Onboarding.bmde.gettingStarted.steps.runErasure.bde.steps.fourth.withoutIbr"),
        },
    ];
    const bmdeRunErasureSteps: Step[] = [
        {
            description: t("Onboarding.bmde.gettingStarted.steps.runErasure.steps.first"),
            subStep: [
                t("Onboarding.bmde.gettingStarted.steps.runErasure.steps.firstSubStep"),
                t("Onboarding.bmde.gettingStarted.steps.runErasure.steps.secondSubStep"),
            ],
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.runErasure.steps.second"),
            tooltip: props.hasIbr ? t("Onboarding.bmde.gettingStarted.steps.runErasure.steps.infoTooltip") : undefined,
        },
    ];
    function createContent() {
        return (
            <>
                <div className={style.stepsContainer}>
                    {(props.product === OnboardingProductType.BDE ? bdeRunErasureSteps : bmdeRunErasureSteps).map(
                        (step, index) => (
                            <div key={index} className={style.subStepsToFollowLayout}>
                                <CircleNumber number={index + 1} subStep={true} />
                                <div className={style.stepPadding}>
                                    <div>
                                        {step.description}
                                        {step.subStep && (
                                            <div>
                                                <ul>
                                                    {step.subStep.map((subStep, index) => (
                                                        <li key={index} className={style.subStepList}>
                                                            {subStep}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        {step.tooltip && (
                                            <Tooltip content={step.tooltip} placement={"top"}>
                                                <span className={style.tooltipSpacing}>
                                                    <Info
                                                        borderColor={theme.contentBackgroundColor}
                                                        color={theme.sortIconActiveColor}
                                                        size={"14"}
                                                    />
                                                </span>
                                            </Tooltip>
                                        )}
                                        {step.secondDescription}
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
                <div className={style.markComplete}>
                    {props.stepCompleted ? (
                        <StepCompletedText
                            successText={t("Onboarding.gettingStartedFlow.createBootableUsbDrive.completed")}
                            testId={testIds.workArea.onboarding.step.statusLabel}
                            dataType={props.product + "_" + stepName}
                        />
                    ) : (
                        <Button
                            variant={"PRIMARY"}
                            loadingAnimation={props.stepInProgressIndex === props.stepIndex}
                            disabled={props.stepInProgressIndex === props.stepIndex}
                            className={classNames(buttons.primaryButtonWithoutIcon)}
                            onClick={() => {
                                props.saveStepInProgressIndex(props.stepIndex);
                                saveOnboardingStep();
                            }}
                            testId={testIds.workArea.onboarding.step.primaryButton}
                            data-type={props.product + "_" + stepName}
                        >
                            {props.stepInProgressIndex === props.stepIndex ? t("Onboarding.saving") : t("Common.ok")}
                        </Button>
                    )}
                </div>
            </>
        );
    }
    return (
        <StepHeader
            success={props.stepCompleted}
            showStep={showContent}
            inactive={!props.stepClickable}
            title={t("Onboarding.bmde.gettingStarted.steps.runErasure.title")}
            stepIndex={props.stepIndex}
            menuExpanded={props.menuExpanded}
            showContent={showContent}
            clickEvent={clickEvent}
            content={createContent()}
            type={props.product}
            stepName={stepName}
        />
    );
};

export default connector(RunErasure);
