import * as React from "react";

const KebabIcon = (props: { color: string; className?: string }): JSX.Element => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.001 25C16.9675 25 17.751 24.2165 17.751 23.25C17.751 22.2835 16.9675 21.5 16.001 21.5C15.0345 21.5 14.251 22.2835 14.251 23.25C14.251 24.2165 15.0345 25 16.001 25Z"
                fill={props.color}
            />
            <path
                d="M16.001 17.75C16.9675 17.75 17.751 16.9665 17.751 16C17.751 15.0335 16.9675 14.25 16.001 14.25C15.0345 14.25 14.251 15.0335 14.251 16C14.251 16.9665 15.0345 17.75 16.001 17.75Z"
                fill={props.color}
            />
            <path
                d="M16.001 10.5C16.9675 10.5 17.751 9.7165 17.751 8.75C17.751 7.7835 16.9675 7 16.001 7C15.0345 7 14.251 7.7835 14.251 8.75C14.251 9.7165 15.0345 10.5 16.001 10.5Z"
                fill={props.color}
            />
        </svg>
    );
};

export default KebabIcon;
