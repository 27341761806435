import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import ManageReportViewModal from "./ManageReportViewModal";
import NotificationModal from "components/modal/NotificationModal";
import { LocationState } from "components/reports/AllReportsTable";
import { reportViewService } from "services/report/ReportViewService";

interface Props {
    setVisible: (visible: boolean) => void;
    visible: boolean;
}

export default function LoadReportPathsModal(props: Props): JSX.Element {
    const paths = reportViewService.getPaths();
    const [visibleErrorDialog, setVisibleErrorDialog] = React.useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const toCreateViews = location.state ? (location.state as LocationState).toCreateViews : null;

    React.useEffect(() => {
        if (toCreateViews) {
            history.replace(location.pathname, { report: null, productType: null, toCreateViews: false });
        }
    }, [location]);
    return (
        <>
            <ManageReportViewModal
                handleVisibility={props.setVisible}
                paths={paths}
                create={true}
                visible={props.visible && !visibleErrorDialog}
                viewDetails={{
                    uuid: "",
                    name: "",
                    columns: [],
                    shared: false,
                    own: true,
                }}
            />
            <NotificationModal
                open={visibleErrorDialog}
                hide={() => setVisibleErrorDialog(false)}
                title={t("CreateReportView.loadFailedDialog.title")}
                message={t("CreateReportView.loadFailedDialog.message")}
            />
        </>
    );
}
