import classNames from "classnames";
import React from "react";
import { Menu } from "react-aria-menubutton";

import style from "components/dropdown-menu/dropdown-menu.scss";

interface Props {
    menuItems?: JSX.Element[];
    isScrollable?: boolean;
    /** Positions dropdown in relation to its parent element. */
    position?: Position;
    className?: string;
    testId?: string;
}

export type Position = "LEFT" | "LEFT-TOP" | "RIGHT" | "RIGHT-TOP" | "AUTO";

export const DropdownMenuDivider = () => <hr className={style.dropdownMenuDivider} />;

export const DropdownMenu = (props: Props) => {
    const dropdownMenuClasses = classNames(
        style.dropdownMenu,
        {
            [style.positionLeft]: props.position === "LEFT",
            [style.positionLeftTop]: props.position === "LEFT-TOP",
            [style.positionRight]: props.position === "RIGHT",
            [style.positionRightTop]: props.position === "RIGHT-TOP",
            [style.positionAuto]: props.position === "AUTO",
        },
        props.className,
        props.isScrollable ? style.scrollable : ""
    );

    return (
        <Menu className={dropdownMenuClasses} data-testid={props.testId}>
            <ul>
                {props.menuItems &&
                    props.menuItems.map((item, key) => (
                        <li className={style.dropdownMenuItem} key={key}>
                            {item}
                        </li>
                    ))}
            </ul>
        </Menu>
    );
};
