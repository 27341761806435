import React from "react";
import { useTranslation } from "react-i18next";

import style from "./confirmation-modal.scss";
import Warning from "components/icons/Warning";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import Heading from "components/typography/heading/Heading";
import buttons from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

export interface ConfirmationModalProps {
    isOpen: boolean;
    hideModal: () => void;
    isLoading: boolean;
    onConfirm: () => void;
    title: string | JSX.Element;
    subtitle: string | JSX.Element;
    actionButtonLabel: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props: ConfirmationModalProps): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Modal isOpen={props.isOpen} hideModal={props.hideModal} closeButton={true}>
            {props.isLoading ? (
                <LoadingIndicator />
            ) : (
                <div className={style.modalContainer}>
                    <div className={style.modalContentContainer}>
                        <Warning color={theme.secondaryWarningBackgroundColor} />
                        <Heading tag={"h2"}>{props.title}</Heading>
                        <p>{props.subtitle}</p>
                    </div>
                    <div className={style.modalButtons}>
                        <button
                            className={buttons.primaryButtonWithoutIcon}
                            onClick={props.onConfirm}
                            data-testid={testIds.common.confirmationDialog.confirmButton}
                        >
                            {props.actionButtonLabel}
                        </button>
                        <button
                            type="button"
                            className={buttons.secondaryButtonWithoutIcon}
                            onClick={props.hideModal}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.cancel")}
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export default ConfirmationModal;
