import classNames from "classnames";
import React from "react";
import { Button } from "react-aria-menubutton";
import { NavLink, NavLinkProps } from "react-router-dom";

import style from "./navigation-menu-item.scss";
import PremiumFeatureIcon from "components/icons/PremiumFeatureIcon";
import Tooltip from "components/tooltip/Tooltip";
import { useTheme } from "utils/useTheme";

interface BaseProps {
    testId?: string;
    className?: string;
    children: React.ReactNode;
    isAdvertised?: boolean;
    tooltipContent?: React.ReactNode;
}

interface ButtonProps extends BaseProps {
    as: "button";
    isActive?: boolean;
}

interface LinkProps extends BaseProps, Omit<NavLinkProps, "children" | "className" | "style"> {
    as: "link";
}

type Props = ButtonProps | LinkProps;
const isLinkProps = (props: Props): props is LinkProps => props.as === "link";

export const NavigationMenuItem = (props: Props) => {
    const { isActive, isAdvertised, tooltipContent, testId, ...baseProps } = props;
    const theme = useTheme();
    const classes = classNames(
        style.navigationMenuItem,
        {
            // active is NavLink's built-in class. Using the same here for consistency.
            ["active"]: isActive,
            [style.disabled]: isAdvertised,
        },
        props.className
    );

    if (isAdvertised) {
        return (
            <div className={classes} data-testid={testId}>
                {props.children}

                <Tooltip maxWidth={218} content={tooltipContent} placement="top-start">
                    <button className={style.tooltipIcon}>
                        <PremiumFeatureIcon color={theme.modularPurpleColor} />
                    </button>
                </Tooltip>
            </div>
        );
    }

    if (isLinkProps(props)) {
        return (
            <NavLink {...baseProps} to={props.to} data-testid={testId} className={classes}>
                {props.children}
            </NavLink>
        );
    }

    return (
        <Button {...baseProps} className={classes} data-testid={testId}>
            {props.children}
        </Button>
    );
};
