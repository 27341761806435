import classNames from "classnames";
import { useFeature } from "flagged";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import style from "components/header/header.scss";
import ToggleablePanel from "components/header/ToggleablePanel";
import ManagementMenu from "components/navigation/ManagementMenu";
import ReportingMenu from "components/navigation/ReportingMenu";
import topNavigation from "components/navigation/top-navigation.scss";
import {
    DASHBOARD_ROUTE,
    HOME_ROUTE,
    LICENSES_ROUTE,
    REPORTS_ROUTE,
    RouteDefinition,
    SUPPORT_AND_HELP_ROUTE,
    SUPPORT_ROUTE,
    SUSTAINABILITY_DASHBOARD_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import { StoreState } from "store";
import { getObject, RepositoryKey, setObject } from "utils/repository";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Panel {
    key: number;
    title: string;
    testId: string;
    children?: React.ReactElement;
    toggle: boolean;
    isActive: boolean;
    isValid?: boolean;
}

export default function TopNavigation() {
    const { t } = useTranslation();
    const user = useSelector((state: StoreState) => state.userReducer.user);
    const theme = useTheme();
    const tenantDetails = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const currentPath = window.location.pathname;
    const currentMenu = getObject(RepositoryKey.ACTIVE_TOP_MENU);

    const isActiveLink = (routes: RouteDefinition[]): boolean => {
        return routes.some((route: RouteDefinition) => {
            if (route.path === currentPath) {
                return true;
            }
            return route.childRoutes.find((child) =>
                child.path === currentPath
                    ? true
                    : child.childRoutes.find((innerChild) => innerChild.path === currentPath)
                    ? true
                    : false
            )
                ? true
                : false;
        });
    };

    const checkValidity = (route: RouteDefinition): boolean => {
        if (!route.isValidUser(user)) {
            return false;
        } else {
            const flag = route.getFeatureFlag();
            if (flag != null && !useFeature(flag)) {
                return false;
            }
            if (!route.isAccessibleTo(tenantDetails)) {
                return false;
            }
        }
        return true;
    };

    const menuItems: Panel[] = [
        {
            key: 1,
            title: t("Routes.reporting"),
            testId: "",
            children: <ReportingMenu checkValidity={checkValidity} isActiveLink={isActiveLink} />,
            toggle: true,
            isActive: isActiveLink([DASHBOARD_ROUTE, REPORTS_ROUTE, SUSTAINABILITY_DASHBOARD_ROUTE]),
            isValid: true,
        },
        {
            key: 2,
            title: t("Routes.management"),
            testId: "",
            children: <ManagementMenu checkValidity={checkValidity} isActiveLink={isActiveLink} />,
            toggle: true,
            isActive: isActiveLink([LICENSES_ROUTE, USERS_ROUTE, TENANTS_ROUTE, WORKFLOWS_ROUTE]),
            isValid: true,
        },
        {
            key: 3,
            title: t(SUPPORT_ROUTE.title),
            testId: SUPPORT_ROUTE.testId ? SUPPORT_ROUTE.testId : "",
            children: (
                <NavLink
                    activeClassName={topNavigation.current}
                    className={topNavigation.toggleButton}
                    exact={true}
                    to={SUPPORT_ROUTE.path}
                >
                    {t(SUPPORT_ROUTE.title)}
                </NavLink>
            ),
            toggle: false,
            isActive: isActiveLink([SUPPORT_AND_HELP_ROUTE]),
            isValid: checkValidity(SUPPORT_AND_HELP_ROUTE),
        },
        {
            key: 4,
            title: t("Routes.home"),
            testId: testIds.navigation.homeLink,
            children: (
                <NavLink className={topNavigation.toggleButton} exact={true} to={HOME_ROUTE.path}>
                    {t("Routes.home")}
                </NavLink>
            ),
            toggle: false,
            isActive: isActiveLink([HOME_ROUTE]),
            isValid: checkValidity(HOME_ROUTE),
        },
    ];

    React.useEffect(() => {
        const activeMenuItem = menuItems.filter((item) => item.isActive === true);
        if (activeMenuItem.length > 0 && currentMenu !== activeMenuItem[0].key) {
            setObject(RepositoryKey.ACTIVE_TOP_MENU, activeMenuItem[0].key);
        }
    }, []);

    return (
        <nav className={topNavigation.navigation} data-testid={testIds.navigation.itself}>
            <ul>
                {menuItems.map((menu) => {
                    if (!menu.isValid) {
                        return null;
                    }
                    return (
                        <li
                            key={menu.key}
                            className={classNames(
                                style.innerMenu,
                                menu.isActive && topNavigation.activeLink,
                                currentMenu === menu.key && topNavigation.staticBottomBorder
                            )}
                        >
                            {menu.toggle ? (
                                <div className={style.menuItem}>
                                    <ToggleablePanel
                                        title={menu.title}
                                        testId={menu.testId}
                                        menuType={"HEADER"}
                                        chevronSize={{ width: 14, height: 14 }}
                                        chevronColor={theme.whiteColor}
                                        buttonClass={topNavigation.toggleButton}
                                    >
                                        {menu.children}
                                    </ToggleablePanel>
                                </div>
                            ) : (
                                <div className={classNames(style.menuItem)} data-testid={menu.testId}>
                                    {menu.children}
                                </div>
                            )}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}
