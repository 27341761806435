import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import PreConfigureStep from "components/home/bde-home-page/PreConfigureStep";
import ChooseWorkflowTemplate from "components/home/choose-workflow-template/ChooseWorkflowTemplate";
import CreateBootableUsb from "components/home/onboarding-create-bootable-usb/CreateBootableUsb";
import RunErasure from "components/home/onboarding-run-erasure/RunErasure";
import ViewReport from "components/home/onboarding-view-report/ViewReport";
import { LicenseData } from "domain/licenses";
import {
    BdeOnboardingSteps,
    checkIfMenuExpanded,
    checkIfStepIsClickable,
    getStepIndexForBde,
    isStepCompleted,
    OnboardingProductType,
} from "domain/onboardings";
import { SoftwarePackage, Versions } from "domain/softwarePackages";
import { TemplateTableData } from "domain/workflowTemplates";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";

interface Props {
    lastCompletedStepIndex: number;
    fetchedSoftwarePackages?: SoftwarePackage[];
    defaultWorkflowTemplate: TemplateTableData;
    requestFailureMessage?: string;
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
    canViewReport: boolean;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    hasIbr: boolean;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});
const connector = connect(mapState);

const BdeSteps = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const getStepIndex = (step: BdeOnboardingSteps | undefined) => {
        return getStepIndexForBde(step, props.hasIbr);
    };

    const [showWorkflowStep, setShowWorkflowStep] = React.useState(false);

    React.useEffect(() => {
        props.hasIbr && setShowWorkflowStep(true);
    }, []);

    return (
        <>
            {props.hasIbr && (
                <ChooseWorkflowTemplate
                    stepIndex={getStepIndex(BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE)}
                    menuExpanded={checkIfMenuExpanded(
                        props.lastCompletedStepIndex,
                        getStepIndex(BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE),
                        props.hasIbr
                    )}
                    stepCompleted={isStepCompleted(
                        getStepIndex(BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE),
                        props.lastCompletedStepIndex
                    )}
                    stepActive={true}
                    product={OnboardingProductType.BDE}
                    defaultWorkflowTemplate={props.defaultWorkflowTemplate}
                    requestFailureMessage={props.requestFailureMessage}
                    saveOnboardingStep={props.saveOnboardingStep}
                    stepInProgressIndex={props.stepInProgressIndex}
                    saveStepInProgressIndex={props.saveStepInProgressIndex}
                />
            )}
            <PreConfigureStep
                stepIndex={getStepIndex(BdeOnboardingSteps.STEP_PRE_CONFIGURATION)}
                fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                fetchedLicenses={props.fetchedLicenses}
                featureWorkflowLicensePresent={showWorkflowStep}
                stepCompleted={isStepCompleted(
                    getStepIndex(BdeOnboardingSteps.STEP_PRE_CONFIGURATION),
                    props.lastCompletedStepIndex
                )}
                stepActive={!props.hasIbr}
                stepClickable={checkIfStepIsClickable(
                    getStepIndex(BdeOnboardingSteps.STEP_PRE_CONFIGURATION),
                    props.lastCompletedStepIndex,
                    props.hasIbr
                )}
                saveOnboardingStep={props.saveOnboardingStep}
                menuExpanded={checkIfMenuExpanded(
                    props.lastCompletedStepIndex,
                    getStepIndex(BdeOnboardingSteps.STEP_PRE_CONFIGURATION),
                    props.hasIbr
                )}
                stepInProgressIndex={props.stepInProgressIndex}
                saveStepInProgressIndex={props.saveStepInProgressIndex}
            />

            <CreateBootableUsb
                menuExpanded={checkIfMenuExpanded(
                    props.lastCompletedStepIndex,
                    getStepIndex(BdeOnboardingSteps.STEP_BOOTING_USB_DRIVE),
                    props.hasIbr
                )}
                stepCompleted={isStepCompleted(
                    getStepIndex(BdeOnboardingSteps.STEP_BOOTING_USB_DRIVE),
                    props.lastCompletedStepIndex
                )}
                stepActive={false}
                licenses={props.licenses}
                fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                stepIndex={getStepIndex(BdeOnboardingSteps.STEP_BOOTING_USB_DRIVE)}
                stepClickable={checkIfStepIsClickable(
                    getStepIndex(BdeOnboardingSteps.STEP_BOOTING_USB_DRIVE),
                    props.lastCompletedStepIndex,
                    props.hasIbr
                )}
                product={OnboardingProductType.BDE}
                saveOnboardingStep={props.saveOnboardingStep}
                stepInProgressIndex={props.stepInProgressIndex}
                saveStepInProgressIndex={props.saveStepInProgressIndex}
            />
            <RunErasure
                stepIndex={getStepIndex(BdeOnboardingSteps.STEP_RUNNING_ERASURE)}
                menuExpanded={checkIfMenuExpanded(
                    props.lastCompletedStepIndex,
                    getStepIndex(BdeOnboardingSteps.STEP_RUNNING_ERASURE),
                    props.hasIbr
                )}
                stepActive={false}
                product={OnboardingProductType.BDE}
                stepClickable={checkIfStepIsClickable(
                    getStepIndex(BdeOnboardingSteps.STEP_RUNNING_ERASURE),
                    props.lastCompletedStepIndex,
                    props.hasIbr
                )}
                stepCompleted={isStepCompleted(
                    getStepIndex(BdeOnboardingSteps.STEP_RUNNING_ERASURE),
                    props.lastCompletedStepIndex
                )}
                saveOnboardingStep={props.saveOnboardingStep}
                hasIbr={props.hasIbr}
                stepInProgressIndex={props.stepInProgressIndex}
                saveStepInProgressIndex={props.saveStepInProgressIndex}
            />
            {props.canViewReport && (
                <ViewReport
                    stepIndex={getStepIndex(BdeOnboardingSteps.STEP_VIEWING_REPORT)}
                    menuExpanded={checkIfMenuExpanded(
                        props.lastCompletedStepIndex,
                        getStepIndex(BdeOnboardingSteps.STEP_VIEWING_REPORT),
                        props.hasIbr
                    )}
                    stepCompleted={isStepCompleted(
                        getStepIndex(BdeOnboardingSteps.STEP_VIEWING_REPORT),
                        props.lastCompletedStepIndex
                    )}
                    stepActive={false}
                    stepClickable={checkIfStepIsClickable(
                        getStepIndex(BdeOnboardingSteps.STEP_VIEWING_REPORT),
                        props.lastCompletedStepIndex,
                        props.hasIbr
                    )}
                    product={OnboardingProductType.BDE}
                    hasIbr={props.hasIbr}
                    saveOnboardingStep={props.saveOnboardingStep}
                />
            )}
        </>
    );
};

export default connector(BdeSteps);
