import * as React from "react";

export const Learn = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            d="M12.4461 16.2745C12.4461 16.6752 12.1213 17 11.7206 17C11.3199 17 10.9951 16.6752 10.9951 16.2745C10.9951 15.8738 11.3199 15.549 11.7206 15.549C12.1213 15.549 12.4461 15.8738 12.4461 16.2745Z"
            fill={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.92244 8.29482C10.415 7.96641 11.0633 7.75 11.7206 7.75C12.3806 7.75 13.0585 7.9694 13.5784 8.43156C14.1083 8.90261 14.4412 9.59745 14.4412 10.4706C14.4412 11.1306 14.2218 11.8085 13.7596 12.3284C13.3911 12.743 12.8855 13.037 12.2647 13.1453V13.6144C12.2647 13.9149 12.0211 14.1585 11.7206 14.1585C11.4201 14.1585 11.1765 13.9149 11.1765 13.6144V12.6471C11.1765 12.3466 11.4201 12.1029 11.7206 12.1029C12.2984 12.1029 12.6918 11.8917 12.9463 11.6054C13.2096 11.3092 13.3529 10.8988 13.3529 10.4706C13.3529 9.89275 13.1417 9.49935 12.8554 9.24491C12.5592 8.98158 12.1488 8.83824 11.7206 8.83824C11.2896 8.83824 10.8497 8.98457 10.5261 9.20028C10.1788 9.43183 10.0882 9.6513 10.0882 9.7451C10.0882 10.0456 9.84463 10.2892 9.54412 10.2892C9.24361 10.2892 9 10.0456 9 9.7451C9 9.1134 9.45358 8.60739 9.92244 8.29482Z"
            fill={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75ZM3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12Z"
            fill={props.color}
        />
    </svg>
);
