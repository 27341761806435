import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import { DropdownMenu, Position } from "components/dropdown-menu/DropdownMenu";
import ToggleablePanel from "components/header/ToggleablePanel";
import { GettingStarted as BDEGettingStarted } from "components/home/bde-home-page/GettingStarted";
import { GettingStarted as BMDEGettingStarted } from "components/home/bmde-home-page/GettingStarted";
import { DownloadIcon } from "components/home/home-icons/DownloadIcon";
import { ManualIcon } from "components/home/home-icons/ManualIcon";
import { WireIcon } from "components/home/home-icons/WireIcon";
import style from "components/home/product-card/product-card.scss";
import { subpageContext } from "components/layout/ApplicationLayout";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Tooltip from "components/tooltip/Tooltip";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { LicenseData } from "domain/licenses";
import { getHomepageProductsMappingList, OnboardingProductType } from "domain/onboardings";
import { SoftwarePackage, SoftwarePackageHomePage } from "domain/softwarePackages";
import { getProductManualLanguage } from "services/product-manuals/productManualLanguageRepository";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { hasTenantCookie } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import { ThemeName } from "store/theme";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Props {
    fetchedLicenses: LicenseData[];
    fetchedSoftwarePackages?: SoftwarePackage[];
    softwarePackages: SoftwarePackage[];
    name: string;
    bdeOnboarded: boolean;
    bmdeOnboarded: boolean;
    additionalProduct?: boolean;
}

const ProductCard = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);
    const theme = useTheme();
    const ref = React.useRef<HTMLDivElement | null>(null);

    const [dropdownMenuClass, setdDropdownMenuClass] = React.useState<Position>("AUTO");
    const location = useLocation();
    const [showGettingStarted, setShowGettingStarted] = React.useState(false);
    const [productType, setProductType] = React.useState<"BDE" | "BMDE" | undefined>(undefined);
    const [description, setDescription] = React.useState<string>();
    const [onboarding, setOnboarding] = React.useState<string>();
    const [onboardingTooltipDescription, setOnboardingTooltipDescription] = React.useState<string>();
    const [productVersions, setProductVersions] = React.useState<
        { version: string; downloadUrl: string; productName: string }[]
    >([]);
    const [manualsVersions, setManualsVersions] = React.useState<
        (
            | {
                  manualType: string;
                  version: string;
                  language: string;
                  link: string;
              }
            | undefined
        )[]
    >([]);

    const homepageProductsMappingList = getHomepageProductsMappingList();

    React.useEffect(() => {
        const product = homepageProductsMappingList.find((productItem) => productItem.productName === props.name);
        setDescription(product?.description);
        setOnboarding(product?.onboarding);
        setOnboardingTooltipDescription(product?.onboardingDescription);

        const productVersions = props.softwarePackages.flatMap((softwarePackage) =>
            softwarePackage.versions.map((version) => ({
                productName: softwarePackage.productName.replace(product?.productName ?? "", ""),
                version: version.version,
                downloadUrl: version.downloadUrl,
            }))
        );
        setProductVersions(productVersions);

        const manualVersions = Array.from(
            new Set(
                props.softwarePackages.flatMap(
                    (product: SoftwarePackageHomePage) =>
                        product.manualsDownloads?.versions.flatMap((version) =>
                            version.manuals.map((manual) =>
                                JSON.stringify({
                                    version: version.version,
                                    language: getProductManualLanguage(manual.language).title,
                                    link: manual.link,
                                    manualType: manual.type === "admin" ? t("Onboarding.home.adminLabel") : "",
                                })
                            )
                        ) ?? []
                )
            )
        ).map((item) => JSON.parse(item));

        setManualsVersions(manualVersions);

        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            if (window.innerHeight - rect.top < 400) {
                if (window.innerHeight - rect.top < 400) {
                    setdDropdownMenuClass("LEFT-TOP");
                } else {
                    setdDropdownMenuClass("RIGHT-TOP");
                }
            } else {
                if (window.innerWidth - rect.width - rect.left < 400) {
                    setdDropdownMenuClass("LEFT");
                }
            }
        }
    }, []);

    React.useEffect(() => {
        if (location.state) {
            const state = location.state as HomeState;
            if (state.showGettingStarted) {
                setShowGettingStarted(true);
                setProductType(state.productType);
            }
        }
    }, [location]);

    React.useEffect(() => {
        if (showGettingStarted && productType !== undefined) {
            context?.setSubpage({
                content:
                    productType === "BDE" ? (
                        <BDEGettingStarted
                            licenses={productVersions}
                            fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                            fetchedLicenses={props.fetchedLicenses}
                            from={"Reports"}
                        />
                    ) : (
                        <BMDEGettingStarted
                            licenses={productVersions}
                            fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                            fetchedLicenses={props.fetchedLicenses}
                            from={"Reports"}
                        />
                    ),
                isModal: true,
            });
        }
    }, [showGettingStarted, productType]);

    const themeName = useSelector((state: StoreState) => state.themeReducer.themeName);
    const opacity = [ThemeName.DEFAULT_DARK, ThemeName.SUB_TENANT_DARK, ThemeName.TENANT_DARK].includes(themeName)
        ? "0.11"
        : "1";

    const showButtonContainer = () => {
        if (hasTenantCookie()) {
            return showProductOptions();
        }
        if (onboarding === OnboardingProductType.BDE && !props.bdeOnboarded) {
            return (
                <ButtonContainer alignment="RIGHT" noSpacingTop={true}>
                    <Button
                        variant={"PRIMARY"}
                        type={"button"}
                        isSmallButton
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.ONBOARDING,
                                action: Action.GET_STARTED,
                                label: Label.BDE,
                            });
                            context?.setSubpage({
                                content:
                                    onboarding === "BDE" ? (
                                        <BDEGettingStarted
                                            licenses={productVersions}
                                            fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                                            fetchedLicenses={props.fetchedLicenses}
                                        />
                                    ) : (
                                        <></>
                                    ),
                                isModal: true,
                            });
                        }}
                        testId={testIds.homePage.productCard.getStartedButton}
                    >
                        {t("Onboarding.home.getStartedButton")}
                    </Button>
                </ButtonContainer>
            );
        } else if (onboarding === OnboardingProductType.BMDE && !props.bmdeOnboarded) {
            return (
                <ButtonContainer alignment="RIGHT" noSpacingTop={true}>
                    <Button
                        variant={"PRIMARY"}
                        type={"button"}
                        isSmallButton
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.ONBOARDING,
                                action: Action.GET_STARTED,
                                label: Label.BMDE,
                            });
                            context?.setSubpage({
                                content:
                                    onboarding === "BMDE" ? (
                                        <BMDEGettingStarted
                                            licenses={productVersions}
                                            fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                                            fetchedLicenses={props.fetchedLicenses}
                                        />
                                    ) : (
                                        <></>
                                    ),
                                isModal: true,
                            });
                        }}
                        testId={testIds.homePage.productCard.getStartedButton}
                    >
                        {t("Onboarding.home.getStartedButton")}
                    </Button>
                </ButtonContainer>
            );
        }
        return showProductOptions();
    };

    function showProductOptions() {
        let dropdownDownloadsMenu;
        if (productVersions.length > 1) {
            dropdownDownloadsMenu = (
                <div className={style.downloadIcons}>
                    <ToggleablePanel
                        title={""}
                        testId={testIds.homePage.productCard.downloadsMenu.button}
                        menuType={"HEADER"}
                        icon={
                            <Tooltip content={t("Onboarding.home.downloadIconTooltip")}>
                                <DownloadIcon color={theme.homeIconsColor} />
                            </Tooltip>
                        }
                        hideChevron={true}
                    >
                        <DropdownMenu
                            position={dropdownMenuClass}
                            testId={testIds.homePage.productCard.downloadsMenu.itself}
                            isScrollable
                            menuItems={productVersions.map((item, index) => (
                                <MenuItemButton
                                    key={"product" + index}
                                    tabIndex={0}
                                    onClick={() => {
                                        window.open(item.downloadUrl, "_blank");
                                    }}
                                >
                                    {item.version} {item.productName}
                                </MenuItemButton>
                            ))}
                        />
                    </ToggleablePanel>
                </div>
            );
        } else {
            dropdownDownloadsMenu = (
                <div
                    data-testid={testIds.homePage.productCard.downloadsMenu.button}
                    onClick={() => window.open(productVersions[0].downloadUrl, "_blank")}
                    className={style.downloadIcons}
                >
                    <Tooltip content={t("Onboarding.home.downloadIconTooltip")}>
                        <DownloadIcon color={theme.homeIconsColor} />
                    </Tooltip>
                </div>
            );
        }

        let dropdownManualsMenu;
        if (manualsVersions.length > 1) {
            dropdownManualsMenu = (
                <div className={style.downloadIcons}>
                    <ToggleablePanel
                        title={""}
                        testId={testIds.homePage.productCard.manualsMenu.button}
                        menuType={"HEADER"}
                        icon={
                            <Tooltip content={t("Onboarding.home.manualIconTooltip")}>
                                <ManualIcon color={theme.homeIconsColor} />
                            </Tooltip>
                        }
                        hideChevron={true}
                    >
                        <DropdownMenu
                            position={dropdownMenuClass}
                            testId={testIds.homePage.productCard.manualsMenu.itself}
                            isScrollable
                            menuItems={manualsVersions.map((item, index) => {
                                if (item) {
                                    return (
                                        <MenuItemButton
                                            key={"manual" + index}
                                            tabIndex={0}
                                            onClick={() => {
                                                window.open(item.link, "_blank");
                                            }}
                                        >
                                            {item.version.split("-")[1] ? item.version.split("-")[1] : item.version}{" "}
                                            {item.language} {item.manualType ? "(" + item.manualType + ")" : ""}
                                        </MenuItemButton>
                                    );
                                }
                                return <></>;
                            })}
                        />
                    </ToggleablePanel>
                </div>
            );
        } else {
            dropdownManualsMenu = manualsVersions && (
                <div
                    data-testid={testIds.homePage.productCard.manualsMenu.button}
                    onClick={() => {
                        if (manualsVersions[0]) {
                            window.open(manualsVersions[0].link, "_blank");
                        }
                    }}
                    className={style.downloadIcons}
                >
                    <Tooltip content={t("Onboarding.home.manualIconTooltip")}>
                        <ManualIcon color={theme.homeIconsColor} />
                    </Tooltip>
                </div>
            );
        }

        return (
            <div className={style.manualDownloadsWrapper}>
                {dropdownDownloadsMenu}
                {dropdownManualsMenu}
            </div>
        );
    }

    return props.additionalProduct ? (
        <div
            ref={ref}
            className={style.productAdditionalCard}
            data-testid={testIds.homePage.productCard.itself}
            data-type={props.name}
        >
            <div className={style.productAdditionalCardWrapper}>
                <div className={style.name}>{props.name}</div>
                <div className={style.versionWrapper}>
                    <div className={style.versionAdditionalProduct}>
                        <TextBlock disableBottomSpacing>
                            {productVersions[0] ? productVersions[0].version : null}
                        </TextBlock>
                    </div>
                </div>
            </div>
            <div className={style.descriptionAdditionalProduct}>
                {onboarding ? showButtonContainer() : showProductOptions()}
            </div>
        </div>
    ) : (
        <div
            className={style.productCard}
            ref={ref}
            data-testid={testIds.homePage.productCard.itself}
            data-type={props.name}
        >
            <div className={style.productCardWrapper}>
                <div>
                    <div className={style.name}>{props.name}</div>
                    <div className={style.versionWrapper}>
                        {onboarding ? (
                            <Tooltip
                                content={
                                    onboardingTooltipDescription
                                        ? onboardingTooltipDescription
                                        : t("Onboarding.home.automationTooltip")
                                }
                            >
                                <WireIcon
                                    backgroundColor={theme.homeWireIconBackgroundColor}
                                    color={theme.homeWireIconColor}
                                    opacity={opacity}
                                />
                            </Tooltip>
                        ) : null}
                        <div className={style.version}>
                            <TextBlock disableBottomSpacing>
                                {productVersions[0] ? productVersions[0].version : null}
                            </TextBlock>
                        </div>
                    </div>
                </div>
                {onboarding ? showButtonContainer() : showProductOptions()}
            </div>
            <div className={style.description}>
                <span>{description}</span>
            </div>
        </div>
    );
};

interface HomeState {
    showGettingStarted?: boolean;
    productType?: "BDE" | "BMDE" | undefined;
}

export default ProductCard;
