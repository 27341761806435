import * as React from "react";

interface Props {
    color?: string;
}

export const DownloadIcon = React.forwardRef<SVGSVGElement, Props>(
    (props, ref): JSX.Element => (
        <svg ref={ref} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 8.25C16.4142 8.25 16.75 8.58579 16.75 9V17.5L19.55 15.4C19.8814 15.1515 20.3515 15.2186 20.6 15.55C20.8485 15.8814 20.7814 16.3515 20.45 16.6L16.45 19.6C16.1833 19.8 15.8167 19.8 15.55 19.6L11.55 16.6C11.2186 16.3515 11.1515 15.8814 11.4 15.55C11.6485 15.2186 12.1186 15.1515 12.45 15.4L15.25 17.5V9C15.25 8.58579 15.5858 8.25 16 8.25ZM8 18.25C8.41421 18.25 8.75 18.5858 8.75 19V22.25H23.25V19C23.25 18.5858 23.5858 18.25 24 18.25C24.4142 18.25 24.75 18.5858 24.75 19V23C24.75 23.4142 24.4142 23.75 24 23.75H8C7.58579 23.75 7.25 23.4142 7.25 23V19C7.25 18.5858 7.58579 18.25 8 18.25Z"
                fill={props.color}
            />
        </svg>
    )
);
