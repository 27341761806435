import classNames from "classnames";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import { displayStepCompletionIcon } from "components/home/bmde-home-page/GettingStarted";
import Chevron from "components/icons/Chevron";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    success: boolean;
    showStep: boolean;
    inactive: boolean;
    title: string;
    clickEvent: () => void;
    stepIndex: number;
    showContent: boolean;
    menuExpanded: boolean;
    content: JSX.Element | null;
    type: string;
    stepName: string;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const StepHeader = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const chevronClass: string = classNames(style.arrowButton, {
        [style.arrowButtonDown]: !props.showStep,
        [style.arrowButtonUp]: props.showStep,
        [style.succcessArrowButton]: props.success && !props.showStep,
    });
    return (
        <div
            className={classNames(
                props.showStep ? style.stepInProgress : props.success ? style.successColor : style.stepsToFollowLayout,
                props.inactive ? style.inactiveStep : style.activeStep,
                { [style.hoverStep]: !props.showContent && !props.inactive }
            )}
        >
            <div
                onClick={() => {
                    props.clickEvent();
                }}
                data-testid={testIds.workArea.onboarding.step.itself}
                data-type={props.type + "_" + props.stepName}
                className={classNames(style.headerLayout)}
            >
                <div className={style.step}>
                    <div className={classNames(style.stepWidthAlignment)}>
                        {displayStepCompletionIcon(
                            props.showContent,
                            props.success,
                            props.stepIndex + 1,
                            !props.inactive,
                            props.theme.defaultStarColor,
                            props.theme.whiteColor,
                            props.theme.secondaryWarningBackgroundColor,
                            props.theme.descriptionTextColor
                        )}
                    </div>
                    <div className={style.stepHeading}>{props.title}</div>
                    <div
                        hidden={props.inactive && !props.showContent}
                        className={classNames(style.arrow, style.stepWidthAlignment)}
                    >
                        <Button variant="TERTIARY">
                            <Chevron color={props.theme.activeForegroundColor} className={chevronClass} />
                        </Button>
                    </div>
                </div>
            </div>

            {props.showContent ? (
                <div className={style.descriptionOfStep}>
                    <div className={classNames(style.divider, style.dividerLayout)} />
                    {props.content}
                </div>
            ) : null}
        </div>
    );
};

export default connector(StepHeader);
