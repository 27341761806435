import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./navigation-panel-footer.scss";
import DarkModeSwitch from "components/dark-mode-switch/DarkModeSwitch";
import headerStyle from "components/header/header.scss";
import { useOutsideClick } from "components/header/MenuPanel";
import ToggleablePanel from "components/header/ToggleablePanel";
import Bell from "components/icons/Bell";
import RefreshButtonIcon from "components/icons/RefreshButtonIcon";
import Modal from "components/modal/Modal";
import NotificationMenu from "components/notifications/NotificationMenu";
import ReportDeletionNotificationDetails from "components/reports/deletion/ReportDeletionNotificationDetails";
import ReportImportNotificationDetails from "components/reports/import/ReportImportNotificationDetails";
import NotificationDetails from "components/reports/notification-details/NotificationDetails";
import Tooltip from "components/tooltip/Tooltip";
import { UserMenu } from "components/user-menu/UserMenu";
import { Notification } from "domain/notification";
import { notificationService } from "services/notification/NotificationService";
import { ReportDeletionNotificationData } from "services/report/ReportDeletionService";
import { ImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { getTenantName, hasTenantCookie } from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { StoreState } from "store";
import { getInitials } from "utils/commonFunctions";
import { logger } from "utils/logging";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Props {
    isMinimized?: boolean;
    className?: string;
}

const RETRY_FETCH_NOTIFICATIONS = 15_000;

export const NavigationPanelFooter = (props: Props) => {
    const [reportImportDialogRefreshCount, setReportImportDialogRefreshCount] = React.useState<number>(0);
    const [importNotificationData, setImportNotificationData] = React.useState<ImportNotificationData>({
        jobId: null,
        jobType: "NORMAL",
    });
    const [reportExportDialogRefreshCount, setReportExportDialogRefreshCount] = React.useState<number>(0);
    const [reportDialogRefreshIconVisibility, setReportDialogRefreshIconVisibility] = React.useState<boolean>(false);
    const [reportExportJobId, setReportExportJobId] = React.useState<string>("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [notificationVisibility, setNotificationVisibility] = React.useState(false);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);
    const [showBadge, setShowBadge] = React.useState(false);
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [reportDeletionNotificationData, setReportDeleteNotificationData] =
        React.useState<ReportDeletionNotificationData>({
            jobId: null,
            totalReports: "",
        });

    const { t } = useTranslation();
    const theme = useTheme();
    const user = useSelector((state: StoreState) => state.userReducer.user);

    const updateTenantDeletionStatus = useCallback(
        (updateTenantUuid: string, abortControllers: AbortController[]) => {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            tenantService.updateTenantDeletionStatus(updateTenantUuid, abortController).catch(() => {
                logger.error("Failed to update tenant deletion status.");
            });
        },
        [abortControllers]
    );

    const fetchNotifications = useCallback(() => {
        const abortController: AbortController = new AbortController();
        abortControllers.push(abortController);
        notificationService.fetchNotifications(abortController).then((data) => {
            setNotifications(data.sort((a, b) => b.creationDate.localeCompare(a.creationDate)));
        });
    }, [abortControllers]);

    const closeReportDeletionResultsDialog = () =>
        setReportDeleteNotificationData((prevState: ReportDeletionNotificationData) => ({
            ...prevState,
            jobId: null,
        }));

    const closeReportImportResultsDialog = () =>
        setImportNotificationData((prevState: ImportNotificationData) => ({
            ...prevState,
            jobId: null,
            totalReports: "",
        }));

    const createNotificationBadge = (): JSX.Element | null => {
        const seenUuids = notificationService.getSeenNotifications() ?? [];
        let count = 0;
        for (const index in notifications) {
            const uuid = notifications[index].uuid;
            if (!seenUuids.includes(uuid)) {
                count++;
            }
        }
        if (count == 0) {
            return null;
        }

        return (
            <div
                className={classNames(
                    headerStyle.circle,
                    { [headerStyle.oneDigitSize]: count < 10 },
                    { [headerStyle.twoDigitsSize]: count > 9 && count < 100 },
                    { [headerStyle.threeDigitsSize]: count > 99 }
                )}
            >
                <div className={headerStyle.count}>{count < 100 ? count : t("Notification.count")}</div>
            </div>
        );
    };

    const handleFetch = () => {
        fetchNotifications();
    };

    const handleClickOutside = () => {
        setNotificationVisibility(false);
    };

    const ref = useOutsideClick(handleClickOutside);

    const getTitle = (notificationType: string) => {
        if (notificationType === "REPORT_EXPORT_STARTED") {
            return t("Notification.reportNotifications.inProgressTitle");
        } else if (notificationType === "REPORT_EXPORT_FAILED") {
            return t("Notification.reportNotifications.failureTitle");
        } else if (notificationType === "REPORT_EXPORT_SUCCEEDED") {
            return t("Notification.reportNotifications.successTitle");
        } else if (notificationType === "REPORT_EXPORT_WARNING") {
            return t("Notification.reportNotifications.successWithFailTitle");
        }
        return "";
    };

    const reportDeletionResult = React.useMemo(
        () => (
            <Modal
                isOpen={reportDeletionNotificationData.jobId !== null}
                hideModal={() =>
                    setReportDeleteNotificationData((prevState: ReportDeletionNotificationData) => ({
                        ...prevState,
                        jobId: null,
                    }))
                }
            >
                <ReportDeletionNotificationDetails
                    data={reportDeletionNotificationData}
                    onClose={closeReportDeletionResultsDialog}
                />
            </Modal>
        ),
        [reportDeletionNotificationData.jobId]
    );

    const setRefreshIconVisibility = (show: boolean) => setReportDialogRefreshIconVisibility(show);
    const notificationData = notifications.filter((each) => each.data.includes(reportExportJobId))[0];

    const reportImportResult = React.useMemo(
        () => (
            <Modal
                isOpen={importNotificationData.jobId !== null}
                hideModal={() =>
                    setImportNotificationData((prevState: ImportNotificationData) => ({ ...prevState, jobId: null }))
                }
                modalTitle={t("Notification.reportImportResultDialog.title")}
                action={
                    reportDialogRefreshIconVisibility && (
                        <Tooltip content={t("Notification.reportNotifications.refreshTableIconTooltip")}>
                            <span
                                className={headerStyle.reportImportResultRefreshIcon}
                                onClick={() => {
                                    usageStatisticsService.sendEvent({
                                        category: Category.REPORT_IMPORT,
                                        action: Action.REFRESH,
                                    });
                                    setReportImportDialogRefreshCount((prev) => prev + 1);
                                }}
                            >
                                <span data-testid={testIds.workArea.report.reportResultsDialog.refreshButton}>
                                    <RefreshButtonIcon color={theme.iconFillColor} />
                                </span>
                            </span>
                        </Tooltip>
                    )
                }
            >
                <ReportImportNotificationDetails
                    data={importNotificationData}
                    refreshCount={reportImportDialogRefreshCount}
                    setRefreshIcon={setReportDialogRefreshIconVisibility}
                    onClose={closeReportImportResultsDialog}
                />
            </Modal>
        ),
        [importNotificationData.jobId, reportDialogRefreshIconVisibility, reportImportDialogRefreshCount]
    );

    let username;
    let usernameFormattedForInitials;
    if (getTenantName() != "") {
        username = getTenantName();
    } else {
        username = user ? (user.name ? user.name : user.username.split("@")[0]) : "";
        usernameFormattedForInitials = username.replace(".", " ");
    }

    React.useEffect(() => {
        if (initialLoading) {
            handleFetch();
            setInitialLoading(false);
            setShowBadge(true);
        }
        const notificationTimer = setTimeout(() => {
            handleFetch();
            setShowBadge(true);
        }, RETRY_FETCH_NOTIFICATIONS);

        notifications.forEach((notification) => {
            if (notification.type === "TENANT_DELETION_FAILED") {
                updateTenantDeletionStatus(JSON.parse(notification.data).tenant_uuid, abortControllers);
            }
        });

        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
            clearTimeout(notificationTimer);
        };
    }, [notifications]);

    return (
        <>
            <div
                className={classNames(
                    style.navigationPanelFooter,
                    {
                        [style.minimized]: props.isMinimized,
                    },
                    props.className
                )}
            >
                <ToggleablePanel
                    title={username}
                    testId={testIds.navigation.userMenu.button}
                    menuType={"HEADER"}
                    icon={
                        <div className={style.icon}>
                            {getInitials(usernameFormattedForInitials ? usernameFormattedForInitials : username)}
                        </div>
                    }
                    buttonClass={classNames(style.contentColumn, style.userNameWithIcon)}
                    hideChevron
                >
                    <UserMenu className={style.userMenu} />
                </ToggleablePanel>

                <div className={classNames(style.contentColumn, style.darkModeButton)}>
                    <DarkModeSwitch />
                </div>

                <div className={classNames(style.contentColumn, style.notificationButtonContainer)}>
                    {!hasTenantCookie() ? (
                        <div
                            ref={ref}
                            onBlur={(event) => {
                                if (event.relatedTarget == null) {
                                    return;
                                }
                                if (!event.currentTarget.contains(event.relatedTarget)) {
                                    setNotificationVisibility(false);
                                }
                            }}
                            onKeyDown={(event) => {
                                if (notificationVisibility && event.key === "Escape") {
                                    setNotificationVisibility(false);
                                }
                            }}
                        >
                            <Tooltip content={t("AltText.notification")}>
                                <button
                                    data-testid={testIds.navigation.notificationMenu.button}
                                    onClick={() => {
                                        if (notificationVisibility) {
                                            setNotificationVisibility(false);
                                        } else {
                                            setNotificationVisibility(true);
                                            notificationService.setSeenNotifications(notifications);
                                        }
                                    }}
                                    className={classNames(headerStyle.button, style.notificationButton)}
                                >
                                    {showBadge && notifications.length > 0 ? createNotificationBadge() : null}
                                    <Bell fillColor={theme.iconFillColor} />
                                </button>
                            </Tooltip>
                            {notificationVisibility ? (
                                <NotificationMenu
                                    onDelete={setNotifications}
                                    notifications={notifications}
                                    showImportReportDialog={setImportNotificationData}
                                    showExportReport={setReportExportJobId}
                                    showDeleteReportDialog={setReportDeleteNotificationData}
                                    className={style.notificationMenu}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            {reportImportResult}
            {reportDeletionResult}
            <Modal
                isOpen={reportExportJobId !== ""}
                hideModal={() => setReportExportJobId("")}
                modalTitle={getTitle(notificationData?.type)}
                action={
                    reportDialogRefreshIconVisibility && (
                        <Tooltip content={t("Notification.reportNotifications.refreshTableIconTooltip")}>
                            <span
                                className={headerStyle.reportExportResultRefreshIcon}
                                onClick={() => {
                                    setReportExportDialogRefreshCount((prev) => prev + 1);
                                }}
                            >
                                <RefreshButtonIcon color={theme.iconFillColor} />
                            </span>
                        </Tooltip>
                    )
                }
            >
                <div className={headerStyle.fixedWidthModal}>
                    {reportExportJobId && (
                        <NotificationDetails
                            refreshCount={reportExportDialogRefreshCount}
                            jobId={reportExportJobId}
                            setRefreshIcon={setRefreshIconVisibility}
                            jobDetail={notificationData}
                        />
                    )}
                </div>
            </Modal>
        </>
    );
};
