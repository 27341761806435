import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import bmpSetupStyle from "components/home/bmp-setup.scss";
import style from "components/home/current-plan/current-plan.scss";
import LinkIcon from "components/icons/LinkIcon";
import { CorporateBundle, ProcessorBundle, SmbBundle } from "components/licenses/bundles";
import bundleStyles from "components/tenants/pricing-tier-view.scss";
import Heading from "components/typography/heading/Heading";
import { CombinedTier, OldLicensingModelTier } from "domain/tenants";
import { generateTenantTypeToTranslationMap, generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { TenantDetails } from "store/tenantDetails";
import { useTheme } from "utils/useTheme";

export interface Props {
    tenantData: TenantDetails;
}

const CurrentPlan = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();

    const bundleToColorMap = new Map<CombinedTier, string>([
        [OldLicensingModelTier.BASIC, bundleStyles.tierFirstLevelColor],
        [OldLicensingModelTier.STANDARD, bundleStyles.tierSecondLevelColor],
        [OldLicensingModelTier.ADVANCED, bundleStyles.tierThirdLevelColor],
        [CorporateBundle.ESSENTIALS, bundleStyles.tierFirstLevelColor],
        [CorporateBundle.POWER, bundleStyles.tierSecondLevelColor],
        [CorporateBundle.ENTERPRISE, bundleStyles.tierThirdLevelColor],
        [ProcessorBundle.CORE, bundleStyles.tierFirstLevelColor],
        [ProcessorBundle.PRO, bundleStyles.tierSecondLevelColor],
        [ProcessorBundle.PRO_SCALE, bundleStyles.tierThirdLevelColor],
        [SmbBundle.SELECT_50, bundleStyles.tierFirstLevelColor],
        [SmbBundle.SELECT_100, bundleStyles.tierFirstLevelColor],
        [SmbBundle.SELECT_PLUS_250, bundleStyles.tierFirstLevelColor],
        [SmbBundle.SELECT_PLUS_500, bundleStyles.tierFirstLevelColor],
    ]);

    const getCurrentPlanStyle = (bundle: CombinedTier): string => {
        return bundleToColorMap.get(bundle) || "";
    };

    return (
        <>
            <div className={classNames(style.currentPlan, getCurrentPlanStyle(props.tenantData.tenantTier))}>
                <div>
                    <div className={style.planLabel}>{t("Onboarding.home.currentPlan")}</div>
                    <Heading tag="div" variant="SUBTITLE_2" className={style.tierTypeLabel}>
                        {props.tenantData
                            ? `${generateTenantTypeToTranslationMap(t).get(props.tenantData.type)}:
                             ${generateTierToTranslationMap(t).get(props.tenantData?.tenantTier)}`
                            : ""}
                    </Heading>
                </div>
                <div>
                    <button
                        className={bmpSetupStyle.linkText}
                        onClick={() => window.open("https://www.blancco.com/blancco-cost-bundles-pricing/", "_blank")}
                    >
                        <strong>{t("Onboarding.home.bundlesView")}</strong>
                        <span className={style.iconPadding}>
                            <LinkIcon fillColor={theme.iconFillColor} />
                        </span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default CurrentPlan;
