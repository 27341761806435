import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { useOutsideClick } from "components/header/MenuPanel";
import style from "components/home/bde-home-page/getting-started.scss";
import product from "components/home/product-card/product-card.scss";
import { createLicenseTypes, FEATURE_WORKFLOW_BDE, isCommonLicense } from "components/licenses/common";
import { LicenseData } from "domain/licenses";
import { SoftwarePackage } from "domain/softwarePackages";
import { TIER_TO_LICENSES_MAP } from "domain/tierRelatedMaps";
import { getUserTier, isLicensingModelBundleWithToken } from "services/tenants/tenantCookieService";
import { StoreState } from "store";

import download from "assets/images/icons/product-icons/download.svg";

interface Props {
    titleKey: string;
    descriptionKey: string;
    onDownload: () => void;
    handleDropdown?: boolean;
    handleIsoDropdown?: boolean;
    fetchedSoftwarePackages?: SoftwarePackage[];
    fetchedLicenses?: LicenseData[];
    featureWorkflowLicensePresent?: boolean;
}

interface NestedDictionary {
    [key: string]: {
        [key: string]: string;
    };
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const ProductCard = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const VERSION_TO_URL_MAPPING: Map<string | undefined, string> = new Map();
    props.fetchedSoftwarePackages?.map((product) => {
        if (
            product.productName === t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationTool")
        ) {
            return product.versions.map((toolVersion) => {
                VERSION_TO_URL_MAPPING.set(toolVersion.version.split(" ").pop(), toolVersion.downloadUrl);
            });
        }
        return null;
    });

    const [expanded, setExpanded] = React.useState<boolean>(false);

    const handleClickOutside = () => {
        setExpanded(false);
    };
    const versionUrlRef = useOutsideClick(handleClickOutside);

    const ONBOARDING_URL_MAP: NestedDictionary = {
        "20": {
            manual: "https://download.blancco.com/products/onboarding/20/manual/ob.iso",
            workflow: "https://download.blancco.com/products/onboarding/20/workflow/ob.iso",
        },
        "36": {
            manual: "https://download.blancco.com/products/onboarding/36/manual/ob.iso",
            workflow: "https://download.blancco.com/products/onboarding/36/workflow/ob.iso",
        },
        "50": {
            manual: "https://download.blancco.com/products/onboarding/50/manual/ob.iso",
            workflow: "https://download.blancco.com/products/onboarding/50/workflow/ob.iso",
        },
    };

    const isoFiles = new Map();
    const buttonRef = React.useRef(null);
    const productType = new Map();
    const extractProductWithinNewModel = () => {
        const licenseTypes = createLicenseTypes(false);
        const userTier = getUserTier();
        const tierLicenses = TIER_TO_LICENSES_MAP.get(userTier);
        tierLicenses &&
            ["20", "36", "50"].some((type) => {
                if (tierLicenses?.includes(type)) {
                    licenseTypes.find((each) => {
                        if (each.productId === type) {
                            productType.set(type, each.productName);
                            return fetchDownloadUrl(tierLicenses?.includes(FEATURE_WORKFLOW_BDE));
                        }
                    });
                }
            });
    };

    const extractCorrectProduct = () => {
        props.fetchedLicenses &&
            props.fetchedLicenses.map((each) => {
                if (isCommonLicense(each.type) && isLicensingModelBundleWithToken()) {
                    return extractProductWithinNewModel();
                }
                if (each.type === "20" || each.type === "36" || each.type === "50") {
                    productType.set(each.type, each.license);
                }
            });
        fetchDownloadUrl(props.featureWorkflowLicensePresent as boolean);
    };

    const fetchDownloadUrl = (featureWorkflowLicensePresent: boolean) => {
        productType.forEach((keys, values) => {
            const productConfig = ONBOARDING_URL_MAP[values];
            featureWorkflowLicensePresent
                ? isoFiles.set(keys, productConfig["workflow"])
                : isoFiles.set(keys, productConfig["manual"]);
        });
        return isoFiles;
    };

    const onWindowsClick = () => {
        window.location.href = VERSION_TO_URL_MAPPING.get("Windows") as string;
    };
    const onLinuxClick = () => {
        window.location.href = VERSION_TO_URL_MAPPING.get("Linux") as string;
    };
    const isoClick = (value: string) => {
        window.location.href = value;
    };

    return (
        <>
            {extractCorrectProduct()}
            <div className={style.productCard}>
                <div className={product.productCardWrapper} ref={versionUrlRef}>
                    <strong>{t(props.titleKey)}</strong>
                    <div
                        className={style.buttonWrapper}
                        onClick={() => {
                            setExpanded((prev) => !prev);
                        }}
                    >
                        {isoFiles.size === 1 ? (
                            <button
                                onClick={() => {
                                    window.location.href = isoFiles.values().next().value;
                                }}
                            >
                                <img src={download} alt="download" />
                            </button>
                        ) : (
                            <button
                                ref={buttonRef}
                                onClick={() => {
                                    props.onDownload();
                                }}
                                aria-expanded={expanded}
                            >
                                <img src={download} alt="download" />
                            </button>
                        )}
                    </div>
                    {expanded && props.handleDropdown && (
                        <div className={style.version}>
                            <div>
                                <button className={style.versionLink} onClick={onWindowsClick}>
                                    {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.windows")}
                                </button>
                            </div>
                            <div>
                                <button className={style.versionLink} onClick={onLinuxClick}>
                                    {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.linux")}
                                </button>
                            </div>
                        </div>
                    )}

                    {expanded && props.handleIsoDropdown && (
                        <div className={style.isoFile}>
                            {Array.from(isoFiles).map(([key, value]) => {
                                if (isoFiles.size !== 1) {
                                    return (
                                        <div key={key}>
                                            <button
                                                className={style.versionLink}
                                                onClick={() => isoClick(value as string)}
                                            >
                                                {key}
                                            </button>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>
                <div className={product.description}>{t(props.descriptionKey)}</div>
            </div>
        </>
    );
};

export default connector(ProductCard);
