import * as React from "react";
import { useTranslation } from "react-i18next";

import EditMcConfiguration from "./EditMcConfiguration";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { hybridMcService, SyncInterval } from "services/hybrid-mc/HybridMcService";
import { logger } from "utils/logging";

export interface Props {
    hide: () => void;
    tenantUuid: string;
}

export default function EditMcConfigurationLoader(props: Props): JSX.Element {
    const [isPending, setIsPending] = React.useState(true);
    const [intervals, setIntervals] = React.useState<SyncInterval | undefined>();
    const [errorMessage, setErrorMessage] = React.useState("");
    const { t } = useTranslation();

    React.useEffect(() => {
        let ignore = false;
        const abortController = new AbortController();
        hybridMcService
            .fetchIntervals(abortController, props.tenantUuid)
            .then((result) => {
                if (!ignore) {
                    setIntervals(result);
                }
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    logger.error("Failed to fetch sync intervals.", error);
                    setErrorMessage(t("Common.errorHasOccurredMessage"));
                }
            })
            .finally(() => setIsPending(false));
        return () => {
            ignore = true;
            abortController.abort();
        };
    }, []);

    if (errorMessage !== "") {
        return (
            <Modal
                isOpen={true}
                hideModal={() => props.hide()}
                modalTitle={t("EditCustomerView.mcManagementModal.title")}
            >
                <div>{errorMessage}</div>
            </Modal>
        );
    }
    if (isPending) {
        return (
            <Modal isOpen={true} hideModal={() => props.hide()} modalTitle={t("Common.pleaseWait")}>
                <LoadingIndicator />
            </Modal>
        );
    }
    if (intervals == null) {
        logger.error("Intervals shouldn't be null on this line.", intervals);
        throw new Error("Null intervals.");
    }
    return <EditMcConfiguration syncInterval={intervals} hide={() => props.hide()} tenantUuid={props.tenantUuid} />;
}
