import * as React from "react";

interface Props {
    color?: string;
}

export const ManualIcon = React.forwardRef<SVGSVGElement, Props>(
    (props, ref): JSX.Element => (
        <svg ref={ref} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 23.0005H24.5952C24.8188 23.0005 25 22.8193 25 22.5958V11.4053C25 11.1817 24.8188 11.0005 24.5952 11.0005H23M16 23.0005H7.40476C7.18122 23.0005 7 22.8193 7 22.5958V11.4049C7 11.1813 7.18128 11 7.40487 11.0001L9 11.0005M16 23.0005V12.1111M16 23.0005C16 22.3817 15.7788 21.7877 15.3849 21.3501C14.9911 20.9125 14.457 20.6667 13.9 20.6667H9V9H13.2C13.9426 9 14.6548 9.32778 15.1799 9.91122C15.705 10.4947 16 11.286 16 12.1111M16 23.0005C16 22.3817 16.2212 21.7877 16.6151 21.3501C17.0089 20.9125 17.543 20.6667 18.1 20.6667H23V9H18.8C18.0574 9 17.3452 9.32778 16.8201 9.91122C16.295 10.4947 16 11.286 16 12.1111"
                stroke={props.color}
                strokeWidth="1.5"
                strokeLinejoin="round"
            />
        </svg>
    )
);
