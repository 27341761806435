import * as React from "react";
import { useTranslation } from "react-i18next";

import AddLicenseAlerts from "./AddLicenseAlerts";
import LicenseAlertsTable, { AlertDetails } from "./LicenseAlertsTable";
import ManageLicenseAlertView from "./manage-license-alerts/ManageLicenseAlertView";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { LICENSES_ROUTE } from "components/router/Routes";
import { AUTH_LICENSE_VIEW, AUTH_TENANT_VIEW } from "domain/authority";
import { Tenant } from "domain/tenants";
import { LicenseResponse, licenseService } from "services/licenses/LicenseService";
import { tenantService } from "services/tenants/TenantService";
import { userSessionService } from "services/user/UserSessionService";

export default function LicenseAlertsView(): JSX.Element {
    const { t } = useTranslation();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [licensesCount, setLicensesCount] = React.useState<boolean>(false);
    const [show, setShow] = React.useState<boolean>(false);
    const [loadingOnFetch, setLoadingOnFetch] = React.useState(false);
    const [state, setState] = React.useState({ count: 0 });
    const viewActions = <AddLicenseAlerts showForm={setShow} loading={setLoadingOnFetch} />;
    const [edit, setEdit] = React.useState<boolean>(false);
    const [tenantName, setTenantName] = React.useState<string>("");
    const [tenantUuid, setTenantUuid] = React.useState<string>("");
    const onLicenseAlertUpdate = () => {
        return setState((prevState) => ({ count: prevState.count + 1 }));
    };
    const [tenantDetails, setTenantDetails] = React.useState<Tenant[]>([]);
    const abortController = new AbortController();
    const details = userSessionService.currentUserDetails();
    const tenantManagementAllowed =
        userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
        userSessionService.userHasAllAuthorities([AUTH_TENANT_VIEW]);
    const fetchTenants = async () => {
        abortControllers.push(abortController);
        if (tenantManagementAllowed) {
            const tenantsPromise = tenantService.fetchTenants(abortController, null, undefined, undefined, "all");
            await Promise.all([tenantsPromise]).then(([tenants]) => {
                setTenantDetails(tenants.tenantList.tenantList);
                setTenantName(details?.tenantName as string);
            });
        } else {
            setTenantName(details?.tenantName as string);
            setTenantUuid(details?.tenantUuid as string);
        }
    };
    React.useEffect(() => {
        fetchTenants();
        fetchTenantsLicenses();
    }, []);

    const [alertDetails, setAlertDetails] = React.useState<AlertDetails>({
        alertUuid: "",
        alertName: "",
        tenantName: "",
        tenantUuid: "",
        availableLicenses: [],
        expirationDate: "",
        poolName: "",
        poolUuid: "",
    });

    const fetchTenantsLicenses = () => {
        const tenantUuid = userSessionService.currentUserDetails()?.tenantUuid as string;
        let licensesFetchPromise: Promise<LicenseResponse>;

        if (!userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW])) {
            licensesFetchPromise = licenseService.fetchLicenses(abortController, undefined, true);
        } else {
            licensesFetchPromise = licenseService.fetchLicenses(
                abortController,
                undefined,
                false,
                tenantUuid == userSessionService.currentUserDetails()?.tenantUuid ? "" : tenantUuid,
                undefined
            );
        }

        licensesFetchPromise.then((data) => {
            setLicensesCount(data.licenses.length !== 0);
        });
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    return (
        <ApplicationLayout
            viewTitle={!show ? t("LicenseAlerts.title") : undefined}
            view={
                !show ? (
                    <LicenseAlertsTable
                        onLicenseAlertDelete={onLicenseAlertUpdate}
                        onLicenseAlertEdit={onLicenseAlertUpdate}
                        count={state.count}
                        setEditView={setEdit}
                        alertDetails={setAlertDetails}
                        showForm={setShow}
                    />
                ) : loadingOnFetch ? (
                    <LoadingIndicator />
                ) : (
                    <ManageLicenseAlertView
                        hideDialogView={setShow}
                        initialTenantData={tenantManagementAllowed ? tenantDetails : undefined}
                        setEditView={setEdit}
                        edit={edit}
                        alertData={alertDetails}
                        tenantName={tenantName}
                        tenantUuid={tenantUuid}
                    />
                )
            }
            mainRoute={LICENSES_ROUTE}
            viewActions={!show && (licensesCount || tenantDetails.length > 0) ? viewActions : undefined}
        />
    );
}
