import * as React from "react";

const InactiveIcon = (props: { color: string; step: number }): JSX.Element => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="20" height="20" rx="10" stroke={props.color} strokeWidth="1.5" strokeDasharray="5 5" />
        <path fill={props.color} />
        <text x="50%" y="50%" textAnchor="middle" dy=".3em" fontSize="12" fill={props.color}>
            {props.step}
        </text>
    </svg>
);

export default InactiveIcon;
