import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { GettingStartedLayout } from "components/home/GettingStartedLayout";
import { LicenseData } from "domain/licenses";
import { OnboardingProductType } from "domain/onboardings";
import { SoftwarePackage, Versions } from "domain/softwarePackages";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    fetchedSoftwarePackages?: SoftwarePackage[];
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
    from?: string;
}
export const GettingStarted = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <>
            <GettingStartedLayout
                product={OnboardingProductType.BDE}
                visible={true}
                footerVisibility={false}
                extraHeaderText={t("Onboarding.gettingStartedFlow.blanccoDriveErasureProduct")}
                title={t("Onboarding.gettingStartedFlow.title")}
                description={t("Onboarding.gettingStartedFlow.flowDescription")}
                licenses={props.licenses}
                fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                fetchedLicenses={props.fetchedLicenses}
                fromPage={props.from}
            />
        </>
    );
};

export default connector(GettingStarted);
