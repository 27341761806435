import classNames from "classnames";
import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import style from "components/tenants/add-tenant/add-tenant.scss";
import Tooltip from "components/tooltip/Tooltip";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface DrawbackPreventionData {
    handleChange: (value: boolean) => void;
    drawbackPrevention: boolean;
    miniLoader?: boolean;
    setMiniLoader?: (value: boolean) => void;
    theme: typeof defaultColor | typeof tenantColor;
}

export const DrawbackPrevention = (drawbackPreventionData: DrawbackPreventionData) => {
    const { t } = useTranslation();
    const loader = drawbackPreventionData.miniLoader && (
        <div className={style.loaderContainer}>
            <LoadingIndicator small={true} />
        </div>
    );

    return (
        <div className={classNames(form.formFields, style.defaultValueCheckbox)}>
            {loader}
            <label htmlFor="drawbackPrevention" className={classNames(form.label)}>
                {t("EditCustomerView.preventLicenseDrawback")}
            </label>
            <label className={classNames(form.container)}>
                <Field
                    type="checkbox"
                    id="drawbackPrevention"
                    className={classNames(form.input)}
                    checked={drawbackPreventionData.drawbackPrevention}
                    data-testid={testIds.workArea.tenant.manageTenantDialog.drawback.drawbackPreventionCheckbox}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        drawbackPreventionData.handleChange(e.target.checked)
                    }
                />
                <span className={form.checkmark} />
            </label>
            <Tooltip
                content={<div className={style.tooltipOpen}>{t("AddCustomerForm.licenseWithdrawalTooltip")}</div>}
                placement={"top"}
            >
                <div className={style.info}>
                    <Info
                        borderColor={drawbackPreventionData.theme.contentBackgroundColor}
                        color={drawbackPreventionData.theme.iconFillColor}
                    />
                </div>
            </Tooltip>
        </div>
    );
};
