import { useTranslation } from "react-i18next";

import { ProductMappingItem } from "domain/licenses";

const LAST_COMPLETED_STEP_INDEX_WITH_IBR = 4;
const LAST_COMPLETED_STEP_INDEX_WITHOUT_IBR = 3;

export enum BmdeOnboardingSteps {
    STEP_WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
    STEP_BOOTING_USB_DRIVE = "USB_DRIVE_CREATION",
    STEP_INSTALLING_BMDE = "INSTALLING_BMDE",
    STEP_RUNNING_ERASURE = "RUNNING_ERASURE",
    STEP_VIEWING_REPORT = "VIEWING_REPORT",
}

export enum BdeOnboardingSteps {
    STEP_WORKFLOW_TEMPLATE = "WORKFLOW_TEMPLATE",
    STEP_PRE_CONFIGURATION = "PRE_CONFIGURATION",
    STEP_BOOTING_USB_DRIVE = "USB_DRIVE_CREATION",
    STEP_RUNNING_ERASURE = "RUNNING_ERASURE",
    STEP_VIEWING_REPORT = "VIEWING_REPORT",
}

export const BMDE_ONBOARDING_STEPS = Object.values(BmdeOnboardingSteps);

export const BDE_ONBOARDING_STEPS = Object.values(BdeOnboardingSteps);

export function isStepCompleted(focusedStepIndex: number, lastCompletedStepIndex: number): boolean {
    return focusedStepIndex <= lastCompletedStepIndex;
}

export function getStepIndexForBde(step: BdeOnboardingSteps | undefined, hasIbr?: boolean) {
    if (!step) {
        return -1;
    }
    const availableSteps = hasIbr
        ? BDE_ONBOARDING_STEPS
        : BDE_ONBOARDING_STEPS.filter((s) => s !== BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE);

    return availableSteps.indexOf(step);
}

export function getStepIndexForBmde(step: BmdeOnboardingSteps | undefined, hasIbr: boolean) {
    if (!step) {
        return -1;
    }

    const availableSteps = hasIbr
        ? BMDE_ONBOARDING_STEPS
        : BMDE_ONBOARDING_STEPS.filter((s) => s !== BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE);

    return availableSteps.indexOf(step);
}

export function getActiveStep(lastCompletedStepIndex: number): number {
    return lastCompletedStepIndex + 1;
}

export function isStepActive(lastCompletedStepIndex: number, currentStep: number): boolean {
    const activeStep = getActiveStep(lastCompletedStepIndex);
    return activeStep === currentStep;
}

export const mapCompletedStepToBmdeOnboardingStep = (lastCompletedStep: string, hasIbr: boolean) => {
    const steps = Object.values(BmdeOnboardingSteps);
    const availableSteps = hasIbr ? steps : steps.filter((step) => step !== BmdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE);
    return availableSteps.find((step) => step === lastCompletedStep);
};

export const mapCompletedStepToBdeOnboardingStep = (lastCompletedStep: string, hasIbr: boolean) => {
    const steps = Object.values(BdeOnboardingSteps);
    const availableSteps = hasIbr ? steps : steps.filter((step) => step != BdeOnboardingSteps.STEP_WORKFLOW_TEMPLATE);
    return availableSteps.find((step) => step === lastCompletedStep);
};

export enum OnboardingProductType {
    BDE = "BDE",
    BMDE = "BMDE",
}

export const OnboardingProductNames: Record<OnboardingProductType, string> = {
    [OnboardingProductType.BDE]: "Blancco Drive Eraser",
    [OnboardingProductType.BMDE]: "Mobile Diagnostics and Erasure",
};

export const SUB_STEPS_INSTALL_BMDE = [
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.prepareYourPC",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.plugInBootableUSB",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.turnOnOrReboot",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickPermanent",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.clickAutomaticPartitioning",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setAdminPassword",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.synchronize",
    "Onboarding.bmde.gettingStarted.steps.installBMDE.steps.setupWifiConfiguration",
];

export function checkIfStepIsClickable(stepIndex: number, lastCompletedStepIndex: number, hasIbr: boolean) {
    return (
        stepIndex <= lastCompletedStepIndex ||
        stepIndex === getActiveStep(lastCompletedStepIndex) ||
        lastCompletedStepIndex === (hasIbr ? LAST_COMPLETED_STEP_INDEX_WITH_IBR : LAST_COMPLETED_STEP_INDEX_WITHOUT_IBR)
    );
}

export function displayProgressBarProgress(lastCompletedStepIndex: number, previousProgress: number, hasIbr: boolean) {
    const firstIncrement = hasIbr ? 10 : 15;
    const otherIncrements = hasIbr ? 20 : 25;
    if (lastCompletedStepIndex === -1) {
        return previousProgress;
    } else if (lastCompletedStepIndex === 0) {
        return previousProgress + firstIncrement;
    } else {
        return previousProgress + otherIncrements;
    }
}

export function checkIfMenuExpanded(lastCompletedStepIndex: number, currentStepIndex: number, hasIbr: boolean) {
    if (
        lastCompletedStepIndex === -1 ||
        lastCompletedStepIndex === (hasIbr ? LAST_COMPLETED_STEP_INDEX_WITH_IBR : LAST_COMPLETED_STEP_INDEX_WITHOUT_IBR)
    ) {
        return false;
    }
    return (
        lastCompletedStepIndex !== -1 &&
        currentStepIndex > lastCompletedStepIndex &&
        isStepActive(lastCompletedStepIndex, currentStepIndex)
    );
}

export function getInitialProgressPercentage(stepIndex: number, hasIbr: boolean) {
    const progressValues = hasIbr ? [20, 40, 60, 80, 100] : [25, 50, 75, 100];

    return progressValues[stepIndex] ?? 10;
}

export function getHomepageProductsMappingList(): ProductMappingItem[] {
    const { t } = useTranslation();
    return [
        {
            productName: "Blancco Drive Eraser",
            description: t("Onboarding.home.products.blanccoDriveErasure"),
            onboarding: "BDE",
        },
        {
            productName: "Blancco Drive Eraser for Mac",
            description: t("Onboarding.home.products.blanccoDriveErasureForMac"),
        },
        {
            productName: "Blancco Drive Eraser for ARM",
            description: t("Onboarding.home.products.blanccoDriveErasureForArm"),
        },
        {
            productName: "Blancco Drive Verifier",
            description: t("Onboarding.home.products.blanccoDriveVerifier"),
            onboarding: "AUTOMATION",
            onboardingDescription: t("Onboarding.home.onboardingDescriptions.blanccoDriveVerifier"),
        },
        {
            productName: "Blancco Mobile Diagnostics and Erasure",
            description: t("Onboarding.home.products.blanccoMobileDiagnosticsAndErasure"),
            onboarding: "BMDE",
        },
        {
            productName: "Blancco Eraser for Apple Devices",
            description: t("Onboarding.home.products.blanccoEraserForAppleDevices"),
            onboarding: "AUTOMATION",
            onboardingDescription: t("Onboarding.home.onboardingDescriptions.blanccoEraserForAppleDevices"),
        },
        {
            productName: "Blancco Removable Media Eraser",
            description: t("Onboarding.home.products.blanccoRemovableMediaEraser"),
        },
        {
            productName: "Blancco WinEraser",
            description: t("Onboarding.home.products.blanccoWinEraser"),
        },
        {
            productName: "Blancco WinEraser for ARM",
            description: t("Onboarding.home.products.blanccoWinEraserForARM"),
        },
        {
            productName: "Blancco LUN Eraser",
            description: t("Onboarding.home.products.blanccoLunEraser"),
        },
        {
            productName: "Blancco File Eraser",
            description: t("Onboarding.home.products.blanccoFileEraser"),
        },
        {
            productName: "Blancco Virtual Machine Eraser",
            description: t("Onboarding.home.products.blanccoVirtualMachineEraser"),
        },
    ];
}
