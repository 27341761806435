import classNames from "classnames";
import { TFunction } from "i18next";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { SuccessIcon } from "components/icons/SuccessIcon";
import layoutStyle from "components/layout/layout.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import style from "components/tenants/edit-mc-configuration.scss";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { hybridMcService, SyncInterval } from "services/hybrid-mc/HybridMcService";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import formStyle from "styles/form.scss";
import layoutStyles from "styles/layout.scss";
import { logger } from "utils/logging";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Props {
    hide: () => void;
    syncInterval: SyncInterval;
    tenantUuid: string;
}

export default function EditMcConfiguration(props: Props): JSX.Element {
    const { t } = useTranslation();
    const theme = useTheme();

    const [isPending, setIsPending] = React.useState(false);
    const [selectedDesired, setSelectedDesired] = React.useState<number>(
        props.syncInterval.selectedDesiredInterval ?? props.syncInterval.desiredIntervals[0]
    );
    const [selectedDeadline, setSelectedDeadline] = React.useState<number>(
        props.syncInterval.selectedDeadlineInterval ?? props.syncInterval.deadlineIntervals[0]
    );
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        if (!isPending) {
            return;
        }
        const abortController = new AbortController();
        hybridMcService
            .updateIntervals(abortController, props.tenantUuid, selectedDesired, selectedDeadline)
            .then(() => {
                setErrorMessage("");
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("Common.resultSuccessTitle")}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
                props.hide();
            })
            .catch((error) => {
                if (!abortController.signal.aborted) {
                    logger.error("Failed to fetch sync intervals.", error);
                    setErrorMessage(t("Common.errorHasOccurredMessage") + " " + SUPPORT_EMAIL);
                }
            })
            .finally(() => setIsPending(false));
        return () => {
            abortController.abort();
        };
    }, [isPending]);

    const submitDisabled =
        selectedDesired === props.syncInterval.selectedDesiredInterval &&
        selectedDeadline === props.syncInterval.selectedDeadlineInterval;

    return (
        <Modal isOpen={true} hideModal={() => props.hide()} modalTitle={t("EditCustomerView.mcManagementModal.title")}>
            {isPending ? (
                <LoadingIndicator />
            ) : (
                <>
                    <div className={classNames(form.formFields, style.selectGap)}>
                        <ConfigurationItem
                            inputId="desiredSelect"
                            intervals={props.syncInterval.desiredIntervals}
                            label={t("EditCustomerView.mcManagementModal.desiredLabel")}
                            intervalPrefix={t("EditCustomerView.mcManagementModal.desiredPrefix")}
                            value={selectedDesired}
                            setter={setSelectedDesired}
                            testId={testIds.workArea.tenant.editMcConfigurationDialog.desiredIntervalSelect}
                        />
                        <ConfigurationItem
                            inputId="deadlineSelect"
                            intervals={props.syncInterval.deadlineIntervals}
                            label={t("EditCustomerView.mcManagementModal.deadlineLabel")}
                            intervalPrefix={t("EditCustomerView.mcManagementModal.deadlinePrefix")}
                            value={selectedDeadline}
                            setter={setSelectedDeadline}
                            testId={testIds.workArea.tenant.editMcConfigurationDialog.deadlineIntervalSelect}
                        />
                    </div>
                </>
            )}

            <div className={classNames(formStyle.error, style.errorMessageWidth)}>{errorMessage}</div>
            <div className={classNames(layoutStyles.buttonContainer, style.buttonContainerGap)}>
                <button
                    type={"submit"}
                    onClick={() => props.hide()}
                    data-testid={testIds.common.dialog.closeButton}
                    className={buttons.secondaryButtonWithoutIcon}
                >
                    {t("Common.cancel")}
                </button>
                <button
                    type={"submit"}
                    disabled={submitDisabled}
                    onClick={() => {
                        setIsPending(true);
                    }}
                    data-testid={testIds.common.dialog.primaryButton}
                    className={buttons.primaryButtonWithoutIcon}
                >
                    {t("Common.save")}
                </button>
            </div>
        </Modal>
    );
}

function ConfigurationItem(props: {
    inputId: string;
    label: string;
    intervals: number[];
    intervalPrefix: string;
    value: number;
    setter: (value: number) => void;
    testId: string;
}): JSX.Element {
    const { t } = useTranslation();
    return (
        <div>
            <label htmlFor={props.inputId} className={form.label}>
                {props.label}
            </label>
            <select
                id={props.inputId}
                className={classNames(form.select, form.fixedWidthInput)}
                name={props.inputId}
                value={props.value}
                onChange={(event) => {
                    props.setter(Number(event.target.value));
                }}
                data-testid={props.testId}
            >
                {props.intervals.map((value) => {
                    return (
                        <option key={value} value={value}>
                            {createIntervalLabel(value, props.intervalPrefix, t)}
                        </option>
                    );
                })}
            </select>
        </div>
    );
}

function createIntervalLabel(seconds: number, prefix: string, t: TFunction): string {
    const duration = deriveDuration(seconds);
    const pieces: string[] = [];
    const pairs: [number | undefined, string][] = [
        [duration.years, "EditCustomerView.mcManagementModal.duration.years"],
        [duration.months, "EditCustomerView.mcManagementModal.duration.months"],
        [duration.weeks, "EditCustomerView.mcManagementModal.duration.weeks"],
        [duration.days, "EditCustomerView.mcManagementModal.duration.days"],
        [duration.hours, "EditCustomerView.mcManagementModal.duration.hours"],
        [duration.minutes, "EditCustomerView.mcManagementModal.duration.minutes"],
        [duration.seconds, "EditCustomerView.mcManagementModal.duration.seconds"],
    ];
    pairs.forEach(([count, localizationKey]) => {
        if (count != null) {
            pieces.push(t(localizationKey, { count }));
        }
    });
    const joined = pieces.join(", ");
    return `${prefix} ${joined}`;
}

export interface Duration {
    seconds?: number;
    minutes?: number;
    hours?: number;
    days?: number;
    weeks?: number;
    months?: number;
    years?: number;
}

export function deriveDuration(seconds: number): Duration {
    const duration: Duration = {};
    const day = 60 * 60 * 24;

    const year = day * 30 * 12;
    const years = Math.floor(seconds / year);
    if (years > 0) {
        seconds -= year * years;
        duration.years = years;
    }

    const month = day * 30;
    const months = Math.floor(seconds / month);
    if (months > 0) {
        seconds -= month * months;
        duration.months = months;
    }

    const week = day * 7;
    const weeks = Math.floor(seconds / week);
    if (weeks > 0) {
        seconds -= week * weeks;
        duration.weeks = weeks;
    }

    const days = Math.floor(seconds / day);
    if (days > 0) {
        seconds -= day * days;
        duration.days = days;
    }

    const hour = 60 * 60;
    const hours = Math.floor(seconds / hour);
    if (hours > 0) {
        seconds -= hour * hours;
        duration.hours = hours;
    }

    const minute = 60;
    const minutes = Math.floor(seconds / minute);
    if (minutes > 0) {
        seconds -= minute * minutes;
        duration.minutes = minutes;
    }

    if (seconds > 0) {
        duration.seconds = seconds;
    }

    return duration;
}
