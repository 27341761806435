import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";

import style from "./report-paths.scss";
import ExportIcon from "components/icons/ExportIcon";
import { Action, Category, Label, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttonStyle from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

export interface Props {
    exportCsvUrl: string;
}

const ExportReportPathsView = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();

    const onReportExportClicked = () => {
        usageStatisticsService.sendEvent({
            label: Label.REPORT_PATHS,
            action: Action.EXPORT_REPORT_PATHS,
            category: Category.REPORT_PATHS,
        });
    };

    return (
        <a
            className={classNames(buttonStyle.primaryButton, buttonStyle.small, style.exportButton)}
            href={props.exportCsvUrl}
            download={"report-path.csv"}
            data-testid={testIds.workArea.reportPaths.exportButton}
            onClick={onReportExportClicked}
        >
            <ExportIcon color={theme.primaryButtonForegroundColor} />
            {t("ReportPaths.exportCsv")}
        </a>
    );
};

export default ExportReportPathsView;
