import * as React from "react";

const ExpandedStepIcon = (props: { color: string }): JSX.Element => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            stroke={props.color}
            strokeWidth="2"
        />
        <path
            d="M12 4C13.0506 4 14.0909 4.20693 15.0615 4.60896C16.0321 5.011 16.914 5.60028 17.6569 6.34315C18.3997 7.08602 18.989 7.96793 19.391 8.93853C19.7931 9.90914 20 10.9494 20 12C20 13.0506 19.7931 14.0909 19.391 15.0615C18.989 16.0321 18.3997 16.914 17.6569 17.6569C16.914 18.3997 16.0321 18.989 15.0615 19.391C14.0909 19.7931 13.0506 20 12 20L12 12L12 4Z"
            fill={props.color}
        />
    </svg>
);

export default ExpandedStepIcon;
