import * as React from "react";

interface Props {
    color: string;
    backgroundColor: string;
    opacity: string;
}

export const WireIcon = React.forwardRef<SVGSVGElement, Props>(
    (props, ref): JSX.Element => (
        <svg ref={ref} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="22" height="22" rx="4" fill={props.backgroundColor} opacity={props.opacity} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.1665 6.33317C5.1665 5.68884 5.68884 5.1665 6.33317 5.1665H8.33317C8.9775 5.1665 9.49984 5.68884 9.49984 6.33317V6.49984H14.3332C15.7139 6.49984 16.8332 7.61913 16.8332 8.99984C16.8332 10.3805 15.7139 11.4998 14.3332 11.4998H13.4998V11.6665C13.4998 12.3108 12.9775 12.8332 12.3332 12.8332H10.3332C9.68884 12.8332 9.1665 12.3108 9.1665 11.6665V11.4998H7.6665C6.83808 11.4998 6.1665 12.1714 6.1665 12.9998C6.1665 13.8283 6.83808 14.4998 7.6665 14.4998H15.1261L14.6463 14.0201C14.451 13.8248 14.451 13.5082 14.6463 13.313C14.8415 13.1177 15.1581 13.1177 15.3534 13.313L16.6867 14.6463C16.882 14.8415 16.882 15.1581 16.6867 15.3534L15.3534 16.6867C15.1581 16.882 14.8415 16.882 14.6463 16.6867C14.451 16.4915 14.451 16.1749 14.6463 15.9796L15.1261 15.4998H7.6665C6.28579 15.4998 5.1665 14.3805 5.1665 12.9998C5.1665 11.6191 6.28579 10.4998 7.6665 10.4998H9.1665V10.3332C9.1665 9.68884 9.68884 9.1665 10.3332 9.1665H12.3332C12.9775 9.1665 13.4998 9.68884 13.4998 10.3332V10.4998H14.3332C15.1616 10.4998 15.8332 9.82826 15.8332 8.99984C15.8332 8.17141 15.1616 7.49984 14.3332 7.49984H9.49984V7.6665C9.49984 8.31084 8.9775 8.83317 8.33317 8.83317H6.33317C5.68884 8.83317 5.1665 8.31084 5.1665 7.6665V6.33317ZM8.49984 6.33317C8.49984 6.24112 8.42522 6.1665 8.33317 6.1665H6.33317C6.24112 6.1665 6.1665 6.24112 6.1665 6.33317V7.6665C6.1665 7.75855 6.24112 7.83317 6.33317 7.83317H8.33317C8.42522 7.83317 8.49984 7.75855 8.49984 7.6665V6.33317ZM12.4998 10.3332C12.4998 10.2411 12.4252 10.1665 12.3332 10.1665H10.3332C10.2411 10.1665 10.1665 10.2411 10.1665 10.3332V11.6665C10.1665 11.7586 10.2411 11.8332 10.3332 11.8332H12.3332C12.4252 11.8332 12.4998 11.7586 12.4998 11.6665V10.3332Z"
                fill={props.color}
            />
        </svg>
    )
);
