import React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import menuStyle from "components/navigation/dashboard-menu.scss";
import topNavigation from "components/navigation/top-navigation.scss";
import {
    DASHBOARD_ROUTE,
    REPORTS_ROUTE,
    RouteDefinition,
    SUSTAINABILITY_DASHBOARD_ROUTE,
} from "components/router/Routes";

import testIds from "testIds.json";

interface Props {
    checkValidity: (route: RouteDefinition) => boolean;
    isActiveLink: (route: RouteDefinition[]) => boolean;
}

const ReportingMenu = (props: Props) => {
    const { t } = useTranslation();
    const childRoutes = [DASHBOARD_ROUTE, REPORTS_ROUTE, SUSTAINABILITY_DASHBOARD_ROUTE];

    return (
        <Menu className={menuStyle.menu} data-testid={testIds.navigation.reports.itself}>
            <ul>
                {childRoutes.filter(props.checkValidity).map((childRoute) => {
                    return (
                        <li
                            key={childRoute.title}
                            className={props.isActiveLink([childRoute]) ? topNavigation.current : ""}
                        >
                            {/* 
                                MenuItem's onKeyDown is set to undefined, because it calls event.preventDefault() if MenuItem is not set to be a link with the tag prop. 
                                This prevents NavLink from working correctly on Enter key press. 
                            */}
                            <MenuItem className={menuStyle.item} onKeyDown={undefined}>
                                <NavLink
                                    activeClassName={topNavigation.current}
                                    className={""}
                                    exact={true}
                                    to={childRoute.path}
                                    data-testid={childRoute.testId}
                                >
                                    {t(childRoute.title)}
                                </NavLink>
                            </MenuItem>
                        </li>
                    );
                })}
            </ul>
        </Menu>
    );
};

export default ReportingMenu;
