import ProgressBar from "@ramonak/react-progress-bar";
import * as React from "react";

import { DialogState } from "./ImportFileDialog";
import style from "components/import-file-dialog/import-file-dialog.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface ProgressBar {
    key: DialogState;
    show: boolean;
    percentage: number;
}

interface Props {
    introductionMessage?: string;
    loadingMessage: string;
    progressMessage?: string;
    progressBar?: ProgressBar;
}

const LoadingView = (props: Props): JSX.Element => {
    const theme = useTheme();
    const color = props.progressBar?.percentage === 100 ? theme.successBorderColor : theme.textColor;
    const progress = props.progressBar?.show && (
        <div
            data-testid={testIds.workArea.report.reportImportDialog.loadingView.progressBar}
            className={style.progressBar}
        >
            <ProgressBar
                key={props.progressBar.key}
                height="3px"
                bgColor={color}
                labelColor={color}
                baseBgColor={theme.contentBorderColor}
                completed={props.progressBar.percentage}
            />
        </div>
    );
    const progressPercentage = props.progressBar?.show && (
        <div
            data-testid={testIds.workArea.report.reportImportDialog.loadingView.progressLabel}
            className={style.progressInPercentage}
        >
            {props.progressBar?.percentage}%
        </div>
    );
    return (
        <>
            <div className={style.introductionLabel}>{props.introductionMessage}</div>
            <div className={style.centeredWrapper}>
                <div className={style.loadingIndicator}>
                    <LoadingIndicator />
                </div>
                <div>{props.loadingMessage}</div>
                {progressPercentage}
                <div
                    data-testid={testIds.workArea.report.reportImportDialog.loadingView.progressMessageLabel}
                    className={props.progressBar?.show ? style.progressMessage : ""}
                >
                    {props.progressMessage}
                </div>
                {progress}
            </div>
        </>
    );
};

export default LoadingView;
