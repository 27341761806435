import * as React from "react";
import { useTranslation } from "react-i18next";

import Products from "./SupportAndHelp";
import ApplicationLayout from "components/layout/ApplicationLayout";
import { SUPPORT_ROUTE } from "components/router/Routes";

export default function SupportAndHelpView(): JSX.Element {
    const { t } = useTranslation();

    return (
        <ApplicationLayout
            viewTitle={t("Common.products")}
            view={<Products />}
            viewContainer={false}
            mainRoute={SUPPORT_ROUTE}
        />
    );
}
