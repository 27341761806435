import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";

import style from "./bms-config.scss";
import { GrantType, IdentityVerificationConfiguration } from "./BmsCommonInterfaces";
import Checkbox from "./form-components/BmsCheckboxComponent";
import BmsOptionsInputComponent from "./form-components/BmsOptionsInputComponent";
import BoxConnector from "./form-components/BoxConnector";
import InputComponent from "components/licenses/license-configuration/bms/form-components/BmsTextInputComponent";

interface FormValues {
    identityVerificationConfiguration: IdentityVerificationConfiguration;
}

interface Props {
    formValues: FormValues;
    setFormValues: React.Dispatch<React.SetStateAction<FormValues>>;
}

const IdentityVerificationConfigurationForm: React.FC<Props> = ({ formValues, setFormValues }) => {
    const { t } = useTranslation();

    const grantTypeOptions = Object.values(GrantType).map((type) => ({
        label: type,
        value: type,
    }));

    const handleInputChange = (fieldName: string, value: string) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            identityVerificationConfiguration: {
                ...prevFormValues.identityVerificationConfiguration,
                [fieldName]: value,
            },
        }));
    };

    const updateFormValues = (updateFn: (formValues: FormValues) => FormValues) => {
        setFormValues((previousFormValues) => updateFn({ ...previousFormValues }));
    };

    const handleOAuthInputChange = (fieldName: string, value: string | boolean) => {
        updateFormValues((prevFormValues) => {
            const { identityVerificationConfiguration } = prevFormValues;
            const updatedOauthConfig = {
                ...identityVerificationConfiguration.oAuthConfiguration,
                [fieldName]: value,
            };

            let updatedApiConfig = identityVerificationConfiguration.apiConfiguration;

            // If "enabled" is being updated and it's a boolean, set API's enabled to false
            if (typeof value === "boolean" && value) {
                updatedApiConfig = { ...updatedApiConfig, enabled: false };
            }

            const updatedIdentifierVerificationConfig = {
                ...identityVerificationConfiguration,
                oAuthConfiguration: updatedOauthConfig,
                apiConfiguration: updatedApiConfig,
            };

            return { ...prevFormValues, identityVerificationConfiguration: updatedIdentifierVerificationConfig };
        });
    };

    const handleApiInputChange = (fieldName: string, value: string | boolean) => {
        updateFormValues((prevFormValues) => {
            const { identityVerificationConfiguration } = prevFormValues;
            const updatedApiConfig = { ...identityVerificationConfiguration.apiConfiguration, [fieldName]: value };

            let updatedOauthConfig = identityVerificationConfiguration.oAuthConfiguration;

            // If "enabled" is being updated set OAuth's enabled to false
            if (value === true) {
                updatedOauthConfig = { ...updatedOauthConfig, enabled: false };
            }

            const updatedIdentifierVerificationConfig = {
                ...identityVerificationConfiguration,
                apiConfiguration: updatedApiConfig,
                oAuthConfiguration: updatedOauthConfig,
            };

            return { ...prevFormValues, identityVerificationConfiguration: updatedIdentifierVerificationConfig };
        });
    };

    return (
        <div>
            <div className={classNames(style.formSection)}>
                <div className={classNames(style.boxed)}>
                    <Label className={classNames(style.bmsProviderHeaderLabel)}>
                        {t("Configuration.identityVerificationConfigurationForm.headers.basic")}
                    </Label>
                    <InputComponent
                        labelKey={t("Configuration.identityVerificationConfigurationForm.endpoint")}
                        inputId={"appIcon"}
                        value={formValues.identityVerificationConfiguration.endpoint}
                        onChange={(newValue) => handleInputChange("endpoint", newValue)}
                    />
                    <InputComponent
                        labelKey={t("Configuration.identityVerificationConfigurationForm.bodyFieldName")}
                        inputId={"headerLogo"}
                        value={formValues.identityVerificationConfiguration.bodyFieldName}
                        onChange={(newValue) => handleInputChange("bodyFieldName", newValue)}
                    />
                </div>
                <BoxConnector className={style.lineConnectorExtraHigh} />
                <div className={classNames(style.childDiv)}>
                    <div className={classNames(style.boxed)}>
                        <Label className={classNames(style.bmsProviderHeaderLabel)}>
                            {t("Configuration.identityVerificationConfigurationForm.headers.apiConfiguration")}
                        </Label>
                        <Checkbox
                            labelKey={t("Configuration.common.apiConfigurationEnabled")}
                            toolTipContent={t(`Configuration.tooltips.common.apiConfigurationEnabled`)}
                            checkboxId={"enabled"}
                            checked={formValues.identityVerificationConfiguration.apiConfiguration?.enabled}
                            onChange={(newValue) => handleApiInputChange("enabled", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.common.apiKeyHeaderName")}
                            toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderName`)}
                            inputId={"apiKeyHeaderName"}
                            value={formValues.identityVerificationConfiguration.apiConfiguration?.apiKeyHeaderName}
                            onChange={(newValue) => handleApiInputChange("apiKeyHeaderName", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.common.apiKeyHeaderValue")}
                            toolTipContent={t(`Configuration.tooltips.common.apiKeyHeaderValue`)}
                            inputId={"apiKeyHeaderValue"}
                            value={formValues.identityVerificationConfiguration.apiConfiguration?.apiKeyHeaderValue}
                            onChange={(newValue) => handleApiInputChange("apiKeyHeaderValue", newValue)}
                        />
                    </div>
                </div>
                <BoxConnector className={style.lineConnectorExtraHigh} />
                <div className={classNames(style.childDiv)}>
                    <div className={classNames(style.boxed)}>
                        <Label className={classNames(style.bmsProviderHeaderLabel)}>
                            {t("Configuration.identityVerificationConfigurationForm.headers.oauthConfiguration")}
                        </Label>
                        <Checkbox
                            labelKey={t("Configuration.common.oAuth.enabled")}
                            toolTipContent={t(`Configuration.tooltips.common.oAuth.enabled`)}
                            checkboxId={"enabled"}
                            checked={formValues.identityVerificationConfiguration.oAuthConfiguration?.enabled}
                            onChange={(newValue) => handleOAuthInputChange("enabled", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.common.oAuth.endpoint")}
                            toolTipContent={t(`Configuration.tooltips.common.oAuth.endpoint`)}
                            inputId={"endpoint"}
                            value={formValues.identityVerificationConfiguration.oAuthConfiguration?.endpoint}
                            onChange={(newValue) => handleOAuthInputChange("endpoint", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.common.oAuth.clientId")}
                            toolTipContent={t(`Configuration.tooltips.common.oAuth.clientId`)}
                            inputId={"client_id"}
                            value={formValues.identityVerificationConfiguration.oAuthConfiguration?.client_id}
                            onChange={(newValue) => handleOAuthInputChange("client_id", newValue)}
                        />
                        <InputComponent
                            labelKey={t("Configuration.common.oAuth.clientSecret")}
                            toolTipContent={t(`Configuration.tooltips.common.oAuth.clientSecret`)}
                            inputId={"client_secret"}
                            value={formValues.identityVerificationConfiguration.oAuthConfiguration?.client_secret}
                            onChange={(newValue) => handleOAuthInputChange("client_secret", newValue)}
                        />

                        <BmsOptionsInputComponent
                            labelKey={t("Configuration.common.oAuth.grantType")}
                            toolTipContent={t(`Configuration.tooltips.common.oAuth.grantType`)}
                            value={formValues.identityVerificationConfiguration.oAuthConfiguration?.grant_type ?? ""}
                            options={grantTypeOptions}
                            onChange={(newValue) => handleOAuthInputChange("grant_type", newValue)}
                        />

                        <InputComponent
                            labelKey={t("Configuration.common.oAuth.scope")}
                            toolTipContent={t(`Configuration.tooltips.common.oAuth.scope`)}
                            inputId={"scope"}
                            value={formValues.identityVerificationConfiguration.oAuthConfiguration?.scope}
                            onChange={(newValue) => handleOAuthInputChange("scope", newValue)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IdentityVerificationConfigurationForm;
