import React from "react";

interface Props {
    showSideNav: boolean;
    color: string;
}

// TODO BCC-3711: Remove this component when new layout and new navigation is in use.
const CollapsibleSideMenuIcon = (props: Props): JSX.Element => {
    return props.showSideNav ? (
        <svg id="icon_collapse_close" xmlns="http://www.w3.org/2000/svg" width="48" height="32" viewBox="0 0 48 32">
            <g id="Group_5052" data-name="Group 5052" transform="translate(671 406) rotate(180)">
                <path
                    id="Rectangle_2802"
                    data-name="Rectangle 2802"
                    d="M0,0H45a3,3,0,0,1,3,3V29a3,3,0,0,1-3,3H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                    transform="translate(623 374)"
                    fill={props.color}
                />
                <g
                    id="Component_135_10"
                    data-name="Component 135 – 10"
                    transform="translate(652.943 394.675) rotate(180)"
                >
                    <path
                        id="Path_1881"
                        data-name="Path 1881"
                        d="M0,0,5.887,4.675,0,9.35"
                        transform="translate(5.887 9.35) rotate(180)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Path_1882"
                        data-name="Path 1882"
                        d="M0,0,5.887,4.675,0,9.35"
                        transform="translate(11.887 9.35) rotate(180)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </svg>
    ) : (
        <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_3)">
                <path
                    d="M0 0L45 3.93402e-06C45.7956 4.00358e-06 46.5587 0.316075 47.1213 0.878684C47.6839 1.44129 48 2.20435 48 3L48 29C48 29.7957 47.6839 30.5587 47.1213 31.1213C46.5587 31.6839 45.7956 32 45 32L-2.79753e-06 32L0 0Z"
                    fill={props.color}
                />
                <path
                    d="M24.057 11.325L29.944 16L24.057 20.675"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
                <path
                    d="M18.057 11.325L23.944 16L18.057 20.675"
                    stroke="#fff"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1_3">
                    <rect width="48" height="32" fill="#fff" transform="translate(48 32) rotate(-180)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CollapsibleSideMenuIcon;
