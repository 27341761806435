import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

import style from "./my-device-health.scss";
import Info from "components/icons/Info";
import { selectLanguageForFaultCodes } from "components/licenses/license-configuration/bms/bmsUtils";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import DateCell from "components/table/DateCell";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { Configuration } from "domain/licenses";
import { DiagnosticReportResponse, NtfHistory, NtfResult } from "domain/reports";
import * as LanguageRepository from "services/language/languageRepository";
import { useTheme } from "utils/useTheme";

import checkIcon from "assets/images/icons/checkMarkInCircle.svg";
import crossIcon from "assets/images/icons/cross.svg";

import testIds from "testIds.json";

export interface NtfProps {
    diagnosticReportResponse: DiagnosticReportResponse;
    clientConfiguration: Configuration;
}

const FAULT_CODE_TO_TRANSLATION_MAP = new Map<string, string>([
    ["fault_check__fault_code_all_tests", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.allTests"],
    [
        "fault_check__fault_code_automated_tests",
        "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.automatedTests",
    ],
    ["fault_check__fault_code_camera", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.camera"],
    ["fault_check__fault_code_ports", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.ports"],
    ["fault_check__fault_code_screen", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.screen"],
    ["fault_check__fault_code_audio", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.audio"],
    ["fault_check__fault_code_device", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.device"],
    ["fault_check__fault_code_sensors", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.sensors"],
    ["fault_check__fault_code_others", "DiagnosticReportsTable.myDeviceHealth.faultCodeResults.others"],
]);

export default function MyDeviceHealthView(props: NtfProps): JSX.Element {
    const { t } = useTranslation();
    const theme = useTheme();
    const selectedLanguage = selectLanguageForFaultCodes(LanguageRepository.getLanguage().locale);

    const { isLoading } = useQuery(
        ["faultCodes", selectedLanguage],
        () => props.clientConfiguration.ntfConfiguration.fault_codes[selectedLanguage],
        {
            onSuccess: (faultCodes) => {
                faultCodes.forEach((faultCode: { key: string; text: string }) => {
                    FAULT_CODE_TO_TRANSLATION_MAP.set(faultCode.key, faultCode.text);
                });
            },
        }
    );

    const reportResponse = props.diagnosticReportResponse;
    const results = reportResponse.ntf.results;
    const otherDetails = reportResponse.ntf.otherDetails;
    const latestFaultFoundEntry = results
        .flatMap((result: NtfResult) => result.history)
        .filter((history: NtfHistory) => history.faultFound)
        .reduce((latest: NtfHistory | null, current: NtfHistory) => {
            return latest == null || new Date(current.reportDate) > new Date(latest.reportDate) ? current : latest;
        }, null);

    const faultFoundIconAndText = (
        <>
            <img src={crossIcon} alt={"AltText.diagnosticSummary.failed"} />{" "}
            {t("DiagnosticReportsTable.myDeviceHealth.faultFound")}
        </>
    );
    const faultNotFoundIconAndText = (
        <>
            <img src={checkIcon} alt={"AltText.diagnosticSummary.passed"} />{" "}
            {t("DiagnosticReportsTable.myDeviceHealth.faultNotFound")}
        </>
    );

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <div className={style.childContainer}>
                {results.map((result: NtfResult, index: number) => (
                    <div
                        key={index}
                        id={result.faultCode}
                        data-testid={
                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.categoryResults.itself
                        }
                    >
                        <Heading
                            tag="div"
                            variant="SUBTITLE_1"
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.categoryResults
                                    .headerLabel
                            }
                        >
                            {t(FAULT_CODE_TO_TRANSLATION_MAP.get(result.faultCode) || result.faultCode)}
                        </Heading>
                        {result.history.map((history: NtfHistory, historyIndex: number) => (
                            <div key={historyIndex} className={style.historyContainer}>
                                <div className={style.reportDataContainer}>
                                    <div>{t("DiagnosticReportsTable.myDeviceHealth.timeAndDate")}</div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth
                                                .categoryResults.dateLabel
                                        }
                                    >
                                        <DateCell value={history.reportDate} tooltip={true} timeFirst={true} />
                                    </div>
                                </div>
                                <div className={style.reportDataContainer}>
                                    <div>{t("DiagnosticReportsTable.myDeviceHealth.result")}</div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth
                                                .categoryResults.resultIcon
                                        }
                                    >
                                        {history.faultFound ? faultFoundIconAndText : faultNotFoundIconAndText}
                                    </div>
                                </div>
                                <div className={style.reportDataContainer}>
                                    <div>{t("DiagnosticReportsTable.myDeviceHealth.codeLabel")}</div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                                .codeLabel
                                        }
                                    >
                                        {history.referenceCode}
                                    </div>
                                </div>
                                <div className={style.reportDataContainer}>
                                    <div>{t("DiagnosticReportsTable.myDeviceHealth.codeTypeLabel")}</div>
                                    <div
                                        data-testid={
                                            testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                                .codeTypeLabel
                                        }
                                    >
                                        {history.faultFound
                                            ? t("DiagnosticReportsTable.myDeviceHealth.returnAuthorization")
                                            : t("DiagnosticReportsTable.myDeviceHealth.referenceCode")}
                                    </div>
                                </div>

                                {history.faultFound && (
                                    <div className={style.reportDataContainer}>
                                        <div>{t("DiagnosticReportsTable.myDeviceHealth.faultDescription")}</div>
                                        <div
                                            data-testid={
                                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth
                                                    .categoryResults.descriptionLabel
                                            }
                                        >
                                            {history.description}
                                        </div>
                                    </div>
                                )}
                                {historyIndex < result.history.length - 1 && <hr />}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div
                className={classNames(style.childContainer, style.rightContainer)}
                id={"rightContainer"}
                data-testid={testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.itself}
            >
                <Heading
                    tag="div"
                    variant="SUBTITLE_1"
                    data-testid={
                        testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.headerLabel
                    }
                >
                    {t("DiagnosticReportsTable.myDeviceHealth.otherDetails")}
                </Heading>

                <div>
                    <div className={style.reportDataContainer}>
                        <div>{t("DiagnosticReportsTable.myDeviceHealth.overallResultLabel")}</div>
                        <div
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                    .overallResultLabel
                            }
                        >
                            {otherDetails.faultFound ? faultFoundIconAndText : faultNotFoundIconAndText}
                        </div>
                    </div>
                    <div className={style.reportDataContainer}>
                        <div>{t("DiagnosticReportsTable.myDeviceHealth.storeLabel")}</div>
                        <div
                            data-testid={
                                testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails.storeLabel
                            }
                        >
                            {" "}
                            {otherDetails.store}
                        </div>
                    </div>
                    <>
                        <div className={style.reportDataContainer}>
                            <div>
                                {t("DiagnosticReportsTable.myDeviceHealth.codeLabel")}
                                <Tooltip
                                    content={<>{t("DiagnosticReportsTable.myDeviceHealth.summary.codeTooltip")}</>}
                                    placement={"top"}
                                >
                                    <div className={style.infoIcon}>
                                        <Info borderColor={theme.contentBackgroundColor} color={theme.iconFillColor} />
                                    </div>
                                </Tooltip>
                            </div>
                            <div
                                data-testid={
                                    testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                        .codeLabel
                                }
                            >
                                {otherDetails.faultFound
                                    ? latestFaultFoundEntry?.referenceCode
                                    : otherDetails.referenceCode}
                            </div>
                        </div>

                        <div className={style.reportDataContainer}>
                            <div>{t("DiagnosticReportsTable.myDeviceHealth.codeTypeLabel")}</div>
                            <div
                                data-testid={
                                    testIds.workArea.report.diagnostic.reportDialog.myDeviceHealth.otherDetails
                                        .codeTypeLabel
                                }
                            >
                                {otherDetails.faultFound
                                    ? t("DiagnosticReportsTable.myDeviceHealth.returnAuthorization")
                                    : t("DiagnosticReportsTable.myDeviceHealth.referenceCode")}
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
}
