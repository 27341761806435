import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { deduceTierLicenses } from "./add-tenant/AddTenantForm";
import { CHECKMARK_SIZE, STROKE_WIDTH, updateSelectedTierTitle } from "./PricingTierView";
import Checkmark from "components/icons/Checkmark";
import Diamond from "components/icons/Diamond";
import Premium from "components/icons/Premium";
import Sparkle from "components/icons/Sparkle";
import GrayStar, { Color as StarColor } from "components/icons/Star";
import {
    BundlesTenantTypes,
    ChannelBundle,
    CorporateBundle,
    ProcessorBundle,
    SmbBundle,
} from "components/licenses/bundles";
import { createProductIdToNameMap } from "components/licenses/common";
import StaticTable from "components/support/api-guide/StaticTable";
import style from "components/tenants/pricing-tier-view.scss";
import { CombinedTier, TenantType } from "domain/tenants";
import { generateTenantTypeToTranslationMap, generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { StoreState } from "store";

import testIds from "testIds.json";

interface Props {
    tenantCreation?: boolean;
    tenantType: TenantType;
    selectedTier: CombinedTier;
}

enum BmpFeatures {
    LICENSE_ALERTS = "License alerts",
    LICENSE_VIEW_AND_ENTITLEMENTS = "Blancco license view and entitlements",
    LICENSE_POOLS = "License pools",
    LICENSE_CONSUMPTION = "License consumption",
    PRODUCT_DOWNLOADS = "Product downloads",
    KNOWLEDGEBASE = "Knowledge base",
    TECHNICAL_SUPPORT_SP1 = "Technical Support SP1",
    TECHNICAL_SUPPORT_SP2 = "Technical Support SP2",
    TECHNICAL_SUPPORT_SPE = "Technical Support SPE",
    KEY_VALUE_STORE = "Key value store",
    BMP_APIS = "BMP APIs",
    CENTRALIZED_REPORTING = "Centralized reporting",
    REPORTING_CUSTOM_VIEWS = "Reporting custom views",
    SUSTAINABILITY_DASHBOARD = "Sustainability dashboard",
    WORKFLOWS = "Intelligent Business Routing (IBR / Workflows)",
    REPORT_CUSTOMIZATION = "Report template library",
    OVERVIEW_DASHBOARD = "Overview dashboard",
    SSO = "Single sign-on (SSO)",
    TENANT_MANAGEMENT = "Tenant management",
    USER_GROUPS = "User groups",
    CUSTOM_ROLES = "Custom roles",
    BMC = "On-premise BMP",
}

interface TierFeature {
    name: string;
    corporateBundles: CorporateBundle[];
    processorBundles: ProcessorBundle[];
    smbBundles: SmbBundle[];
    channelBundles: ChannelBundle[];
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const NewModelPricingTierView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const allCorporateBundles = [CorporateBundle.ESSENTIALS, CorporateBundle.POWER, CorporateBundle.ENTERPRISE];
    const allProcessorBundles = [ProcessorBundle.CORE, ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE];
    const allSmbBundles = [
        SmbBundle.SELECT_50,
        SmbBundle.SELECT_100,
        SmbBundle.SELECT_PLUS_250,
        SmbBundle.SELECT_PLUS_500,
    ];
    const allChannelBundles = [ChannelBundle.PRO_SCALE];
    const deducedTierLicenses = deduceTierLicenses(props.selectedTier, true, props.tenantType, true);
    const availableFeatures: TierFeature[] = [
        {
            name: BmpFeatures.LICENSE_VIEW_AND_ENTITLEMENTS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.LICENSE_ALERTS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.LICENSE_POOLS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.LICENSE_CONSUMPTION,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.PRODUCT_DOWNLOADS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.KNOWLEDGEBASE,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.CUSTOM_ROLES,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.KEY_VALUE_STORE,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.CENTRALIZED_REPORTING,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.SSO,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.REPORTING_CUSTOM_VIEWS,
            corporateBundles: allCorporateBundles,
            processorBundles: allProcessorBundles,
            smbBundles: allSmbBundles,
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.BMP_APIS,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.SUSTAINABILITY_DASHBOARD,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.WORKFLOWS,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.USER_GROUPS,
            corporateBundles: [CorporateBundle.POWER, CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.OVERVIEW_DASHBOARD,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO, ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.REPORT_CUSTOMIZATION,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.TENANT_MANAGEMENT,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
        {
            name: BmpFeatures.TECHNICAL_SUPPORT_SP1,
            corporateBundles: [CorporateBundle.POWER],
            processorBundles: [ProcessorBundle.PRO],
            smbBundles: [],
            channelBundles: [],
        },
        {
            name: BmpFeatures.TECHNICAL_SUPPORT_SP2,
            corporateBundles: [CorporateBundle.ESSENTIALS],
            processorBundles: [ProcessorBundle.CORE],
            smbBundles: [SmbBundle.SELECT_PLUS_250, SmbBundle.SELECT_PLUS_500],
            channelBundles: [],
        },
        {
            name: BmpFeatures.TECHNICAL_SUPPORT_SPE,
            corporateBundles: [CorporateBundle.ENTERPRISE],
            processorBundles: [ProcessorBundle.PRO_SCALE],
            smbBundles: [],
            channelBundles: allChannelBundles,
        },
    ];

    function deduceTierTranslationKey(
        tierType: string,
        selected: boolean
    ):
        | {
              translationKey: string;
              icon: JSX.Element;
          }
        | undefined {
        const corporateBundlesToTranslationKey = new Map<string, { translationKey: string; icon: JSX.Element }>([
            [
                CorporateBundle.ESSENTIALS.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.essentials"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Sparkle />,
                },
            ],
            [
                CorporateBundle.POWER.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.power"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Premium />,
                },
            ],
            [
                CorporateBundle.ENTERPRISE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.enterprise"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Diamond />,
                },
            ],
        ]);

        const processorBundlesToTranslationKey = new Map<string, { translationKey: string; icon: JSX.Element }>([
            [
                ProcessorBundle.CORE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.core"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Sparkle />,
                },
            ],
            [
                ProcessorBundle.PRO.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.pro"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Premium />,
                },
            ],
            [
                ProcessorBundle.PRO_SCALE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.proScale"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Diamond />,
                },
            ],
        ]);
        const smbBundlesToTranslationKey = new Map<string, { translationKey: string; icon: JSX.Element }>([
            [
                SmbBundle.SELECT_50.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.select_50"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Sparkle />,
                },
            ],
            [
                SmbBundle.SELECT_100.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.select_100"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Premium />,
                },
            ],
            [
                SmbBundle.SELECT_PLUS_250.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.select_plus_250"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Diamond />,
                },
            ],
            [
                SmbBundle.SELECT_PLUS_500.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.select_plus_500"),
                        props.selectedTier,
                        t
                    ),
                    icon: <GrayStar color={StarColor.PURPLE} titleText={""} />,
                },
            ],
        ]);
        const channelBundlesToTranslationKey = new Map<string, { translationKey: string; icon: JSX.Element }>([
            [
                ChannelBundle.PRO_SCALE.valueOf(),
                {
                    translationKey: updateSelectedTierTitle(
                        selected,
                        t("AddCustomerForm.pricingTierDialog.header.proScale"),
                        props.selectedTier,
                        t
                    ),
                    icon: <Diamond />,
                },
            ],
        ]);

        if (props.tenantType == BundlesTenantTypes.CORPORATE) {
            return corporateBundlesToTranslationKey.get(tierType);
        } else if (props.tenantType == BundlesTenantTypes.PROCESSOR) {
            return processorBundlesToTranslationKey.get(tierType);
        } else if (props.tenantType == BundlesTenantTypes.SMB) {
            return smbBundlesToTranslationKey.get(tierType);
        } else if (props.tenantType == BundlesTenantTypes.CHANNEL) {
            return channelBundlesToTranslationKey.get(tierType);
        }
    }

    function computeTier(): string[] {
        if (props.tenantType == BundlesTenantTypes.PROCESSOR) {
            return Object.keys(ProcessorBundle);
        } else if (props.tenantType == BundlesTenantTypes.SMB) {
            return Object.keys(SmbBundle);
        } else if (props.tenantType == BundlesTenantTypes.CHANNEL) {
            return Object.keys(ChannelBundle);
        }
        return Object.keys(CorporateBundle);
    }

    function createHeaderForVolumeLicences() {
        const headers = [];
        headers.push({
            className: style.firstColumnHeader,
            value: t("AddCustomerForm.pricingTierDialog.header.licences"),
        });
        return headers;
    }

    function createHeaders() {
        const tiers = computeTier();
        const headers = [];
        headers.push({
            className: style.firstColumnHeader,
            value: t("AddCustomerForm.pricingTierDialog.header.features"),
        });
        tiers.forEach((each, index) => {
            const tierAndIcon = deduceTierTranslationKey(each, props.selectedTier === each);
            headers.push({
                value: (
                    <div className={style.headerContaner}>
                        <div
                            className={
                                index == 0 || index == tiers.length - 1 ? style.headerIcon : style.secondHeaderIcon
                            }
                        >
                            {tierAndIcon?.icon}
                        </div>
                        {tierAndIcon?.translationKey}
                    </div>
                ),
            });
        });
        return headers;
    }

    function displayAvailability(
        tier: CorporateBundle | ProcessorBundle | SmbBundle | ChannelBundle,
        corporateBundles: CorporateBundle[],
        processorBundles: ProcessorBundle[],
        smbBundles: SmbBundle[],
        channelBundle: ChannelBundle[]
    ) {
        if (props.tenantType == BundlesTenantTypes.SMB) {
            return smbBundles.includes(tier as SmbBundle);
        } else if (props.tenantType == BundlesTenantTypes.CORPORATE) {
            return corporateBundles.includes(tier as CorporateBundle);
        } else if (props.tenantType == BundlesTenantTypes.PROCESSOR) {
            return processorBundles.includes(tier as ProcessorBundle);
        }
        return channelBundle.includes(tier as ChannelBundle);
    }

    function generateListOfVolumeLicenses() {
        const productsNameList: string[] = [];
        const ALL_PRODUCTS = createProductIdToNameMap();
        deducedTierLicenses?.forEach((each) => {
            productsNameList.push(ALL_PRODUCTS.get(each) as string);
        });
        return productsNameList.sort();
    }

    function generateCellData() {
        let tiers: CorporateBundle[] | ProcessorBundle[] | SmbBundle[] | ChannelBundle[];
        if (props.tenantType == BundlesTenantTypes.CORPORATE) {
            tiers = allCorporateBundles;
        } else if (props.tenantType == BundlesTenantTypes.PROCESSOR) {
            tiers = allProcessorBundles;
        } else if (props.tenantType == BundlesTenantTypes.SMB) {
            tiers = allSmbBundles;
        } else if (props.tenantType == BundlesTenantTypes.CHANNEL) {
            tiers = allChannelBundles;
        }
        if (!props.tenantType) {
            availableFeatures.push({
                name: BmpFeatures.BMC,
                corporateBundles: [],
                processorBundles: [ProcessorBundle.PRO_SCALE],
                smbBundles: [],
                channelBundles: [ChannelBundle.PRO_SCALE],
            });
        }
        const tierIndexToColorMap = new Map<number, { backgroundColor: string; iconColor: string }>([
            [0, { backgroundColor: style.tierFirstLevelColor, iconColor: props.theme.basicTierBannerBorderColor }],
            [1, { backgroundColor: style.tierSecondLevelColor, iconColor: props.theme.premiumTierBannerBorderColor }],
            [2, { backgroundColor: style.tierThirdLevelColor, iconColor: props.theme.diamondTierBannerBorderColor }],
        ]);
        if (props.tenantType == BundlesTenantTypes.SMB) {
            tierIndexToColorMap.set(3, {
                backgroundColor: style.tierFourthLevelColor,
                iconColor: props.theme.diamondTierBannerBorderColor,
            });
        }
        return availableFeatures.map((eachLicense) => {
            return [
                <td key={"row" + "-" + eachLicense.name}>
                    <div key={eachLicense.name}>{eachLicense.name}</div>
                </td>,
                ...tiers.map((eachTier, index) => {
                    const tierColor = tierIndexToColorMap.get(index);
                    const backgroundColor = tierColor?.backgroundColor || "";
                    const iconColor = tierColor?.iconColor || "";
                    return (
                        <td
                            key={eachTier + "-" + eachLicense}
                            className={classNames(
                                //[style.tableDataBorder]:  index != tiers.length,
                                { [backgroundColor]: props.selectedTier === eachTier }
                            )}
                        >
                            <div className={style.checkMarkCenter}>
                                {displayAvailability(
                                    eachTier,
                                    eachLicense.corporateBundles,
                                    eachLicense.processorBundles,
                                    eachLicense.smbBundles,
                                    eachLicense.channelBundles
                                ) ? (
                                    <Checkmark color={iconColor} size={CHECKMARK_SIZE} strokeWidth={STROKE_WIDTH} />
                                ) : null}
                            </div>
                        </td>
                    );
                }),
            ];
        });
    }

    function createFeatureLicensesSummary() {
        return t("AddCustomerForm.pricingTierDialog.summary2", {
            tenantType: generateTenantTypeToTranslationMap(t).get(props.tenantType),
            tierPlan: generateTierToTranslationMap(t).get(props.selectedTier),
            tierOrBundle: t("AddCustomerForm.pricingTierDialog.bundle"),
        });
    }

    function createVolumeLicensesSummary() {
        return t("AddCustomerForm.pricingTierDialog.summaryVolLicences", {
            tenantType: generateTenantTypeToTranslationMap(t).get(props.tenantType),
            tierPlan: generateTierToTranslationMap(t).get(props.selectedTier),
            tierOrBundle: t("AddCustomerForm.pricingTierDialog.bundle"),
        });
    }

    return (
        <div>
            <Tabs className={style.tabsSegmentVariant}>
                <TabList>
                    <Tab>{t("AddCustomerForm.pricingTierDialog.header.features")} </Tab>
                    <Tab>{t("AddCustomerForm.pricingTierDialog.header.volumeLicenses")} </Tab>
                </TabList>
                <TabPanel>
                    <div className={style.summaryBundles}>{createFeatureLicensesSummary()}</div>
                    <div className={style.tableContainer}>
                        <StaticTable
                            headers={createHeaders()}
                            styledCells={generateCellData()}
                            tableClass={style.table}
                            testId={testIds.workArea.tenant.pricingTierDialog.table.itself}
                        />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                        <div className={style.summaryBundles}>{createVolumeLicensesSummary()}</div>
                        <div className={style.tableContainer}>
                            <StaticTable
                                headers={createHeaderForVolumeLicences()}
                                cells={generateListOfVolumeLicenses().map((each) => {
                                    return [<div key={each}>{each}</div>];
                                })}
                                tableClass={style.table}
                                testId={testIds.workArea.tenant.pricingTierDialog.table.itself}
                            />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default connector(NewModelPricingTierView);
