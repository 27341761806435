import classNames from "classnames";
import React, { useRef } from "react";
import { MenuItem, MenuItemProps } from "react-aria-menubutton";

import style from "./menu-item-button.scss";

const MenuItemButton = ({ ...props }: MenuItemProps<HTMLElement>) => {
    const ref = useRef<HTMLElement & MenuItem>(null);
    return (
        <MenuItem
            {...props}
            tag={props.tag ?? "button"}
            onKeyDown={undefined}
            ref={ref}
            type="button"
            className={classNames(props.className, style.menuItemButton)}
        >
            {props.children}
        </MenuItem>
    );
};

export default MenuItemButton;
