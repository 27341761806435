import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import Button from "components/button/Button";
import { useOutsideClick } from "components/header/MenuPanel";
import style from "components/home/bde-home-page/getting-started.scss";
import CircleNumber from "components/home/CircleWithNumber";
import StepCompletedText from "components/home/StepCompletedText";
import StepHeader from "components/home/StepHeader";
import ExportIcon from "components/icons/ExportIcon";
import Info from "components/icons/Info";
import Tooltip from "components/tooltip/Tooltip";
import { BmdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { SoftwarePackage, Versions } from "domain/softwarePackages";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    stepIndex: number;
    menuExpanded: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    licenses: Versions[];
    stepClickable: boolean;
    fetchedSoftwarePackages?: SoftwarePackage[];
    saveOnboardingStep: (onboarding: Onboarding) => void;
    product: OnboardingProductType;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

interface StepAction {
    description: string;
    label: string;
    onClick: () => void;
    variant?: "bmdeIso" | "bmdeUSBCreator" | "bdeUSBCreator";
}

interface Step {
    description: string;
    actions?: StepAction[];
    icon?: string;
    tooltip?: string;
}

const CreateBootableUsb = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const [closeInfoDialog, setCloseInfoDialog] = React.useState(true);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const actionButtonRef = React.useRef(null);
    const dataType = props.product + "_" + BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE;

    React.useEffect(() => {
        if (props.stepInProgressIndex !== props.stepIndex) {
            setShowContent(false);
            setCloseInfoDialog(true);
        }
    }, [props.stepInProgressIndex]);
    React.useEffect(() => {
        if (props.menuExpanded && props.stepClickable) {
            setShowContent(true);
        }
    }, [props.menuExpanded]);

    const usbVersionToUrlMapping: Map<string, string> = new Map();
    props.fetchedSoftwarePackages?.map((product) => {
        if (product.productName === "Blancco USB Creator") {
            return product.versions.map((toolVersion) => {
                const version = toolVersion.version;
                if (version && toolVersion.downloadUrl) {
                    usbVersionToUrlMapping.set(version, toolVersion.downloadUrl);
                }
            });
        }
        return null;
    });

    const handleISODownload = () => {
        if (props.licenses.length == 1) {
            const url = window.URL.createObjectURL(new Blob([props.licenses[0].downloadUrl]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", props.licenses[0].version);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    };

    const handleUSBCreatorInstall = () => {
        setExpanded((prev) => !prev);
    };

    const menuItems: JSX.Element[] = [...usbVersionToUrlMapping].map(([key, url]) => (
        <button
            className={style.versionLink}
            key={key}
            onClick={() => handleUSBCreatorDownload(url, key)}
            data-testid={testIds.navigation.userMenu.profileLink}
        >
            {key}
        </button>
    ));

    const handleUSBCreatorDownload = (licenseUrl: string, version: string) => {
        const url = window.URL.createObjectURL(new Blob([licenseUrl]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", version);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    };

    const clickEvent = () => {
        if (props.stepClickable) {
            setShowContent(!showContent);
        }
    };

    const bdeUsbCreationSteps: Step[] = [
        {
            description: "",
            actions: [
                {
                    description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.title"),
                    label: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.usbCreatorButton"),
                    onClick: handleUSBCreatorInstall,
                    variant: "bdeUSBCreator",
                },
            ],
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepTwo"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepThree"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepFour"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepFive"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepSix"),
            tooltip: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.infoIcon"),
        },
        {
            description: t("Onboarding.gettingStartedFlow.createBootableUsbDrive.stepSeven"),
        },
    ];

    const bmdeUsbCreationSteps: Step[] = [
        {
            description: "",
            actions: [
                {
                    description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.downloadISO"),
                    label: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.downloadISOButton"),
                    onClick: handleISODownload,
                    variant: "bmdeIso",
                },
                {
                    description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.downloadUSBCreator"),
                    label: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.usbCreatorButton"),
                    onClick: handleUSBCreatorInstall,
                    variant: "bmdeUSBCreator",
                },
            ],
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.insertUSB"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.bootableUSBStep"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.selectUSB"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.addISO"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.createBootableUSB"),
            tooltip: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.createUsbTooltip"),
        },
        {
            description: t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.done"),
        },
    ];

    const handleClickOutside = () => {
        setExpanded(false);
    };

    const versionUrlRef = useOutsideClick(handleClickOutside);
    function createContent() {
        return (
            <>
                <div hidden={closeInfoDialog && !showContent} className={style.stepsContainer}>
                    <div className={style.alertBox}>
                        <div>
                            <Info
                                borderColor={theme.contentBackgroundColor}
                                color={theme.sortIconActiveColor}
                                size={"18"}
                            />
                        </div>
                        <div className={style.bannerText}>
                            {props.product === OnboardingProductType.BDE
                                ? t("Onboarding.gettingStartedFlow.createBootableUsbDrive.prepareYourUSB")
                                : t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.prepareYourUSB")}
                        </div>
                    </div>
                    {(props.product === OnboardingProductType.BDE ? bdeUsbCreationSteps : bmdeUsbCreationSteps).map(
                        (step, index) => (
                            <div key={index} className={style.subStepsToFollowLayout}>
                                <CircleNumber number={index + 1} subStep={true} />
                                {!step.actions && <div className={style.stepPadding}>{step.description}</div>}
                                {step.tooltip && (
                                    <Tooltip content={step.tooltip} placement={"top"}>
                                        <span className={style.tooltipSpacing}>
                                            <Info
                                                borderColor={theme.contentBackgroundColor}
                                                color={theme.sortIconActiveColor}
                                                size={"14"}
                                            />
                                        </span>
                                    </Tooltip>
                                )}
                                {step.actions && step.actions.length > 0 && (
                                    <div className={classNames(style.columnLayout)}>
                                        {step.actions.map((action, actionIndex) => (
                                            <div key={actionIndex} className={classNames(style.actionColumn)}>
                                                <div>{action.description}</div>
                                                <div className={style.stepActionButton} ref={versionUrlRef}>
                                                    {action.label}
                                                    <div className={classNames(style.actionButton)}>
                                                        <Button
                                                            variant={"TERTIARY"}
                                                            onClick={action.onClick}
                                                            ref={actionButtonRef}
                                                            aria-expanded={expanded}
                                                        >
                                                            <ExportIcon size={24} color={theme.iconFillColor} />
                                                        </Button>
                                                    </div>
                                                </div>
                                                {usbVersionToUrlMapping && expanded && (
                                                    <div className={style.version}>{menuItems}</div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
                <div className={style.markComplete} hidden={closeInfoDialog && !showContent}>
                    {props.stepCompleted ? (
                        <StepCompletedText
                            successText={t("Onboarding.gettingStartedFlow.createBootableUsbDrive.completed")}
                            testId={testIds.workArea.onboarding.step.statusLabel}
                            dataType={dataType}
                        />
                    ) : (
                        <Button
                            variant={"PRIMARY"}
                            className={classNames(buttons.primaryButtonWithoutIcon)}
                            onClick={() => {
                                props.saveStepInProgressIndex(props.stepIndex);
                                props.saveOnboardingStep({
                                    product: props.product,
                                    step: BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE,
                                });
                            }}
                            testId={testIds.workArea.onboarding.step.primaryButton}
                            data-type={dataType}
                            disabled={props.stepInProgressIndex === props.stepIndex}
                            loadingAnimation={props.stepInProgressIndex === props.stepIndex}
                        >
                            {props.stepInProgressIndex === props.stepIndex
                                ? t("Onboarding.saving")
                                : t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.markComplete")}
                        </Button>
                    )}
                </div>
            </>
        );
    }
    return (
        <StepHeader
            success={props.stepCompleted}
            showStep={showContent}
            inactive={!props.stepClickable}
            title={t("Onboarding.bmde.gettingStarted.steps.createBootableUsbDrive.title")}
            stepIndex={props.stepIndex}
            showContent={showContent}
            clickEvent={clickEvent}
            menuExpanded={props.menuExpanded}
            content={createContent()}
            type={props.product}
            stepName={BmdeOnboardingSteps.STEP_BOOTING_USB_DRIVE}
        />
    );
};

export default connector(CreateBootableUsb);
