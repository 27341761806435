import * as React from "react";

interface Props {
    color: string;
}

export const InfoBadgeIcon = React.forwardRef<SVGSVGElement, Props>(
    (props, ref): JSX.Element => (
        <svg ref={ref} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.00016" cy="7.00016" r="6.66667" fill={props.color} />
            <path
                d="M7.66683 3.66667C7.66683 4.03486 7.36835 4.33333 7.00016 4.33333C6.63197 4.33333 6.3335 4.03486 6.3335 3.66667C6.3335 3.29848 6.63197 3 7.00016 3C7.36835 3 7.66683 3.29848 7.66683 3.66667Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.00016 5.66667C7.36835 5.66667 7.66683 5.96514 7.66683 6.33333V10.3333C7.66683 10.7015 7.36835 11 7.00016 11C6.63197 11 6.3335 10.7015 6.3335 10.3333V6.33333C6.3335 5.96514 6.63197 5.66667 7.00016 5.66667Z"
                fill="white"
            />
        </svg>
    )
);
