import * as React from "react";

const StepCompletedIcon = (props: { color: string; checkMarkColor: string }): JSX.Element => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
            fill={props.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4801 6.42385C14.7983 6.68903 14.8413 7.16195 14.5762 7.48016L9.57617 13.4802C9.44127 13.642 9.24449 13.7397 9.03399 13.7492C8.82348 13.7588 8.61867 13.6794 8.46967 13.5303L5.46967 10.5303C5.17678 10.2375 5.17678 9.76258 5.46967 9.46969C5.76256 9.1768 6.23744 9.1768 6.53033 9.46969L8.9496 11.889L13.4238 6.51988C13.689 6.20167 14.1619 6.15868 14.4801 6.42385Z"
            fill={props.checkMarkColor}
        />
    </svg>
);

export default StepCompletedIcon;
