import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import style from "./mcs.scss";
import McsKebabMenu from "./McsKebabMenu";
import McSyncInformationView from "./McSyncInformationView";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import StatusBadge, { Status } from "components/status-badge/StatusBadge";
import DateCell from "components/table/DateCell";
import Table, { deriveColumnWidth } from "components/table/Table";
import TextWithTooltip from "components/table/TextWithTooltip";
import { TABLE_PAGE_LIMIT } from "domain/globalConstants";
import { getMcStatusDisplay, Mcs, McsTableData, McStatus } from "domain/mc";
import { hybridMcService } from "services/hybrid-mc/HybridMcService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import buttons from "styles/buttons.scss";
import layoutStyle from "styles/layout.scss";
import statusStyle from "styles/status.scss";
import { RepositoryKey } from "utils/repository";

import infoBadge from "assets/images/icons/product-icons/infoBadge.svg";

import testIds from "testIds.json";

interface TableState {
    Mcs: McsTableData[];
    cursor: string;
    desired: number;
    scrollPosition?: number;
}

export interface Props {
    count: number;
    initialMcsData: Mcs;
    search?: string;
    onMcChange: () => void;
}

const STATUS_MAPPING: Record<McStatus, Status> = {
    [McStatus.ACTIVE]: Status.SUCCESS,
    [McStatus.UNREGISTERED]: Status.NEUTRAL,
    [McStatus.DISABLED]: Status.ERROR,
};

export function calculateDaysUntilHalt(
    now: Date,
    mc: McsTableData,
    desiredSeconds: number
): { desiredPassed: boolean; days: number } {
    const desiredMillis = desiredSeconds * 1000;
    const theoreticalDesiredMillis = new Date(mc.lastUpdatedDate).getTime() + desiredMillis;
    const desiredPassed = theoreticalDesiredMillis < now.getTime();
    const deadlineDate = new Date(mc.deadlineDate);
    return {
        desiredPassed,
        days: desiredPassed ? Math.ceil((deadlineDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)) : 0,
    };
}

const McsTable = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    const [tableState, setTableState] = React.useState<TableState>({
        Mcs: [],
        cursor: "",
        desired: 0,
        scrollPosition: 0,
    });
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string | JSX.Element>("");
    const [initialLoading, setInitialLoading] = React.useState<boolean>(false);
    const [mcSyncInformationVisibility, setMcSyncInformationVisibility] = React.useState(false);
    const [selectedMc, setSelectedMc] = React.useState<McsTableData>();
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);

    const haltedStatusContent = { status: Status.ERROR, title: t("Mcs.table.mcStatus.deactivated") };

    const hideSyncInformation = () => {
        setMcSyncInformationVisibility(false);
    };

    const viewSyncInformation = (
        <Modal
            isOpen={mcSyncInformationVisibility}
            hideModal={hideSyncInformation}
            modalTitle={t("Mcs.onPremBmpInfoModal.title")}
        >
            {selectedMc != null && <McSyncInformationView mcsTableData={selectedMc} onClick={hideSyncInformation} />}
        </Modal>
    );

    const errorText = (
        <div>
            <div className={style.errorText}>
                <span>{t("Mcs.table.requestFailed")}</span>
                <span>{t("Mcs.table.requestFailed1")}</span>
                <span>{t("Mcs.table.requestFailed2")}</span>
            </div>
        </div>
    );
    const fetchData = (initialLoading: boolean) => {
        setLoading(true);
        setInitialLoading(initialLoading);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        hybridMcService
            .fetchData(abortController)
            .then((data) => {
                setTableState((prevState) => ({
                    ...prevState,
                    scrollPosition: prevState.Mcs.length - 1,
                    Mcs: prevState.Mcs.concat(data.mcdata),
                    desired: data.desired,
                    cursor: data.cursor,
                }));
                setLoading(false);
                setRequestFailureMessage("");
            })
            .catch(() => {
                if (!abortController?.signal.aborted) {
                    setRequestFailureMessage(errorText);
                }
            })
            .finally(() => {
                if (!abortController?.signal.aborted) {
                    setLoading(false);
                    setInitialLoading(false);
                }
            });
    };
    const deriveStatusConfig = (value: McStatus) => {
        const status: Status = STATUS_MAPPING[value];
        const translationKey = getMcStatusDisplay(value);
        return {
            status: status,
            title: t(translationKey),
        };
    };

    const deriveStatus = (desired: number, mc: McsTableData) => {
        const { desiredPassed, days } = calculateDaysUntilHalt(new Date(), mc, desired);
        if (desiredPassed) {
            if (days > 0) {
                return (
                    <div>
                        <span className={classNames(style.statusBadge, statusStyle.statusWarning)}>
                            {t("Mcs.table.mcStatus.syncText", { days })}
                        </span>
                    </div>
                );
            } else {
                return (
                    <div>
                        <span className={classNames(style.statusBadge)}>
                            <StatusBadge values={[haltedStatusContent]} tooltip={true} />
                        </span>
                    </div>
                );
            }
        } else {
            return (
                <div>
                    <span className={classNames(style.statusBadge)}>
                        <StatusBadge values={[deriveStatusConfig(mc.status)]} tooltip={true} />
                    </span>
                </div>
            );
        }
    };

    const columns: Array<Column<McsTableData>> = [
        {
            Header: () => <TextWithTooltip text={t("ApiKeys.name")} key="1" />,
            accessor: "name",
            Cell: (cellInfo) => (
                <div className={classNames(style.alignment)}>
                    <div className={classNames(style.marginRight)}>
                        {
                            <McsKebabMenu
                                uuid={cellInfo.cell.row.original.uuid}
                                name={cellInfo.cell.row.original.name}
                                description={cellInfo.cell.row.original.description}
                                status={cellInfo.cell.row.original.status}
                                tableState={tableState.Mcs}
                                onMcChange={props.onMcChange}
                            />
                        }
                    </div>
                    <a
                        onClick={() => {
                            setMcSyncInformationVisibility(true);
                            setSelectedMc(cellInfo.cell.row.original);
                        }}
                    >
                        <TextWithTooltip text={cellInfo.value} />
                    </a>
                </div>
            ),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.description")} key="2" />,
            accessor: "description",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.registrationDate")} key="3" />,
            accessor: "registrationDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={true} />,
            width: deriveColumnWidth(28, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.lastSynced")} key="4" />,
            accessor: "lastUpdatedDate",
            Cell: ({ cell: { value } }) => <DateCell tooltip={true} value={value} withoutTime={false} />,
            width: deriveColumnWidth(18, tableContainerRef),
        },
        {
            Header: () => (
                <div className={classNames(style.statusTitleInfo)} key="5">
                    <TextWithTooltip text={t("Mcs.table.status")} />
                    <TextWithTooltip text={t("Mcs.table.statusInfo")}>
                        <img src={infoBadge} />
                    </TextWithTooltip>
                </div>
            ),
            accessor: "status",
            Cell: (cellInfo) => {
                const mc = cellInfo.cell.row.original;

                if (mc.status == McStatus.UNREGISTERED || mc.status == McStatus.DISABLED) {
                    return (
                        <div>
                            <span className={classNames(style.statusBadge)}>
                                <StatusBadge values={[deriveStatusConfig(mc.status)]} tooltip={true} />
                            </span>
                        </div>
                    );
                }

                if (mc.halted) {
                    return (
                        <div>
                            <span className={classNames(style.statusBadge)}>
                                <StatusBadge values={[haltedStatusContent]} tooltip={true} />
                            </span>
                        </div>
                    );
                }

                const statusResult = deriveStatus(tableState.desired, mc);
                return (
                    <div>
                        <span className={classNames(style.statusBadge)}>{statusResult}</span>
                    </div>
                );
            },
            width: deriveColumnWidth(15, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.uuid")} key="6" />,
            accessor: "uuid",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(25, tableContainerRef),
        },
        {
            Header: () => <TextWithTooltip text={t("Mcs.table.mcVersion")} key="7" />,
            accessor: "mcVersion",
            Cell: ({ cell: { value } }) => <TextWithTooltip text={value} />,
            width: deriveColumnWidth(10, tableContainerRef),
        },
    ];

    React.useEffect(() => {
        setTableState({ Mcs: [], cursor: "", desired: 0, scrollPosition: 0 });
        fetchData(true);
    }, [props.count]);

    let dataCount = null;
    if (tableState.Mcs.length > 0) {
        dataCount = <div>{t("Common.defaultSearchResultHint", { dataCount: tableState.Mcs.length })}</div>;
    }
    return (
        <>
            <div className={layoutStyle.aboveTable}>
                <div className={layoutStyle.recordCount}>{dataCount}</div>
            </div>
            <div className={layoutStyle.tableWrapper} ref={tableContainerRef}>
                <Table
                    tableIdentity={RepositoryKey.MCS_TABLE}
                    data={tableState.Mcs}
                    columns={columns}
                    loaded={!initialLoading}
                    failureMessage={requestFailureMessage}
                    tooltips={true}
                    scrollTo={tableState.scrollPosition}
                    emptyMessage={""}
                    testId={testIds.workArea.mcs.table.itself}
                />
            </div>
            {tableState.cursor != null &&
                tableState.Mcs.length >= TABLE_PAGE_LIMIT &&
                tableState.Mcs.length != 0 &&
                requestFailureMessage === "" &&
                (loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <button
                        className={buttons.loadMoreButtonWithoutIcon}
                        onClick={() => {
                            fetchData(false);
                            usageStatisticsService.sendEvent({
                                action: Action.LOAD_MORE,
                                category: Category.MCS,
                            });
                        }}
                    >
                        {t("Common.loadMore")}
                    </button>
                ))}
            {viewSyncInformation}
        </>
    );
};

export default McsTable;
