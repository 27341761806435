import classNames from "classnames";
import * as React from "react";

import style from "./status-badge.scss";
import layoutStyle from "styles/layout.scss";
import statusStyle from "styles/status.scss";

export enum Status {
    ERROR,
    SUCCESS,
    WARNING,
    NEUTRAL,
    INFO,
}

interface Content {
    status: Status;
    title: string;
}

const STATUS_TO_CLASSNAME = new Map([
    [Status.ERROR, statusStyle.statusFailed],
    [Status.SUCCESS, statusStyle.statusSuccessful],
    [Status.WARNING, statusStyle.statusWarning],
    [Status.NEUTRAL, statusStyle.statusNeutral],
    [Status.INFO, statusStyle.statusInfo],
]);

function toClassname(status: Status): string {
    const className = STATUS_TO_CLASSNAME.get(status);
    return className != null ? className : statusStyle.statusWarning;
}

const StatusBadge = (props: {
    values: Content[];
    tooltip?: boolean;
    disabled?: boolean;
    testId?: string;
}): JSX.Element => (
    <div>
        {props.values.map((value, index) => (
            <div
                data-testid={props.testId}
                className={classNames(style.statusBadge, toClassname(value.status), {
                    [layoutStyle.disabledContainer]: props.disabled,
                })}
                key={index}
            >
                {value.title}
            </div>
        ))}
    </div>
);

export default StatusBadge;
