import * as React from "react";

const SelectedCheckmark = (props: { color: string }): JSX.Element => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5017 6.44254C19.8096 6.71963 19.8346 7.19385 19.5575 7.50173L10.5575 17.5017C10.4198 17.6547 10.2254 17.7443 10.0197 17.7497C9.81403 17.7552 9.61516 17.6758 9.46967 17.5303L5.46967 13.5303C5.17678 13.2374 5.17678 12.7626 5.46967 12.4697C5.76256 12.1768 6.23744 12.1768 6.53033 12.4697L9.97135 15.9107L18.4425 6.49828C18.7196 6.1904 19.1938 6.16544 19.5017 6.44254Z"
                fill={props.color}
            />
        </svg>
    );
};

export default SelectedCheckmark;
