import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { ALL_REPORTS_ROUTE } from "components/router/Routes";
import {
    BdeOnboardingSteps,
    BmdeOnboardingSteps,
    OnboardingProductNames,
    OnboardingProductType,
} from "domain/onboardings";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { Report, reportService } from "services/report/erasure/ReportService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    product: OnboardingProductType;
    stepCompleted: boolean;
    hasIbr: boolean;
    stepName: string;
    saveOnboardingStep: (onboarding: Onboarding) => void;
}
const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const ViewReportSubStep = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const [reports, setReports] = React.useState<Report[]>([]);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState("");
    const history = useHistory();
    const navigateToReports = (report: Report | undefined, productType: string | undefined) => {
        history.push(ALL_REPORTS_ROUTE.path, { report, productType });
    };
    const [showReportCheckedButton, setShowReportCheckedButton] = React.useState(true);
    const [showUploadReport, setShowUploadReport] = React.useState(false);
    const abortController = new AbortController();
    abortControllers.push(abortController);
    const intervalRef = React.useRef<NodeJS.Timeout>();
    const attemptCountRef = React.useRef(0);
    const MAX_ATTEMPTS = 10;
    const POLLING_INTERVAL = 15000;

    const paths = ["report.uuid", "report.product_name", "report.report_date"];

    const fetchReports = () => {
        setLoading(true);
        reportService
            .fetchAllReports({
                abortController,
                sortAscending: false,
                paths: paths,
                search: OnboardingProductNames[props.product],
            })
            .then((response) => {
                if (response.reports.length > 0) {
                    setReports(response.reports);
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    setLoading(false);
                } else {
                    setReports([]);
                    attemptCountRef.current += 1;

                    if (attemptCountRef.current >= MAX_ATTEMPTS) {
                        setRequestFailureMessage(
                            t("Onboarding.bmde.gettingStarted.steps.viewReport.reportFetchTimeOut")
                        );
                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                            setLoading(false);
                        }
                    }
                }
            })
            .catch((exception) => {
                setLoading(false);
                setRequestFailureMessage(exception);
            });
    };
    React.useEffect(() => {
        if (!props.stepCompleted) {
            fetchReports();

            intervalRef.current = setInterval(() => {
                if (attemptCountRef.current < MAX_ATTEMPTS) {
                    fetchReports();
                }
            }, POLLING_INTERVAL);

            return () => {
                abortControllers.forEach((abortController) => abortController.abort());
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        }
    }, []);

    const saveOnboardingStep = () => {
        props.saveOnboardingStep({
            product: props.product,
            step:
                props.product === OnboardingProductType.BMDE
                    ? BmdeOnboardingSteps.STEP_VIEWING_REPORT
                    : BdeOnboardingSteps.STEP_VIEWING_REPORT,
        });
    };

    return (
        <>
            <div className={style.previewTemplateInfoText}>
                {loading ? (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.description")} />
                ) : reports.length === 1 ? (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.reportReady")} />
                ) : showUploadReport || props.stepCompleted ? (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.reportViewed")} />
                ) : (
                    <LoadingReports description={t("Onboarding.bmde.gettingStarted.steps.viewReport.description")} />
                )}
                {loading ? (
                    <LoadingIndicator small={true} />
                ) : (
                    <div className={style.innerPaddingSubStep}>
                        {reports.length > 1 ? (
                            <button
                                className={style.previewTemplateButton}
                                onClick={() => {
                                    navigateToReports(undefined, props.product);
                                    saveOnboardingStep();
                                }}
                                data-testid={testIds.workArea.onboarding.step.primaryButton}
                                data-type={props.product + "_" + props.stepName}
                            >
                                {t("Onboarding.bmde.gettingStarted.steps.viewReport.goToReports")}
                            </button>
                        ) : reports.length === 1 ? (
                            <button
                                className={style.previewTemplateButton}
                                onClick={() => {
                                    navigateToReports(reports[0], props.product);
                                    saveOnboardingStep();
                                }}
                            >
                                {t("Onboarding.bmde.gettingStarted.steps.viewReport.viewReport")}
                            </button>
                        ) : showUploadReport ? (
                            <div>
                                <div className={style.uploadButtonContainer}>
                                    <Button
                                        variant={"TERTIARY"}
                                        className={style.previewTemplateButton}
                                        onClick={() => {
                                            navigateToReports(undefined, props.product);
                                            saveOnboardingStep();
                                            setShowUploadReport(false);
                                        }}
                                    >
                                        {t("Onboarding.bmde.gettingStarted.steps.viewReport.uploadReport")}
                                    </Button>
                                    <div className={style.textGap}>
                                        {t("Onboarding.bmde.gettingStarted.steps.viewReport.uploadReportManually")}
                                    </div>
                                </div>
                            </div>
                        ) : requestFailureMessage !== "" ? (
                            <div className={style.timeoutMessageContainer}>
                                {t("Onboarding.bmde.gettingStarted.steps.viewReport.reportFetchTimeOut")}
                            </div>
                        ) : null}
                    </div>
                )}
                {showReportCheckedButton && !props.stepCompleted && (requestFailureMessage !== "" || loading) && (
                    <div className={style.templateSelectButtonLayout}>
                        <button
                            className={classNames(buttons.primaryButtonWithoutIcon, style.templateSelectButton)}
                            onClick={() => {
                                if (requestFailureMessage !== "") {
                                    if (props.hasIbr) {
                                        setShowUploadReport(true);
                                    }
                                    saveOnboardingStep();
                                    setRequestFailureMessage("");
                                } else {
                                    saveOnboardingStep();
                                    setLoading(false);
                                    clearInterval(intervalRef.current);
                                }
                                setShowReportCheckedButton(false);
                            }}
                        >
                            {(requestFailureMessage !== "" || loading) &&
                                (props.hasIbr
                                    ? t("Onboarding.bmde.gettingStarted.steps.viewReport.canSeeTheReport")
                                    : t("Onboarding.bmde.gettingStarted.steps.viewReport.reportChecked"))}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

interface LoadingReportsProps {
    description: string;
}

const LoadingReports = (props: LoadingReportsProps): JSX.Element => {
    return (
        <>
            <div className={style.innerPaddingSubStep}>{props.description}</div>
        </>
    );
};

export default connector(ViewReportSubStep);
