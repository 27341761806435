import classNames from "classnames";
import { Field, Form, Formik, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import style from "./mcs.scss";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { McsTableData } from "domain/mc";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Props {
    uuid: string;
    name: string;
    description?: string;
    handleSubmit: (values: FormValues) => Promise<void>;
    handleCancel: () => void;
    tableState: McsTableData[];
}

export interface FormValues {
    uuid: string;
    name: string;
    description: string;
}

export default function EditMcsForm(props: Props): JSX.Element {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Formik
            initialValues={{
                uuid: props.uuid,
                name: props.name,
                description: props.description,
            }}
            onSubmit={props.handleSubmit}
            validationSchema={object().shape({
                name: string()
                    .required()
                    .max(50)
                    .test("duplicate-name-check", t("Mcs.edit.errorMessage"), function (value) {
                        const normalizedValue = value ? value.trim().replace(/\s+/g, " ").toLowerCase() : "";
                        if (
                            !normalizedValue ||
                            normalizedValue === props.name?.trim().replace(/\s+/g, " ").toLowerCase()
                        ) {
                            return true;
                        }

                        return !props.tableState.some(
                            (item) =>
                                item.name.trim().replace(/\s+/g, " ").toLowerCase() === normalizedValue &&
                                item.uuid !== props.uuid
                        );
                    }),
                description: string().optional().max(1000).nullable(),
            })}
            validateOnChange={true}
            validateOnBlur={true}
        >
            {({
                values,
                handleChange,
                handleBlur,
                errors,
                isSubmitting,
                isValid,
                dirty,
                touched,
            }: FormikProps<FormValues>) => {
                if (isSubmitting) {
                    return <LoadingIndicator />;
                }
                return (
                    <Form>
                        <div>
                            <div className={form.formFields}>
                                <label htmlFor="uuid" className={form.label}>
                                    {t("Mcs.edit.uuid")}
                                </label>
                                <span data-testid={testIds.workArea.mcs.editMcDialog.uuidLabel}>{values.uuid}</span>
                            </div>
                            <div className={form.formFields}>
                                <label htmlFor="name" className={form.label}>
                                    {t("Mcs.table.name")}
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className={classNames(form.input, form.fixedWidthInput)}
                                    autoFocus
                                    onBlur={handleBlur}
                                    defaultValue={values.name}
                                    onChange={handleChange}
                                    data-testid={testIds.workArea.mcs.editMcDialog.nameInput.itself}
                                />
                                <div
                                    className={form.error}
                                    data-testid={testIds.workArea.mcs.editMcDialog.nameInput.errorLabel}
                                >
                                    {touched.name && errors.name}
                                </div>
                            </div>

                            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                <div className={form.formFieldsAlignItemsTop}>
                                    <label htmlFor="description" className={classNames(form.label)}>
                                        {t("Mcs.table.description")}
                                    </label>
                                    <span className={form.optional}>{t("Common.optional")}</span>
                                </div>
                                <div>
                                    <Field
                                        as="textarea"
                                        id="description"
                                        className={classNames(form.input, form.fixedWidthInput, style.textarea)}
                                        name="description"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        data-testid={testIds.workArea.mcs.editMcDialog.descriptionInput.itself}
                                    />
                                    <div
                                        className={form.error}
                                        data-testid={testIds.workArea.mcs.editMcDialog.descriptionInput.errorLabel}
                                    >
                                        {touched.description && errors.description}
                                    </div>
                                </div>
                            </div>

                            <div className={classNames(form.buttonContainer, style.padding)}>
                                <button
                                    type={"submit"}
                                    className={buttons.primaryButtonWithoutIcon}
                                    data-testid={testIds.workArea.mcs.editMcDialog.saveButton}
                                    disabled={!(isValid && dirty)}
                                >
                                    {t("Common.save")}
                                </button>
                                <button
                                    type={"button"}
                                    className={buttons.secondaryButtonWithoutIcon}
                                    data-testid={testIds.common.dialog.closeButton}
                                    onClick={props.handleCancel}
                                >
                                    {t("Common.cancel")}
                                </button>
                            </div>
                            <div>
                                {errors.name && (
                                    <div className={style.errorBanner}>
                                        <div className={style.warningIcon} tabIndex={0}>
                                            <FailedRedNotificationIcon
                                                backgroundColor={theme.bannerIconBackgroundColor}
                                                iconColor={theme.bannerIconColor}
                                            />
                                        </div>
                                        <p>{t("Mcs.edit.errorBanner")}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}
