import { useFeature } from "flagged";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import CurrentPlan from "components/home/current-plan/CurrentPlan";
import { InfoBadgeIcon } from "components/home/home-icons/InfoBadgeIcon";
import style from "components/home/home.scss";
import ProductCard from "components/home/product-card/ProductCard";
import Support from "components/home/support/Support";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { SUPPORT_AND_HELP_ROUTE } from "components/router/Routes";
import { filterProductManuals } from "components/support/support-and-help/ProductManuals";
import { filterSoftwarePackages } from "components/support/support-and-help/SoftwarePackages";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { AUTH_LICENSE_VIEW_OWN } from "domain/authority";
import { LicenseData } from "domain/licenses";
import { BdeOnboardingSteps, BmdeOnboardingSteps } from "domain/onboardings";
import { ProductManual } from "domain/productManuals";
import { SoftwarePackage, SoftwarePackageHomePage } from "domain/softwarePackages";
import { FLAG_ONBOARDING } from "services/feature/FeatureFlagService";
import { licenseService } from "services/licenses/LicenseService";
import { onboardingsService } from "services/onboardings/OnboardingsService";
import { productManualsService } from "services/product-manuals/ProductManualsService";
import { softwarePackagesService } from "services/software-packages/SoftwarePackagesService";
import {
    getCurrentTenantDetails,
    hasTenantCookie,
    isLicensingModelBundleWithToken,
} from "services/tenants/tenantCookieService";
import { userSessionService } from "services/user/UserSessionService";
import { isStringNotBlank } from "utils/commonFunctions";
import { useTheme } from "utils/useTheme";

const Home = (): JSX.Element => {
    const theme = useTheme();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [infoProductsState, setInfoProductsState] = useState<Record<string, SoftwarePackage[]>>();
    const [assetProductsState, setAssetProductsState] = useState<Record<string, SoftwarePackage[]>>();
    const [additionalProductsState, setAdditionalProductsState] = useState<Record<string, SoftwarePackage[]>>();

    const [loading, setLoading] = React.useState(false);
    const currentTenant = getCurrentTenantDetails();
    const [fetchedSoftwarePackages, setFetchedSoftwarePackages] = React.useState<SoftwarePackage[]>([]);
    const [licenseList, setLicenseList] = React.useState<LicenseData[]>([]);
    const { t } = useTranslation();
    const [bdeOnboarded, setBdeOnboarded] = React.useState(false);
    const [bmdeOnboarded, setBmdeOnboarded] = React.useState(false);

    function groupProducts(products: Set<SoftwarePackageHomePage>) {
        return [...products].reduce<Record<string, SoftwarePackage[]>>((result, product) => {
            const groupName = product.bmpHomeGroup || product.productName;
            if (!result[groupName]) {
                result[groupName] = [];
            }
            result[groupName].push(product);
            return result;
        }, {});
    }

    const fetchProductsData = () => {
        const licensesAbortController = new AbortController();
        abortControllers.push(licensesAbortController);
        const licensePromise = licenseService.fetchAllLicenses({
            abortController: licensesAbortController,
            tenantIdentifier: currentTenant.uuid,
            own: !hasTenantCookie(),
        });
        const manualsPromise = productManualsService.fetchProductManuals();

        if (userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW_OWN])) {
            try {
                setLoading(true);
                const softwarePackagesPromise = softwarePackagesService.fetchSoftwarePackages();
                Promise.all([softwarePackagesPromise, licensePromise, manualsPromise])
                    .then(([softwarePackagesData, licenseData, manualsData]) => {
                        const filteredProducts = filterSoftwarePackages(
                            softwarePackagesData.productDownloads,
                            licenseData.licenses.licenseData
                        );

                        setFetchedSoftwarePackages(filteredProducts);
                        setLicenseList(licenseData.licenses.licenseData);

                        let assetProducts: Set<SoftwarePackageHomePage> = new Set();
                        let infoProducts: Set<SoftwarePackageHomePage> = new Set();
                        let additionalProducts: Set<SoftwarePackageHomePage> = new Set();

                        filteredProducts.map((product: SoftwarePackageHomePage) => {
                            switch (product.bmpHomeCategory) {
                                case "asset":
                                    assetProducts.add(product);
                                    break;
                                case "information":
                                    infoProducts.add(product);
                                    break;
                                case "additional":
                                    if (product.productName != "Blancco WinBuilder") {
                                        additionalProducts.add(product);
                                    }
                                    break;
                            }
                        });

                        const filteredManuals = filterProductManuals(
                            manualsData.productManuals,
                            licenseData.licenses.licenseData
                        );
                        assetProducts = appendManuals(assetProducts, filteredManuals);
                        infoProducts = appendManuals(infoProducts, filteredManuals);
                        additionalProducts = appendManuals(additionalProducts, filteredManuals);

                        const groupedAssetsByHomeGroup = groupProducts(assetProducts);

                        const groupedInfosByHomeGroup = groupProducts(infoProducts);

                        setAssetProductsState(groupedAssetsByHomeGroup);
                        setInfoProductsState(groupedInfosByHomeGroup);
                        setAdditionalProductsState(groupProducts(additionalProducts));
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } catch (e) {
                setLoading(false);
            }
        }
    };

    function appendManuals(products: Set<SoftwarePackageHomePage>, manuals: ProductManual[]) {
        products.forEach((product) => {
            manuals.forEach((manual) => {
                if (typeof product.bmpHomeGroup !== "undefined" && isStringNotBlank(product.bmpHomeGroup)) {
                    if (product.bmpHomeGroup === manual.productName) {
                        product.manualsDownloads = manual;
                    }
                } else {
                    if (product.productName === manual.productName) {
                        product.manualsDownloads = manual;
                    }
                }
            });
        });
        return products;
    }

    const abortController = new AbortController();
    React.useEffect(() => {
        onboardingsService.fetchOnboardedProducts(abortController).then((onboarding) => {
            onboarding.map((each) => {
                if (each.product === "BDE" && each.step == BdeOnboardingSteps.STEP_VIEWING_REPORT) {
                    setBdeOnboarded(true);
                }

                if (each.product === "BMDE" && each.step == BmdeOnboardingSteps.STEP_VIEWING_REPORT) {
                    setBmdeOnboarded(true);
                }
            });
        });
    }, []);

    useEffect(() => {
        fetchProductsData();
    }, []);

    const additionsProductCards = additionalProductsState && Object.keys(additionalProductsState).length > 0 && (
        <>
            <div className={style.headerWrapper}>
                <Heading tag={"div"} className={style.h3headerAdditionalProducts}>
                    {t("Onboarding.home.additionalToolsHeader")}
                </Heading>
            </div>
            {additionalProductsState &&
                Object.keys(additionalProductsState).length !== 0 &&
                Object.entries(additionalProductsState)
                    .reduce<Array<[string, SoftwarePackage[]]>[]>((softwarePackage, [key, value], index) => {
                        const groupIndex = Math.floor(index / 2);
                        if (!softwarePackage[groupIndex]) {
                            softwarePackage[groupIndex] = [];
                        }
                        softwarePackage[groupIndex].push([key, value]);
                        return softwarePackage;
                    }, [])
                    .map((group, groupIndex) => (
                        <div key={groupIndex} className={style.tableCell}>
                            {group.map(([key, value], index) => (
                                <ProductCard
                                    key={key + index}
                                    additionalProduct
                                    name={key}
                                    softwarePackages={value}
                                    fetchedSoftwarePackages={fetchedSoftwarePackages}
                                    fetchedLicenses={licenseList}
                                    bdeOnboarded={bdeOnboarded}
                                    bmdeOnboarded={bmdeOnboarded}
                                />
                            ))}
                        </div>
                    ))}
        </>
    );

    const productCards = (
        <>
            {assetProductsState && Object.keys(assetProductsState).length > 0 && (
                <>
                    <div className={style.headerWrapper}>
                        <Heading tag={"div"} className={style.h3header}>
                            {t("Onboarding.home.assetProductsHeader")}
                        </Heading>
                        <Tooltip content={t("Onboarding.home.assetHeaderLabel")} placement={"right-end"}>
                            <InfoBadgeIcon color={theme.homeInfoBadgeColor} />
                        </Tooltip>
                    </div>
                    {Object.entries(assetProductsState).map(([key, value]) => (
                        <div key={key} className={style.tableCell}>
                            <ProductCard
                                name={key}
                                softwarePackages={value}
                                fetchedSoftwarePackages={fetchedSoftwarePackages}
                                fetchedLicenses={licenseList}
                                bdeOnboarded={bdeOnboarded}
                                bmdeOnboarded={bmdeOnboarded}
                            />
                        </div>
                    ))}
                </>
            )}
            {infoProductsState && Object.keys(infoProductsState).length > 0 && (
                <>
                    <div className={style.headerWrapper}>
                        <Heading tag={"div"} className={style.h3headerInfoProducts}>
                            {t("Onboarding.home.informationProductsHeader")}
                        </Heading>
                        <Tooltip content={t("Onboarding.home.infoHeaderLabel")} placement={"right-end"}>
                            <InfoBadgeIcon color={theme.homeInfoBadgeColor} />
                        </Tooltip>
                    </div>
                    {Object.entries(infoProductsState).map(([key, value]) => (
                        <div key={key} className={style.tableCell}>
                            <ProductCard
                                name={key}
                                softwarePackages={value}
                                fetchedSoftwarePackages={fetchedSoftwarePackages}
                                fetchedLicenses={licenseList}
                                bdeOnboarded={bdeOnboarded}
                                bmdeOnboarded={bmdeOnboarded}
                            />
                        </div>
                    ))}
                </>
            )}
        </>
    );
    const onboardingEnabled = useFeature(FLAG_ONBOARDING);
    return (
        <>
            {onboardingEnabled ? (
                loading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <div className={style.planAndSupport}>
                            {isLicensingModelBundleWithToken() ? <CurrentPlan tenantData={currentTenant} /> : null}
                            <Support tenantTier={currentTenant.tenantTier} />
                        </div>
                        <div className={style.products}>
                            {productCards}
                            <hr className={style.hr} />
                            {additionsProductCards}
                        </div>
                    </>
                )
            ) : (
                <Redirect to={SUPPORT_AND_HELP_ROUTE.path} />
            )}
        </>
    );
};

export default Home;
