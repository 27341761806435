import * as React from "react";
import { connect } from "react-redux";
import { Progress } from "rsuite";

import style from "components/home/bde-home-page/getting-started.scss";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

interface Props {
    progressValue: number;
}

const ProgressBar = (props: Props): JSX.Element => {
    return (
        <>
            <div className={style.progressBarContainer}>
                <Progress.Line percent={props.progressValue} className={style.customProgressBar} />
            </div>
        </>
    );
};

export default connector(ProgressBar);
