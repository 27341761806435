import { ManifestWorkflowEditor } from "services/workflows/WorkflowService";

export const compareEditorVersionDtos = (left: ManifestWorkflowEditor, right: ManifestWorkflowEditor): number => {
    return compareEditorVersions(left.version, right.version);
};

export const compareEditorVersions = (left: string, right: string): number => {
    // Split version into base version and suffix
    const getVersionWeight = (part: string): number => {
        if (part === "dev") {
            return -3;
        }
        if (part.startsWith("a")) {
            return -2;
        }
        if (part.startsWith("rc")) {
            return -1;
        }
        return parseInt(part) || 0;
    };

    // Split versions into parts
    const leftParts = left.split(".");
    const rightParts = right.split(".");

    // Compare major.minor.patch
    for (let i = 0; i < Math.max(leftParts.length, rightParts.length); i++) {
        const aStr = (leftParts[i] || "").replace(/([0-9]+)(.*)/, "$1|$2");
        const bStr = (rightParts[i] || "").replace(/([0-9]+)(.*)/, "$1|$2");

        const [aNum, aSuffix] = aStr.split("|");
        const [bNum, bSuffix] = bStr.split("|");

        // Compare numeric parts
        const aNumeric = parseInt(aNum) || 0;
        const bNumeric = parseInt(bNum) || 0;

        if (aNumeric !== bNumeric) {
            // Reverse the comparison for descending order
            return bNumeric - aNumeric;
        }

        // If numeric parts are equal, compare suffixes
        if (aSuffix || bSuffix) {
            const aWeight = getVersionWeight(aSuffix || "");
            const bWeight = getVersionWeight(bSuffix || "");
            if (aWeight !== bWeight) {
                // Reverse the comparison for descending order
                return bWeight - aWeight;
            }
        }
    }

    return 0;
};

export const deduceEditorVersion = (editorVersion: string) => {
    const lowercaseEditorVersion = editorVersion.toLowerCase();
    if (lowercaseEditorVersion.includes("dev")) {
        return lowercaseEditorVersion.replace("dev", "");
    } else if (lowercaseEditorVersion.includes("rc")) {
        return lowercaseEditorVersion.split("rc")[0];
    } else if (lowercaseEditorVersion.includes("a")) {
        return lowercaseEditorVersion.split("a")[0];
    } else {
        return lowercaseEditorVersion;
    }
};
