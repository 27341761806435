import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import Button from "components/button/Button";
import style from "components/home/bde-home-page/getting-started.scss";
import ProductCard from "components/home/bde-home-page/ProductCard";
import SubStep from "components/home/bde-home-page/SubStep";
import StepCompletedText from "components/home/StepCompletedText";
import StepHeader from "components/home/StepHeader";
import { LicenseData } from "domain/licenses";
import { BdeOnboardingSteps, OnboardingProductType } from "domain/onboardings";
import { SoftwarePackage } from "domain/softwarePackages";
import { Onboarding } from "services/onboardings/OnboardingsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    stepIndex: number;
    fetchedSoftwarePackages?: SoftwarePackage[];
    fetchedLicenses: LicenseData[];
    featureWorkflowLicensePresent: boolean;
    stepCompleted: boolean;
    stepActive: boolean;
    stepClickable: boolean;
    saveOnboardingStep: (onboarding: Onboarding) => void;
    menuExpanded: boolean;
    stepInProgressIndex: number;
    saveStepInProgressIndex: (index: number) => void;
}

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
});

const connector = connect(mapState);

const TRANSLATION_KEYS = {
    TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title",
    STEPS: {
        ONE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepOne",
        TWO: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepTwo",
        THREE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepThree",
        FOUR: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepFour",
        FIVE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.stepFive",
    },
    PRODUCTS: {
        CONFIG_TOOL: {
            TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationTool",
            DESCRIPTION: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.configurationToolDescription",
        },
        ERASURE_FILE: {
            TITLE: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.erasureFile",
            DESCRIPTION: "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.erasureFileDescription",
        },
    },
};

const PreConfigureStep = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const [handleDropdown, setHandleDropDown] = React.useState<boolean>(false);
    const [handleIsoDropdown, setHandleIsoDropDown] = React.useState<boolean>(false);
    const [showContent, setShowContent] = React.useState<boolean>(false);
    const dataType = OnboardingProductType.BDE + "_" + BdeOnboardingSteps.STEP_PRE_CONFIGURATION;
    const handleConfigToolDownload = () => {
        return setHandleDropDown(true);
    };

    const handleErasureFileDownload = () => {
        return setHandleIsoDropDown(true);
    };
    const onSubmitEventHandler = (productSteps: Onboarding) => {
        props.saveStepInProgressIndex(props.stepIndex);
        props.saveOnboardingStep({
            step: productSteps.step,
            product: productSteps.product,
        });
    };

    const clickEvent = () => {
        if (props.stepClickable) {
            setShowContent(!showContent);
        }
    };

    React.useEffect(() => {
        if (props.stepInProgressIndex !== props.stepIndex) {
            setShowContent(false);
        }
    }, [props.stepInProgressIndex]);

    React.useEffect(() => {
        if (props.menuExpanded && props.stepClickable) {
            setShowContent(true);
        }
    }, [props.menuExpanded]);

    function createContent() {
        return (
            <>
                <SubStep iconNumber={1} translationKey={TRANSLATION_KEYS.STEPS.ONE} subStep={true} hasCards={true} />
                <div className={style.portalContent}>
                    <ProductCard
                        titleKey={TRANSLATION_KEYS.PRODUCTS.CONFIG_TOOL.TITLE}
                        descriptionKey={TRANSLATION_KEYS.PRODUCTS.CONFIG_TOOL.DESCRIPTION}
                        onDownload={handleConfigToolDownload}
                        handleDropdown={handleDropdown}
                        fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                    />

                    <ProductCard
                        titleKey={TRANSLATION_KEYS.PRODUCTS.ERASURE_FILE.TITLE}
                        descriptionKey={TRANSLATION_KEYS.PRODUCTS.ERASURE_FILE.DESCRIPTION}
                        onDownload={handleErasureFileDownload}
                        handleIsoDropdown={handleIsoDropdown}
                        fetchedLicenses={props.fetchedLicenses}
                        featureWorkflowLicensePresent={props.featureWorkflowLicensePresent}
                    />
                </div>

                <SubStep iconNumber={2} translationKey={TRANSLATION_KEYS.STEPS.TWO} subStep={true} />

                <SubStep iconNumber={3} translationKey={TRANSLATION_KEYS.STEPS.THREE} subStep={true} hasCards={true} />

                <div className={style.portalContent}>
                    <div className={style.hostName}>
                        <strong>{t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.hostname")}</strong>
                        {props.user?.erasureClientEndpoint}
                    </div>
                    <div className={style.url}>
                        <strong>{t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.port")}</strong>
                        {t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.443")}
                    </div>
                </div>

                <SubStep iconNumber={4} translationKey={TRANSLATION_KEYS.STEPS.FOUR} subStep={true} infoIcon={true} />

                <SubStep iconNumber={5} translationKey={TRANSLATION_KEYS.STEPS.FIVE} subStep={true} />
                <div className={style.markComplete}>
                    {props.stepCompleted ? (
                        <div className={style.templateSelectedBlock}>
                            <StepCompletedText
                                successText={t(
                                    "Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.completed"
                                )}
                                testId={testIds.workArea.onboarding.step.statusLabel}
                                dataType={dataType}
                            />
                        </div>
                    ) : (
                        <Button
                            variant={"PRIMARY"}
                            className={classNames(buttons.primaryButtonWithoutIcon)}
                            onClick={() => {
                                onSubmitEventHandler({
                                    step: BdeOnboardingSteps.STEP_PRE_CONFIGURATION,
                                    product: OnboardingProductType.BDE,
                                });
                            }}
                            testId={testIds.workArea.onboarding.step.primaryButton}
                            data-type={dataType}
                            loadingAnimation={props.stepInProgressIndex === props.stepIndex}
                            disabled={props.stepInProgressIndex === props.stepIndex}
                        >
                            {props.stepInProgressIndex === props.stepIndex
                                ? t("Onboarding.saving")
                                : t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.markComplete")}
                        </Button>
                    )}
                </div>
            </>
        );
    }

    return (
        <StepHeader
            title={t("Onboarding.gettingStartedFlow.preConfigureBlanccoDriveErasure.title")}
            success={props.stepCompleted}
            showStep={showContent}
            inactive={!props.stepClickable}
            clickEvent={clickEvent}
            stepIndex={props.stepIndex}
            showContent={showContent}
            menuExpanded={props.menuExpanded}
            content={createContent()}
            type={OnboardingProductType.BDE}
            stepName={BdeOnboardingSteps.STEP_PRE_CONFIGURATION}
        />
    );
};

export default connector(PreConfigureStep);
