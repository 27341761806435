import * as React from "react";

export const Reports = (props: { className?: string; color: string }): JSX.Element => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={props.className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.97482 3.0044C5.44192 2.52395 6.08003 2.25 6.75 2.25L14 2.25003C14.1989 2.25003 14.3897 2.32904 14.5303 2.46969L19.5303 7.46967C19.671 7.61032 19.75 7.80109 19.75 8V19.2C19.75 19.8696 19.4916 20.5159 19.0252 20.9956C18.5581 21.4761 17.92 21.75 17.25 21.75H6.75C6.08003 21.75 5.44192 21.4761 4.97482 20.9956C4.50841 20.5159 4.25 19.8696 4.25 19.2V4.8C4.25 4.13041 4.50841 3.48414 4.97482 3.0044ZM6.75 3.75C6.49171 3.75 6.23958 3.85534 6.05031 4.05002C5.86034 4.24541 5.75 4.51481 5.75 4.8V19.2C5.75 19.4852 5.86034 19.7546 6.05031 19.95C6.23958 20.1447 6.49171 20.25 6.75 20.25H17.25C17.5083 20.25 17.7604 20.1447 17.9497 19.95C18.1397 19.7546 18.25 19.4852 18.25 19.2V8.75H14C13.5858 8.75 13.25 8.41421 13.25 8V3.75002L6.75 3.75L6.75 3L6.75 3.75ZM14.75 4.81068L17.1893 7.25H14.75V4.81068ZM7.25 9C7.25 8.58579 7.58579 8.25 8 8.25H11C11.4142 8.25 11.75 8.58579 11.75 9C11.75 9.41421 11.4142 9.75 11 9.75H8C7.58579 9.75 7.25 9.41421 7.25 9ZM7.25 13C7.25 12.5858 7.58579 12.25 8 12.25H16C16.4142 12.25 16.75 12.5858 16.75 13C16.75 13.4142 16.4142 13.75 16 13.75H8C7.58579 13.75 7.25 13.4142 7.25 13ZM7.25 17C7.25 16.5858 7.58579 16.25 8 16.25H16C16.4142 16.25 16.75 16.5858 16.75 17C16.75 17.4142 16.4142 17.75 16 17.75H8C7.58579 17.75 7.25 17.4142 7.25 17Z"
            fill={props.color}
            strokeWidth="1"
        />
    </svg>
);
