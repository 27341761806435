import * as React from "react";

const LinkIcon = (props: { fillColor: string }): JSX.Element => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.33301 3.95801C4.96834 3.95801 4.6186 4.10287 4.36074 4.36074C4.10287 4.6186 3.95801 4.96834 3.95801 5.33301V14.6663C3.95801 15.031 4.10287 15.3807 4.36074 15.6386C4.6186 15.8965 4.96834 16.0413 5.33301 16.0413H14.6663C15.031 16.0413 15.3807 15.8965 15.6386 15.6386C15.8965 15.3807 16.0413 15.031 16.0413 14.6663V11.6663C16.0413 11.3212 16.3212 11.0413 16.6663 11.0413C17.0115 11.0413 17.2913 11.3212 17.2913 11.6663V14.6663C17.2913 15.3625 17.0148 16.0302 16.5225 16.5225C16.0302 17.0148 15.3625 17.2913 14.6663 17.2913H5.33301C4.63681 17.2913 3.96914 17.0148 3.47685 16.5225C2.98457 16.0302 2.70801 15.3625 2.70801 14.6663V5.33301C2.70801 4.63681 2.98457 3.96914 3.47685 3.47685C3.96914 2.98457 4.63681 2.70801 5.33301 2.70801H8.33301C8.67819 2.70801 8.95801 2.98783 8.95801 3.33301C8.95801 3.67819 8.67819 3.95801 8.33301 3.95801H5.33301ZM12.708 3.33301C12.708 2.98783 12.9878 2.70801 13.333 2.70801H16.6663C17.0115 2.70801 17.2913 2.98783 17.2913 3.33301V6.66634C17.2913 7.01152 17.0115 7.29134 16.6663 7.29134C16.3212 7.29134 16.0413 7.01152 16.0413 6.66634V4.84189L9.60828 11.2749C9.36421 11.519 8.96848 11.519 8.7244 11.2749C8.48032 11.0309 8.48032 10.6351 8.7244 10.3911L15.1575 3.95801H13.333C12.9878 3.95801 12.708 3.67819 12.708 3.33301Z"
                fill={props.fillColor}
            />
        </svg>
    );
};

export default LinkIcon;
