import * as React from "react";

const TemplateViewImage = (): JSX.Element => {
    return (
        <svg width="84" height="128" viewBox="0 0 84 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8C0 3.58172 3.58172 0 8 0H84V128H8C3.58172 128 0 124.418 0 120V8Z"
                fill="url(#paint0_linear_2295_15069)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.875 53.5C28.875 52.0503 30.0503 50.875 31.5 50.875H36C37.4497 50.875 38.625 52.0503 38.625 53.5V53.875H49.5C52.6066 53.875 55.125 56.3934 55.125 59.5C55.125 62.6066 52.6066 65.125 49.5 65.125H47.625V65.5C47.625 66.9497 46.4497 68.125 45 68.125H40.5C39.0503 68.125 37.875 66.9497 37.875 65.5V65.125H34.5C32.636 65.125 31.125 66.636 31.125 68.5C31.125 70.364 32.636 71.875 34.5 71.875H51.284L50.2045 70.7955C49.7652 70.3562 49.7652 69.6438 50.2045 69.2045C50.6438 68.7652 51.3562 68.7652 51.7955 69.2045L54.7955 72.2045C55.2348 72.6438 55.2348 73.3562 54.7955 73.7955L51.7955 76.7955C51.3562 77.2348 50.6438 77.2348 50.2045 76.7955C49.7652 76.3562 49.7652 75.6438 50.2045 75.2045L51.284 74.125H34.5C31.3934 74.125 28.875 71.6066 28.875 68.5C28.875 65.3934 31.3934 62.875 34.5 62.875H37.875V62.5C37.875 61.0503 39.0503 59.875 40.5 59.875H45C46.4497 59.875 47.625 61.0503 47.625 62.5V62.875H49.5C51.364 62.875 52.875 61.364 52.875 59.5C52.875 57.636 51.364 56.125 49.5 56.125H38.625V56.5C38.625 57.9497 37.4497 59.125 36 59.125H31.5C30.0503 59.125 28.875 57.9497 28.875 56.5V53.5ZM36.375 53.5C36.375 53.2929 36.2071 53.125 36 53.125H31.5C31.2929 53.125 31.125 53.2929 31.125 53.5V56.5C31.125 56.7071 31.2929 56.875 31.5 56.875H36C36.2071 56.875 36.375 56.7071 36.375 56.5V53.5ZM45.375 62.5C45.375 62.2929 45.2071 62.125 45 62.125H40.5C40.2929 62.125 40.125 62.2929 40.125 62.5V65.5C40.125 65.7071 40.2929 65.875 40.5 65.875H45C45.2071 65.875 45.375 65.7071 45.375 65.5V62.5Z"
                fill="#303030"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2295_15069"
                    x1="42"
                    y1="22.8571"
                    x2="42"
                    y2="128"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C6EAED" />
                    <stop offset="1" stopColor="#F6D9BA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default TemplateViewImage;
